<form [formGroup]="formGroup">
	<div class="bk-dialog__row-item">
		<label class="bk-dialog__label bk-dialog__tooltip-element"
			matTooltip="{{tooltipText | translate}}"
				[matTooltipPosition]="'above'" 
				[matTooltipClass]="'bk-dialog__tooltip'">
			{{ label | translate }}
			<i class="bk-dialog__info-icon fa fa-info-circle" ></i>
		</label>
		<div class="bk-dialog__field--container">
			<input class="bk-dialog__field bk-modal__input--short"
				type="number"
				formControlName="{{ controlName }}"
				(blur)="reloadData()"/>
			<div class="bk-dialog__field-button">
				<slider-button [sliderValue]="formGroup.get(controlName).value"
					[sliderMin]="min"
					[sliderMax]="max"
					[sliderDecimals]="decimals"
					[sliderDisabled]="false"
					[step]="step" 

					(sliderValueChanged)="formGroup.get(controlName).setValue($event);
						formGroup.get(controlName).markAsTouched(); reloadData();">
				</slider-button>
			</div>
		</div>
		<div class="bk-dialog__field--old-value">
			{{defaultValue}}
		</div>
		<div *ngIf="formGroup.get(controlName).invalid">
			<div *ngIf="formGroup.get(controlName).errors.required"
				class="bk-invalid-text">

				{{'Field cannot be empty' | translate }}
			</div>
			<div *ngIf="formGroup.get(controlName).errors.max"
				class="bk-invalid-text">

				{{'Value cannot be more than' | translate }} {{max}}
			</div>
			<div *ngIf="formGroup.get(controlName).errors.min"
				class="bk-invalid-text">

				{{'Value cannot be less than' | translate }} {{min}}
			</div> 
		</div>
	</div>
</form>