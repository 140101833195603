<div class="bk-dialog__table">
    <div class="bk-dialog__table-row bk-dialog__canopy-table--head">
        <div class="bk-dialog__table-title">
            {{ 'Canopy Data' | translate }}
        </div>
        <div class="bk-dialog__canopy-table--add-row" *ngIf="!addingEntry && !removingEntry" (click)="showAddEntryForm()">
            <i class="fa fa-plus bk-dialog__canopy-table--add-icon"></i> {{ 'Add Custom Canopy Data' | translate }}
        </div>
    </div>

    <div class="bk-dialog__canopy-table--form-add" #addEntryForm>
        <div class="bk-dialog__canopy-table--form">
            <div class="bk-dialog__canopy-table--form-item">
                <label class="bk-dialog__label">
					{{'Canopy Measure Date' | translate }}
				</label>

                <my-date-picker [options]="datePickerOptions" (dateChanged)="onDateChanged($event)" 
					[(ngModel)]="newEntryDate"></my-date-picker>
            </div>

            <div class="bk-dialog__canopy-table--form-item">
                <label class="bk-dialog__label">{{'Measured Canopy Cover' | translate }}</label>
                <input class="bk-dialog__field bk-modal__input--short" type="number"
					   [(ngModel)]="newEntry.CanopyCover" (keyup)="validateNewEntry('canopyCover')"
                       [ngClass]="{'bk-input-invalid' : !formValid.canopyCover }" />
                <div class="bk-dialog__field-unit">%</div>
            </div>

            <div class="bk-dialog__canopy-table--form-buttons">
                <button class="bk-button bk-button--medium bk-button--text-only" (click)="cancelAddingEntry()">{{ 'Cancel' | translate }}</button>
                <button class="bk-button bk-button--medium" [disabled]="isNewEntryInvalid()" (click)="save()">{{ 'Add' | translate }}</button>
            </div>
        </div>
    </div>

    <div class="bk-dialog__table-row">
        <div class="bk-dialog__table-col bk-dialog__canopy-table--col-1 bk-dialog__canopy-table--col-1-head">
            {{ 'Date' | translate }}
        </div>
        <div class="bk-dialog__table-col bk-dialog__canopy-table--col-2 bk-dialog__canopy-table--col-2-head">
            {{ 'Measured Canopy' | translate }}
        </div>
        <div class="bk-dialog__table-col bk-dialog__canopy-table--col-3 bk-dialog__canopy-table--col-3-head">
            {{ 'CropManage Model Canopy' | translate }}
        </div>
    </div>

    <ng-container class="bk-dialog__table-row"
        *ngFor="let tableRow of tableData.Data">

        <div class="bk-dialog__canopy-table--form-remove"
            [@openClose]="(isOpen && entryToRemove.Date === tableRow.Date) ? 'open' : 'closed'">
            <div [@rowChildrenAnim]="(isOpen && entryToRemove.Date === tableRow.Date) ? 'open' : 'closed'"
                 class="bk-dialog__canopy-table--form">
                 <div class="bk-dialog__canopy_table--flex">
                    <div class="bk-dialog__canopy-table--remove-text">
                        {{ 'Remove' | translate }} {{ tableRow.Date }} {{ 'measurement' | translate }}?
                    </div>

                    <div class="bk-dialog__canopy-table--remove-buttons">
                        <button class="bk-button bk-button--medium bk-button--text-only"
                            (click)="cancelRemovingEntry()">
                            {{'Cancel' | translate }}
                        </button>
                        <button class="bk-button bk-button--medium"
                            (click)="remove()">
                            {{ 'Remove' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="bk-dialog__table-row">
            <div class="bk-dialog__table-col bk-dialog__canopy-table--col-1">
                <i class="fa fa-times-circle bk-dialog__canopy-table--remove-icon" (click)="openRemoveEntrySection(tableRow)"></i> 
                {{ tableRow.Date | date:'shortDate' }}
            </div>
            <div class="bk-dialog__table-col bk-dialog__canopy-table--col-2">
                {{ tableRow.MeasuredCanopy | number: '1.0-2'}}% {{ tableRow.Source }}
            </div>
            <div class="bk-dialog__table-col bk-dialog__canopy-table--col-3">
                {{ tableRow.CropManageCanopy | number: '1.0-2'}}%
            </div>
        </div>
    </ng-container>

    <div class="bk-dialog__table-row">
        <div class="bk-dialog__table-col bk-dialog__canopy-table--rms-text">
            <span class="bk-dialog__tooltip-element" matTooltip="{{'Canopy Root Mean Square Difference Text' | translate}}" [matTooltipPosition]="'above'" [matTooltipClass]="'bk-dialog__tooltip'">
                {{ 'Root Mean Square Difference' | translate }}: <i class="bk-dialog__info-icon fa fa-info-circle"></i>
            </span>
        </div>
        <div class="bk-dialog__table-col bk-dialog__canopy-table--rms-value">
            {{ tableData.RootMeanSquared }}
        </div>
    </div>
</div>
