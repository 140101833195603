import { Injectable } from '@angular/core';
import { Subject ,  Observable, BehaviorSubject } from 'rxjs';

import { IEventGroup } from '../models/event/interfaces';

import { IRanch, ICIMISOptions, IRanchPermissions, IRanchSettingsValidationStates,
	ISubjectFavoriteResponse,
	IRanchUserListItem,
	IWeatherStationCurrentModel} from '../components/ranch-settings/interfaces';

import { IUpdatedSoilMoistureFile } from '../components/soil-moisture/soilMoisture.interface';
import { iLocation } from '../interfaces/views/map';
import { IWeatherStation } from '../components/weather-stations/interfaces';
import { iIgnoreSchedule } from '../components/ignore-schedules/interfaces';
import { IWeatherStationRegion } from '../models/region/interfaces';
import { ICommodityTypesRanchJSON } from '../models/commodity-type/interfaces';
import { WeatherStation } from '../components/weather-stations/weatherStation';
import { IRanchLotModel } from '../components/ranch-settings/modals/lot/interfaces';
import { IWell } from '../models/planting-well/well.interface';
import { ICurrentUser } from '../models/user/interfaces';
import { IFertilizer } from '../components/ranch-settings/modals/fertilizer/interfaces';

// Navigation options in the main menu - used to track which menu item is active
export enum navigationViews {
	LOCALIZATION = 1,
	PROFILE = 2,
	RANCHES = 3
}

@Injectable()
export class UpdateService {

	/*
     * Subscription for when a ranch is created, updated, or deleted
     */
	private ranchesSubject: Subject<string> = new Subject<string>();

	// use this to detect changes to a single ranch
	// - used primary for updating ranch settings page
	private updatedRanch: Subject<IRanch> = new Subject<IRanch>();
	public updatedRanch$ = this.updatedRanch.asObservable();

	/*
     * Subscription for tracking current ranch
     */

	public reload = { // tracks to see if we switched ranches, to figure out when to repull from server
		active: false,
		favorites: false,
		all: false
	}

	public currentRanchId: string; // access this object for current ranch id
	private ranchIdSubject: Subject<string> = new Subject<string>();

	// subscribe to this object to get alerted when current ranch changes (with .subscribe())
	public ranchIdSubscription$: Observable<string> = this.ranchIdSubject.asObservable();

	/*
     * subscription for ranch plantings permissions
     */
	private RanchPlantingsPermissions: Subject<IRanchPermissions> = new Subject<IRanchPermissions>();
	public RanchPlantingsPermissions$ = this.RanchPlantingsPermissions.asObservable();
	public currentPermissions: IRanchPermissions;

	/*
     * subscription for ranch settings
     */
	private RanchSettingsUpdated: Subject<string> = new Subject<string>();
	public RanchSettingsUpdated$ = this.RanchSettingsUpdated.asObservable();

	/*for adding events?*/
	private PlantingsUpdated: Subject<Object> = new Subject<Object>();
	public PlantingsUpdated$ = this.PlantingsUpdated.asObservable();

	private EventUpdated: Subject<Object> = new Subject<Object>();
	public EventUpdated$ = this.EventUpdated.asObservable();

	/* For hiding or displaying Ranch search and filter side nav*/
	private displayRanchesSearch: Subject<boolean> = new Subject<boolean>();
	public diplayRanchesSearch$ = this.displayRanchesSearch.asObservable();

	/* For hiding or displaying Planting search and filter side nav*/
	private displayPlantingsSearch: Subject<boolean> = new Subject<boolean>();
	public displayPlantingsSearch$ = this.displayPlantingsSearch.asObservable();

	/* For toggling units in all events modal */
	private unitsToggle: Subject<{type: string, value: string }> = new Subject<{type: string, value: string }>();
	public unitsToggle$ = this.unitsToggle.asObservable();

	/**
     * Trigger ranches to load when tab is clicked
     */
	private plantingsTabclicked: Subject<{ tabName: string }> = new Subject<{ tabName: string }>();
	public plantingsTabClicked$ = this.plantingsTabclicked.asObservable();

	private plantingFavoritesUpdated: Subject<Object> = new Subject<Object>();
	public plantingFavoritesUpdated$ = this.plantingFavoritesUpdated.asObservable();

	private ranchFavoritesUpdated: Subject<ISubjectFavoriteResponse> =
		new Subject<ISubjectFavoriteResponse>();

	public ranchFavoritesUpdated$ = this.ranchFavoritesUpdated.asObservable();

	public showRanchSettings: boolean;

	/*for setting the current page*/
	// BehaviorSubject is used instead of Subject to ensure that subscribers
	// receive updates even if the updates were fired before the subscription
	// happens
	private currentPage: BehaviorSubject<navigationViews> = new BehaviorSubject<navigationViews>(null);
	public currentPage$ = this.currentPage.asObservable();

	private missingRanchSettings: Subject<IRanchSettingsValidationStates> = new Subject<IRanchSettingsValidationStates>();
	public missingRanchsettings$ = this.missingRanchSettings.asObservable();

	public ranchSettings: IRanchSettings = {
		members: null,
		lots: null,
		fertilizers: null,
		weatherStations: null,
		wells: null,
		commodityTypes: null
	}

	private _ranchSettingsSubject: Subject<IRanchSettings> = new Subject<IRanchSettings>();
	public ranchSettings$ = this._ranchSettingsSubject.asObservable();

	private navigateToRanchSettings: Subject<void> = new Subject<void>();
	public navigateToRanchSettings$ = this.navigateToRanchSettings.asObservable();

	/* Boolean for determining whether or not to cancel ranch export */
	public cancelRanchDataExport = false;

	private favoritePlantingsLoaded: Subject<boolean> = new Subject<boolean>();
	public favoritePlantingsLoaded$ = this.favoritePlantingsLoaded.asObservable();

	private activePlantingsLoaded: Subject<boolean> = new Subject<boolean>();
	public activePlantingsLoaded$ = this.activePlantingsLoaded.asObservable();

	private closeModalSubject: Subject<boolean> = new Subject<boolean>();
	public closeModalSubscription = this.closeModalSubject.asObservable();

	public currentLocation: iLocation = {
		lat: null,
		lng: null
	}

	private SoilMoistureFileUpdated: Subject<IUpdatedSoilMoistureFile> = new Subject<IUpdatedSoilMoistureFile>();
	public SoilMoistureFileUpdated$ = this.SoilMoistureFileUpdated.asObservable();

	private CIMISOptionsUpdated: Subject<ICIMISOptions> = new Subject<ICIMISOptions>();
	public CIMISOptionsUpdated$ = this.CIMISOptionsUpdated.asObservable();

	/**
     * Allows event dialogs to tell event table/list that it has closed. Used for mat dialogs which
     * are not embedded in the event table component template
     */

	private eventDialogClosed: Subject<null> = new Subject<null>();
	public eventDialogClosed$ = this.eventDialogClosed.asObservable();

	private updatedWeatherStation: Subject<IWeatherStation> = new Subject<IWeatherStation>();
	public updatedWeatherStation$ = this.updatedWeatherStation.asObservable();

	private createdWeatherStation: Subject<IWeatherStation> = new Subject<IWeatherStation>();
	public createdWeatherStation$ = this.createdWeatherStation.asObservable();

	private deletedWeatherStation: Subject<IWeatherStation> = new Subject<IWeatherStation>();
	public deletedWeatherStation$ = this.deletedWeatherStation.asObservable();

	private deactivatedWeatherstation: Subject<number> = new Subject<number>();
	public deactivatedWeatherStation$ = this.deactivatedWeatherstation.asObservable();

	public weatherStations: IWeatherStation[] = new Array();

	private weatherStationsList: Subject<IWeatherStation[]> = new Subject<IWeatherStation[]>();
	public weatherStationsList$ = this.weatherStationsList.asObservable();

	private createdIgnoreSchedule: Subject<iIgnoreSchedule> = new Subject<iIgnoreSchedule>();
	public createdIgnoreSchedule$ = this.createdIgnoreSchedule.asObservable();

	private updatedIgnoreSchedule: Subject<iIgnoreSchedule> = new Subject<iIgnoreSchedule>();
	public updatedIgnoreSchedule$ = this.updatedIgnoreSchedule.asObservable();

	private deletedIgnoreSchedule: Subject<iIgnoreSchedule> = new Subject<iIgnoreSchedule>();
	public deletedIgnoreSchedule$ = this.deletedIgnoreSchedule.asObservable();

	public weatherStationRegions: IWeatherStationRegion[] = new Array();

	private weatherStationRegionsList: Subject<IWeatherStationRegion[]> = new Subject<IWeatherStationRegion[]>();
	public weatherStationRegionsList$ = this.weatherStationRegionsList.asObservable();

	private currentRanchUserReceived: Subject<ICurrentUser> = new Subject<ICurrentUser>();
	public currentRanchUserReceived$ = this.currentRanchUserReceived.asObservable();

	private authToken: Subject<void> = new Subject<void>(); // triggers when user logs in
	public authToken$ = this.authToken.asObservable();

	/******************************************************************************************** */

	constructor() { }

	public updateAuthToken(): void {
		return this.authToken.next();
	}

	public setCurrentRanchUserAsReceived(user: ICurrentUser): void {
		return this.currentRanchUserReceived.next(user);
	}

	public setUpdatedRanches(source: string) {
		this.ranchesSubject.next(source);
	}

	// subscribe to this method to get alerted when ranches are updated (with .subscribe())
	public getUpdatedRanchesSubscription(): Observable<string> {
		return this.ranchesSubject.asObservable();
	}

	public setUpdatedRanch(ranch: IRanch) {
		this.updatedRanch.next(ranch);
	}

	public setCurrentRanchId(id: string) {

		this.reload = {
			active: true,
			favorites: true,
			all: true
		};

		this.currentRanchId = id;
		this.ranchIdSubject.next(id);
	}

	public setRanchPlantingsPermissions(permissions: IRanchPermissions) {
		this.RanchPlantingsPermissions.next(permissions);
		this.currentPermissions = permissions;
	}

	public setRanchSettingsUpdated(settings: string) {
		this.RanchSettingsUpdated.next(settings);
	}

	public setPlantingsUpdated(ranchId: string, time: Date) {
		this.PlantingsUpdated.next({
			ranchId: ranchId,
			time: time
		});
	}

	public setEventUpdated(eventId: number, event: IEventGroup) {
		this.EventUpdated.next({
			eventId: eventId,
			event: event
		});
	}

	public setDisplayRanchesSearch(displayRanchesSearch: boolean): void {
		this.displayRanchesSearch.next(displayRanchesSearch);
	}

	public setDisplayPlantingsSearch(displayPlantingsSearch: boolean): void {
		this.displayPlantingsSearch.next(displayPlantingsSearch);
	}

	public toggleUnits(type: string, value: string): void {
		this.unitsToggle.next({
			type: type,
			value: value
		});
	}

	public setPlantingsTabClicked(tabName: string) {
		this.plantingsTabclicked.next({ tabName: tabName });
	}

	public setPlantingFavoritesUpdated(plantingId: number, favoriteId: number) {
		this.plantingFavoritesUpdated.next({
			plantingId: plantingId,
			favoriteId: favoriteId
		});
	}

	public setRanchFavoritesUpdated(ranchId: number, favoriteId?: number, ranch?: IRanch) {
		this.ranchFavoritesUpdated.next({
			ranchId: ranchId,
			favoriteId: favoriteId,
			ranch: ranch
		});
	}

	public setCurrentPage(view: navigationViews): void {
		this.currentPage.next(view);
	}

	public setMissingRanchSettings(settings: IRanchSettingsValidationStates): void {
		this.missingRanchSettings.next(settings);
	}

	public updateRanchSettings(): void {
		this._ranchSettingsSubject.next(this.ranchSettings);
	}

	public clearRanchSettings(): void {

		this.ranchSettings = {
			members: null,
			lots: null,
			fertilizers: null,
			weatherStations: null,
			wells: null,
			commodityTypes: null
		}

		this._ranchSettingsSubject.next(this.ranchSettings);
	}

	public setNavigateToRanchSettings(): void {
		this.navigateToRanchSettings.next();
	}

	public setFavoritePlantingsLoaded(loaded: boolean): void {
		this.favoritePlantingsLoaded.next(loaded);
	}

	public setActivePlantingsLoaded(loaded: boolean): void {
		this.activePlantingsLoaded.next(loaded);
	}

	public setPlantingsLoaded(loaded: boolean): void {
		this.favoritePlantingsLoaded.next(loaded);
		this.activePlantingsLoaded.next(loaded);
	}

	public closeModal(): void {
		this.closeModalSubject.next(true);
	}

	public setCurrentLocation(lat: number, lng: number): void {
		this.currentLocation.lat = lat;
		this.currentLocation.lng = lng;
	}

	public setSoilMoistureFileUpdated(plantingId: number, fileName: string): void {
		if (fileName && typeof fileName === 'string') {
			fileName = fileName.trim();
		}

		this.SoilMoistureFileUpdated.next({ plantingId: plantingId, fileName: fileName });
	}

	public setCIMISOptionsUpdated(cimisOptions: ICIMISOptions): void {
		this.CIMISOptionsUpdated.next(cimisOptions);
	}

	public setEventDialogClosed(): void {
		this.eventDialogClosed.next();
	}

	public updateWeatherStation(updatedWeatherStation: IWeatherStation): void {
		for (let weatherStation of this.weatherStations) {
			if (weatherStation.Id === updatedWeatherStation.Id) {
				WeatherStation.copy(updatedWeatherStation, weatherStation);
				break;
			}
		}

		this.updatedWeatherStation.next(updatedWeatherStation);
	}

	public createWeatherStation(weatherStation: IWeatherStation): void {
		this.weatherStations.push(weatherStation);
		this.createdWeatherStation.next(weatherStation);
	}

	public deleteWeatherStation(weatherStation: IWeatherStation): void {
		this.weatherStations = this.weatherStations.filter(x => x.Id !== weatherStation.Id);
		this.deletedWeatherStation.next(weatherStation);
	}

	public deactivateWeatherStation(id: number): void {
		this.deactivatedWeatherstation.next(id);
	}

	public setWeatherStationsList(weatherStations: IWeatherStation[]): void {
		this.weatherStations = weatherStations;
		this.weatherStationsList.next(weatherStations);
	}

	public createIgnoreSchedule(ignoreSchedule: iIgnoreSchedule): void {
		this.createdIgnoreSchedule.next(ignoreSchedule);
	}

	public updateIgnoreSchedule(ignoreSchedule: iIgnoreSchedule): void {
		this.updatedIgnoreSchedule.next(ignoreSchedule);
	}

	public deleteIgnoreSchedule(ignoreSchedule: iIgnoreSchedule): void {
		this.deletedIgnoreSchedule.next(ignoreSchedule);
	}

	public setweatherStationRegionsList(weatherStationRegions: IWeatherStationRegion[]): void {
		this.weatherStationRegions = weatherStationRegions;
		this.weatherStationRegionsList.next(weatherStationRegions);
	}
}

export interface IRanchSettings {
	members: IRanchUserListItem[],
	lots: IRanchLotModel[],
	fertilizers: IFertilizer[], // REFACTOR
	weatherStations: IWeatherStationCurrentModel[],
	wells: IWell[],
	commodityTypes: ICommodityTypesRanchJSON[]
}
