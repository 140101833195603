<div class="bk-dialog bk-dialog__event">

    <button type="button"
        class="bk-dialog__close"
        (click)="onClose()">

        &times;
    </button>
    
    <h4 mat-dialog-title>
        {{ (eventId ? 'Add' : 'Edit') | translate }} {{'Fertilization Event' | translate }}
    </h4>

    <div mat-dialog-content
        *ngIf="isLoaded === false">
    
        <div class="bk-loader__container">
            <div class="bk-loader">{{'Loading' | translate }}...</div>
        </div>
    </div>

    <div mat-dialog-content
        *ngIf="isLoaded && form">

        <form [formGroup]="form" class="bk-form">
            <mat-form-field appearance="fill"
                class='mat-form-field--one-line mat-form-field--short'>
                <mat-label>{{ 'Event Date' | translate }}</mat-label>
                <input matInput [matDatepicker]="picker"
                    [max]="maxDate"
                    size="10"
                    required
                    formControlName="EventDate">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-error *ngIf="f.EventDate.invalid && (f.EventDate.dirty || f.EventDate.touched)">
                    {{'Planting Event Date Error Text' | translate }}
                </mat-error>
            </mat-form-field>

            <!-- Fertilizer Detail -->

            <div class="bk-modal__dropdown-section">
                <a *ngIf="f.FertilizerId.value > 0"
                    href="javascript:void(0);"
                    class="bk-modal__dropdown-link"
                    [popper]="fertilizerDetail"
                    [popperTrigger]="'click'"
                    [popperPlacement]="'top'"
                    [popperTimeoutAfterShow]="5000"
                    >
                    {{'Fertilizer Details' | translate }}
                </a>
            </div>

            <mat-form-field appearance="fill"
                class="mat-form-field--one-line mat-form-field--short">
                <mat-label>
                    {{'Fertilizer Type' | translate }}
                </mat-label>
                <mat-select formControlName="FertilizerId"
                    required>
                    <mat-option *ngFor="let fertilizer of fertilizers"
                        [value]="fertilizer.Id">
                        {{fertilizer.Name}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="f.FertilizerId.invalid && (f.FertilizerId.dirty ||
                    f.FertilizerId.touched)">
                    {{'This field is required.' | translate }}
                </mat-error>
            </mat-form-field>

            <ng-container *ngIf="isFertilizationsPerMonth && isNDependent">
                <mat-form-field appearance="fill"
                    class="mat-form-field--one-line mat-form-field--short">
                    <mat-label>
                        {{'Fertilizations Per Month' | translate }}
                    </mat-label>
                    <input matInput
                        type="number"
                        step="0.01"
                        required
                        name="FertilizationsPerMonth"
                        formControlName="FertilizationsPerMonth"/>
                    <mat-error *ngIf="f.FertilizationsPerMonth.invalid &&
                        (f.FertilizationsPerMonth.dirty || f.FertilizationsPerMonth.touched)">
                        {{'Fertilizations per Month must be a number between 1 and 10' | translate }}
                    </mat-error>
                </mat-form-field>
            </ng-container>

            <ng-container *ngIf="!isFertilizationsPerMonth && isNDependent">
                <mat-form-field appearance="fill"
                    class="mat-form-field--one-line mat-form-field--short">
                    <mat-label>
                        {{'Days To Next Fertilization' | translate }}
                    </mat-label>
                    <input matInput
                        type="number"
                        step="0.01"
                        required
                        name="DaysToNextFertilization"
                        formControlName="DaysToNextFertilization"/>
                    <mat-error *ngIf="f.DaysToNextFertilization.invalid &&
                        (f.DaysToNextFertilization.dirty || f.DaysToNextFertilization.touched)">
                        {{'Not a valid number' | translate }}
                    </mat-error>
                </mat-form-field>
            </ng-container>

            <!--
            <mat-form-field appearance="fill"
                class="mat-form-field--one-line mat-form-field--w3">
                <mat-label>
                    {{'Crop Stage' | translate }}
                </mat-label>
                <mat-select formControlName="CropStageId">
                    <mat-option *ngFor="let stage of cropStages"
                        [value]="stage.Id">
                        {{stage.Name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            -->

            <!-- Soil Sample Dropdown -->

            <ng-container *ngIf="!isTree && isNDependent">
                <!--
                    trees use initial tissue samples for N calculation, so doesn't use soil samples
                    alfalfa do not use N, so soil samples are not useful
                -->
                <mat-form-field appearance="fill"
                    class="mat-form-field--one-line mat-form-field--short"
                    hintLabel="{{ 'Choose the soil sample date used to calculate this recommendation' | translate }}">
                    <mat-label>
                        {{'Soil Sample' | translate }}
                    </mat-label>
                    <mat-select formControlName="soilSampleEventId"
                        required>
                        <mat-option [value]="-2">
                            {{'No Soil Sample' | translate}}
                        </mat-option> <!-- "null" is a string, not null -->
                        <mat-option [value]="-1">
                            {{'New Soil Sample' | translate}}
                        </mat-option>
                        <mat-option *ngFor="let sample of soilSamples"
                            [value]="sample.Id">
                            {{sample.Name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </ng-container>

            <!--NEW SOIL SAMPLE ADD SECTION-->

            <ng-container *ngIf="f.soilSampleEventId.value === SOIL_SAMPLE_DROPDOWN_NEW">
                <soil-sample-ui [plantingId]="plantingId"
                    [eventDate]="f.EventDate.value"
                    [cropStageId]="f.CropStageId.value"
                    (onSoilSampleAdded)="onSoilSampleUpdated($event)">
                </soil-sample-ui>
            </ng-container>

            <!-- N Dependent crop recommendation section -->

            <ng-container *ngIf="isNDependent && f.soilSampleEventId.value !== SOIL_SAMPLE_DROPDOWN_NEW">
                <div *ngIf="!firstStep"
                    class="bk-recommendations">

                    <ng-container *ngIf="!NContribution.UIVisible">
                        <!-- Unit Toggle -->

                        <div class="bk-form-group">
                            <h4 class="bk-recommendations__title">
                                {{'Recommendation' | translate }}
                            </h4>
                            <div class="bk-button-set bk-button-set--right
                                bk-button-set_event--right">
                                <button class="bk-button bk-button-set__button
                                    bk-unit-menu__button bk-button--gray-icon"
                                    type="button"
                                    [ngClass]="{'bk-button-set__button--selected':
                                        units === 'lbsNPerAcre'}"
                                    (click)="toggleToLbsNPerAcre()">
                                    {{'lbs' | translate }} N/{{'acre' | translate }}
                                </button>

                                <button class="bk-button bk-button-set__button
                                    bk-unit-menu__button bk-button--gray-icon"
                                    type="button"
                                    [ngClass]="{'bk-button-set__button--selected':
                                        units == 'fertilizerUnit'}"
                                    (click)="toggleToFertUnits()">
                                    {{'Fertilizer Unit' | translate }}
                                </button>
                            </div>
                        </div>

                        <!-- recommendation -->

                        <div *ngIf="f.soilSampleEventId.value !== SOIL_SAMPLE_DROPDOWN_NEW"
                            id="fertilizationRecommendationsContainer"
                            class="bk-modal__clearfix">

                            <ng-container *ngIf="f.NPercentage.value === 0 || (form && form.invalid)">
                                <div class="bk-modal__recommendation">
                                    {{ 'N/A' | translate }}
                                </div>
                            </ng-container>
                            <ng-container *ngIf="f.NPercentage.value > 0 && form && form.valid
                                && units === 'fertilizerUnit'">
                                <div class="bk-modal__recommendation">
                                    {{FertilizationRecommendationLbsPerAcre | number: '1.2-2'}} 
                                    <span *ngIf="f.IsFormulationTypeDry.value"
                                        class="bk-modal__recommendation--unit">
                                        {{'lbs' | translate }}/{{'acre' | translate }}
                                    </span>
                                    <span *ngIf="!f.IsFormulationTypeDry.value"
                                        class="bk-modal__recommendation--unit">
                                        {{'gal' | translate }}/{{'acre' | translate }}
                                    </span>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="f.NPercentage.value > 0 && form && form.valid &&
                                units === 'lbsNPerAcre'">
                                <div class="bk-modal__recommendation">
                                    {{ NFertilizationRecommended | number: '1.2-2'}} 
                                    <span class="bk-modal__recommendation--unit">
                                        {{'lbs' | translate }} N/{{'acre' | translate }}
                                    </span>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="f.NPercentage.value > 0 && form && form.valid">
                                <a href="javascript:void(0);"
                                    class="bk-modal__dropdown-link bk-link--show-recommendation-summary"
                                    (click)="showRecommendationSummary()">
                                    {{'Recommendation Summary' | translate }}

                                    <i *ngIf="!recommendationSummaryVisible"
                                        class="fa fa-angle-down bk-modal__dropdown-link-icon">
                                    </i>

                                    <i *ngIf="recommendationSummaryVisible"
                                        class="fa fa-angle-up bk-modal__dropdown-link-icon">
                                    </i>
                                </a>
                                <div id="fertilizationRecommendationSummary"
                                    class="bk-modal__dropdown"
                                    [style.display]="recommendationSummaryVisible ? 'initial' : 'none'" >
                                    <fertilization-recommendation-summary
                                        [nInWater]="getNAppliedFromWaterInFertilizerUnits()">
                                    </fertilization-recommendation-summary>
                                </div>
                            </ng-container>
                        </div>
                    
                        <!-- N CONTRIBUTION SECTION -->

                        <!-- display N Contribution button only if NAppliedFromWater isn't calculated yet, ranch has wells,
                                and event is associated with a soil sample -->

                        <button *ngIf="(loading.nContribution || f.NAppliedFromWater.value === null
                            || NContribution.UIVisible)
                            && f.soilSampleEventId.value !== SOIL_SAMPLE_DROPDOWN_NEW"
                            class="bk-button bk-n-contribution-ui__display-button"
                            type="button"
                            (click)="displayNContributionUI()"
                            [disabled]="NContribution.UIVisible || loading.nContribution || form.invalid">
                            {{ 'Include N Contribution From Water in Recommendation' | translate}}
                        </button>

                        <div class="bk-n-contribution-ui__details" 
                                *ngIf="!loading.isInitial && !loading.nContribution && !NContribution.UIVisible &&
                                f.NAppliedFromWater.value !== null && f.soilSampleEventId.value !== SOIL_SAMPLE_DROPDOWN_NEW">

                            <div class="bk-n-contribution-ui__n-applied">
                                {{ 'N Contribution From Water' | translate }}: 
                                <span class="bk-n-contribution-ui__n-applied--value">
                                    <ng-container *ngIf="units === 'lbsNPerAcre'">
                                        {{ f.NAppliedFromWater.value | number: '1.0-2'}} lbs N/{{'acre' | translate}}
                                    </ng-container>
                                    <ng-container *ngIf="units === 'fertilizerUnit'">
                                        {{getNAppliedFromWaterInFertilizerUnits() | number: '1.0-2'}} 
                                        <span *ngIf="f.IsFormulationTypeDry.value">
                                            {{'lbs' | translate }}/{{'acre' | translate }}
                                        </span>
                                        <span *ngIf="!f.IsFormulationTypeDry.value">
                                            {{'gal' | translate }}/{{'acre' | translate }}
                                        </span>
                                    </ng-container>
                                </span>
                                <i class="bk-n-contribution-ui__tooltip-icon bk-n-contribution-ui__tooltip-icon--edit fa fa-info-circle"
                                    [popper]="nContributionEditPopper" 
                                    [popperTrigger]="'click'"
                                    [popperPlacement]="'top'"
                                    [popperTimeoutAfterShow]="10000">
                                </i>
                            </div>
                            <div class="bk-n-contribution-ui__n-applied--buttons">
                                <a (click)="editNContribution($event)">
                                    {{ 'Edit' | translate }}
                                </a>
                                <a (click)="clearNContribution($event)">
                                    {{ 'Clear' | translate }}
                                </a>
                            </div>
                        </div>

                        <div class="bk-n-contribution-ui"
                            #nContributionLoader>
                            <div class="bk-n-contribution-ui__loader" >
                                <div class="bk-loader">
                                    {{'Loading' | translate }}...
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <div class="bk-n-contribution-ui" #nContributionUI>
                        <form [formGroup]="nContributionForm">
                            <h4 class="bk-new-soil-sample__title">
                                {{'N Contribution from Water' | translate}}
                            </h4>
                            <mat-form-field appearance="fill"
                                class="mat-form-field--half-width">
                                <mat-label>
                                    {{'Expected Irrigation Method' | translate }}
                                </mat-label>
                                <mat-select formControlName="IrrigationMethodId">
                                    <mat-option *ngFor="let irrigationMethod of this.irrigationMethods"
                                        [value]="irrigationMethod.Id">
                                        {{irrigationMethod.Name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <div class="bk-n-contribution-ui__irrigation--loader" #nContributionIrrigationLoader>
                                <div class="bk-n-contribution-ui__loader">
                                    <div class="bk-loader">
                                        {{'Loading' | translate }}...
                                    </div>
                                </div>
                            </div>

                            <div class="bk-n-contribution-ui__irrigation--container" #nContributionIrrigation>

                                <div class="bk-modal__radio-buttons bk-n-contribution-ui__radio-buttons">
                                    <mat-radio-group aria-label=""
                                        name="UseWells"
                                        formControlName="UseWells">

                                        <mat-radio-button [value]="true"
                                            (click)="calculateNContribution(true);"
                                            name="UseWells">
                                            {{'Use Avg. Water Source PPM' | translate}}
                                        </mat-radio-button>

                                        <mat-radio-button [value]="false"
                                            class="mat-radio-button--left-margin"
                                            (click)="calculateNContribution(true);"
                                            name="UseWells">
                                            {{'Enter PPM Manually' | translate}}
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </div>

                                <ng-container *ngIf="fn.UseWells.value && fn.wells.value.length > 1">
                                    <label class="bk-n-contribution-ui__label">
                                        {{ 'Blending of Water from Various Water Sources' | translate }}
                                    </label>

                                    <div *ngIf="!fn.wells.value.length === 0"
                                        class="bk-n-contribution-ui__invalid-text bk-n-contribution-ui__invalid-text--no-wells">
                                        {{ 'There are no water sources associated with this Planting' | translate }}
                                    </div>

                                    <table *ngIf="fn.wells.value.length"
                                        class="bk-n-contribution-ui__table">
                                        <thead class="bk-n-contribution-ui__table-head">
                                            <tr>
                                                <th class="bk-n-contribution-ui__table-head--border bk-n-contribution-ui__table-column--well">
                                                    {{ 'Water Source' | translate }}
                                                </th>
                                                <th class="bk-n-contribution-ui__table-head--border bk-n-contribution-ui__table-column--ppm">
                                                    {{ 'N Concentration' | translate }}
                                                </th>
                                                <th class="bk-n-contribution-ui__table-head--border bk-n-contribution-ui__table-column--percentage">
                                                    {{ '% Used for Planting' | translate }}
                                                </th>
                                                <th class="bk-n-contribution-ui__table-column--error"></th>
                                            </tr>
                                        </thead>
                                        <tbody class="bk-n-contribution-ui__table-body">
                                            <tr *ngFor="let well of fn.wells.controls; let i = index">
                                                <td class="bk-n-contribution-ui__table-body--border">
                                                    {{well.get('Name').value}}
                                                </td>
                                                <td class="bk-n-contribution-ui__table-body--border">
                                                    {{well.get('NitrogenPPM').value}} ppm
                                                </td>
                                                <td class="bk-n-contribution-ui__table-body--border">
                                                    <mat-form-field appearance="fill"
                                                        class="mat-form-field--remove-label-space">
                                                        <input matInput
                                                            class="mat-input-element--text-align-right"
                                                            type="number"
                                                            step="0.01"
                                                            [formControl]="fn.wells.controls[i].get('Percentage')"
                                                            (blur)="calculateNContribution(true)"/>
                                                        <span matSuffix class="mat-form-field-suffix--padded">%</span>
                                                    </mat-form-field>
                                                </td>
                                                <td class="bk-n-contribution-ui__table-error">
                                                </td>
                                            </tr>
                                            <tr class="bk-n-contribution-ui__table-footer">
                                                <td class="bk-n-contribution-ui__table-footer--avg-text">
                                                    {{ 'Average N Concentration' | translate }}
                                                </td>
                                                <td>
                                                    <span *ngIf="NContribution.calculatedPPM || NContribution.calculatedPPM === 0">
                                                        {{NContribution.calculatedPPM | number: '1.0-2'}} ppm
                                                    </span>
                                                    <span *ngIf="!NContribution.calculatedPPM && NContribution.calculatedPPM !== 0">
                                                        N/A ppm
                                                    </span>
                                                </td>
                                                <td>
                                                    {{ totalWellPercentage }}%
                                                </td>
                                                <td class="bk-n-contribution-ui__table-error">
                                                    <i *ngIf="totalWellPercentage !== 100"
                                                        class="bk-n-contribution-ui__table-error--icon-invalid fa fa-times">
                                                    </i>
                                                    <i *ngIf="totalWellPercentage === 100"
                                                        class="bk-n-contribution-ui__table-error--icon-valid fa fa-check"></i>
                                                    <span *ngIf="totalWellPercentage !== 100"
                                                        class="bk-n-contribution-ui__table-error--text">
                                                        {{'Percentages must total 100' | translate}}.
                                                        {{ 'You must'}} <ng-container *ngIf="totalWellPercentage < 100">{{'add' | translate }} <b>{{ 100 - totalWellPercentage}}%</b></ng-container>
                                                        <ng-container *ngIf="totalWellPercentage > 100">{{'subtract' | translate}} <b>{{ totalWellPercentage - 100 }}%</b></ng-container>
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </ng-container>

                                <ng-container *ngIf="!fn.UseWells.value">
                                    <mat-form-field appearance="fill">
                                        <mat-label>
                                            {{ 'Water N Concentration' | translate }}:
                                        </mat-label>
                                        <input matInput
                                            type="number"
                                            step="0.01"
                                            min="0"
                                            name="PPMTotal"
                                            formControlName="PPMTotal"
                                            (ngModelChange)="calculateNContribution(true)"/>
                                        <span matSuffix class="mat-form-field-suffix--padded">
                                            {{'ppm' | translate}}
                                        </span>
                                    </mat-form-field>
                                </ng-container>

                                <div class="bk-n-contribution-ui__well--divider">
                                </div>

                                <div class="bk-n-contribution-ui__row">
                                    <div class="bk-n-contribution-ui__rec-irrigation">
                                        <label class="bk-n-contribution-ui__label">
                                            {{ 'Recommended Irrigation Amount' | translate }}
                                        </label>
                                        <div class="bk-n-contribution-ui__value--container">
                                            <div class="bk-n-contribution-ui__value">
                                                <ng-container *ngIf="irrigationUnit === 1">
                                                    {{f.IrrigationRecommendation.value | number: '1.0-2'}}
                                                </ng-container>

                                                <ng-container *ngIf="irrigationUnit === 2">
                                                    {{NContribution.recommendationHours}}
                                                </ng-container>
                                            </div>
                                            <div *ngIf="irrigationUnit === 1"
                                                class="bk-n-contribution-ui__unit">
                                                {{'Inches' | translate}}
                                            </div>
                                            <div *ngIf="irrigationUnit === 2"
                                                class="bk-n-contribution-ui__unit">
                                                {{'hours' | translate}}
                                            </div>
                                            <i [popper]="recommendedIrrigationPopper"
                                                [popperTrigger]="'click'"
                                                [popperPlacement]="'top'"
                                                [popperTimeoutAfterShow]="10000"
                                                class="bk-n-contribution-ui__tooltip-icon fa fa-info-circle"></i>
                                        </div>
                                    </div>
                                    <div class="bk-button-set bk-n-contribution-ui__button-set">
                                        <button class="bk-button bk-button-set__button bk-unit-menu__button bk-button--gray-icon"
                                            [ngClass]="{'bk-button-set__button--selected': irrigationUnit === 1}"
                                            type="button"
                                            (click)="setIrrigationUnit(1)">
                                            {{'Inches' | translate }}
                                        </button>
                                        <button class="bk-button bk-button-set__button bk-unit-menu__button bk-button--gray-icon"
                                            [ngClass]="{'bk-button-set__button--selected': irrigationUnit === 2}"
                                            type="button"
                                            (click)="setIrrigationUnit(2)">
                                            {{'Hours' | translate }}
                                        </button>
                                    </div>
                                </div>

                                <div class="bk-n-contribution-ui__well--divider"></div>

                                <div class="bk-n-contribution-ui__row">
                                    <div class="bk-n-contribution-ui__rec-irrigation">
                                        <mat-form-field appearance="fill"> 
                                            <mat-label>
                                                {{ 'Calculate Contribution for' | translate }}:
                                            </mat-label>
                                            
                                            <input matInput *ngIf="irrigationUnit === irrigationUnits.INCHES"
                                                class="bk-modal__field bk-n-contribution-ui__input--short"
                                                type="number"
                                                step="0.01"min="0" 
                                                formControlName="IrrigationAmount"/>

                                            <input matInput *ngIf="irrigationUnit === irrigationUnits.HOURS"
                                                class="bk-modal__field bk-n-contribution-ui__input--short"
                                                type="number"
                                                step="0.1"
                                                min="0" 
                                                formControlName="irrigationAmountHours"/>

                                            <span matSuffix>
                                                <span class="bk-n-contribution-ui__unit"
                                                    *ngIf="irrigationUnit === irrigationUnits.INCHES">
                                                    {{'Inches' | translate}}
                                                </span>
                                                <span class="bk-n-contribution-ui__unit"
                                                    *ngIf="irrigationUnit === irrigationUnits.HOURS">
                                                    {{'hours' | translate}}
                                                </span>
                                            </span>
                                            <mat-error *ngIf="fn.IrrigationAmount.invalid && (fn.IrrigationAmount.dirty ||
                                                fn.IrrigationAmount.touched)">
                                                {{'Irrigation amount cannot be empty' | translate}}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div class="bk-n-contribution-ui__well--divider"></div>

                                <label class="bk-n-contribution-ui__label">
                                    {{ 'N Contribution From Water' | translate }}
                                </label>
                                <div class="bk-n-contribution-ui__value--container">
                                    <div class="bk-n-contribution-ui__value"
                                        *ngIf="units === 'lbsNPerAcre'">
                                        {{f.NAppliedFromWater.value | number: '1.0-2'}}
                                    </div>
                                    <div class="bk-n-contribution-ui__value"
                                        *ngIf="units === 'fertilizerUnit'">
                                        {{getNAppliedFromWaterInFertilizerUnits() | number: '1.0-2'}}
                                    </div>
                                    <div class="bk-n-contribution-ui__unit"
                                        *ngIf="units === 'lbsNPerAcre'">
                                        lbs N/{{'acre' | translate}}
                                    </div>
                                    <div class="bk-n-contribution-ui__unit"
                                        *ngIf="units === 'fertilizerUnit'">
                                        <span *ngIf="f.IsFormulationTypeDry.value">
                                            {{'lbs' | translate }}/{{'acre' | translate }}
                                        </span>
                                        <span *ngIf="!f.IsFormulationTypeDry.value">
                                            {{'gal' | translate }}/{{'acre' | translate }}
                                        </span>
                                    </div>
                                    <i [popper]="nContributionPopper"
                                        [popperTrigger]="'click'"
                                        [popperPlacement]="'top'"
                                        [popperTimeoutAfterShow]="5000"
                                        class="bk-n-contribution-ui__tooltip-icon fa fa-info-circle">
                                    </i>
                                </div>
                            </div>
                        </form>
                    </div>

                    <!-- end n contribution section -->
                </div>
            </ng-container>

            <div *ngIf='warnings.indexOf(warningTypes.ZERO_LBS_PER_GALLON) > -1 ||
                    (!firstStep && warnings.indexOf(warningTypes.NO_YIELD) > -1)'
                class="bk-modal__warning-section bk-clearfix"
                [ngClass]="{ 'bk-model__warning-section--remove-margin' : !eventId}">
                <div *ngIf="warnings.indexOf(warningTypes.NO_YIELD) > -1"
                    class="bk-modal__warning-section__subsection">
                    <h3>
                        {{ 'Missing Fertilization Information' | translate}}
                    </h3>

                    {{ 'Missing yield warning' | translate }}
                </div>
                <div *ngIf="warnings.indexOf(warningTypes.ZERO_LBS_PER_GALLON) > -1"
                    class="bk-modal__warning-section__subsection">
                    <h3>
                        {{ 'Wet Fertilizer Error' | translate}}
                    </h3>

                    {{ 'If Lbs Per Gallon for a wet fertilizer is zero, CropManage will not be able'
                    + ' to convert lbs N/acre to lbs/gallon. You can correct this in Ranch Settings.' | translate }}
                </div>
            </div>

            <!-- Alfalfa recommendation section -->

            <ng-container *ngIf="!isNDependent && !firstStep">

                 <!-- code is identical -->
                <mat-form-field appearance="fill"
                    class="mat-form-field--short mat-form-field--two-wide__left">
                    <mat-label>
                        {{'Manager Amount' | translate }}
                    </mat-label>
                    <input matInput
                        type="number"
                        step="0.01"
                        formControlName="ManagerFertilizerAmount" 
                        (change)="getLbsNPerAcre()"/>
                    <span matSuffix>
                        <span *ngIf="f.IsFormulationTypeDry.value"
                            class="bk-modal__field-unit">
                            {{'lbs' | translate }}/{{'acre' | translate }}
                        </span>
                        <span *ngIf="!f.IsFormulationTypeDry.value"
                            class="bk-modal__field-unit">
                            {{'gal' | translate }}/{{'acre' | translate }}
                        </span>
                    </span>
                    <mat-hint align="start"><strong>{{ 'Enter the amount recommended by a manager' | translate }}</strong> </mat-hint>
                    <mat-error *ngIf="f.ManagerFertilizerAmount.invalid &&
                        (f.ManagerFertilizerAmount.dirty ||
                        f.ManagerFertilizerAmount.touched)">
                        {{'Not a valid number' | translate }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill"
                    class="mat-form-field--short mat-form-field--two-wide__right">
                    <mat-label>
                        {{'Amount Applied' | translate}}
                    </mat-label>
                    <input matInput
                        type="number"
                        step="0.01"
                        name="FertilizerApplied"
                        formControlName="FertilizerApplied"
                        (change)="getLbsNPerAcre()"/>
                    <span matSuffix class="mat-form-field-suffix--padded">
                        <ng-container *ngIf="f.IsFormulationTypeDry.value">
                            {{'lbs' | translate }}/{{'acre' | translate }}
                        </ng-container>
                        <ng-container *ngIf="!f.IsFormulationTypeDry.value">
                            {{'gal' | translate }}/{{'acre' | translate }}
                        </ng-container>
                    </span>
                    <mat-hint align="start"><strong>{{ 'Enter the amount that was actually applied' | translate }}</strong> </mat-hint>
                    <mat-error *ngIf="f.FertilizerApplied.invalid &&
                        (f.FertilizerApplied.dirty ||
                        f.FertilizerApplied.touched)"
                        class="bk-invalid-text">

                        {{'Not a valid number' | translate }}
                    </mat-error>
                </mat-form-field>
            </ng-container>

            <div *ngIf="!NContribution.UIVisible && isNDependent &&
                f.soilSampleEventId.value !== SOIL_SAMPLE_DROPDOWN_NEW && !firstStep">
                <ng-container *ngIf="units === 'fertilizerUnit'">
                    <mat-form-field appearance="fill"
                        class="mat-form-field--short mat-form-field--two-wide__left">
                        <mat-label>
                            {{'Manager Amount' | translate }}
                        </mat-label>
                        <input matInput
                            type="number"
                            step="0.01"
                            formControlName="ManagerFertilizerAmount"
                            (change)="getLbsNPerAcre()"/>
                        
                        <span matSuffix *ngIf="f.IsFormulationTypeDry.value" 
                            class="mat-form-field-suffix--padded">
                            {{'lbs' | translate }}/{{'acre' | translate }}
                        </span>
                            
                        <span matSuffix
                            *ngIf="!f.IsFormulationTypeDry.value"
                            class="mat-form-field-suffix--padded">
                            {{'gal' | translate }}/{{'acre' | translate }}
                        </span>
                        
                        <mat-hint align="start"><strong>{{ 'Enter the amount recommended by a manager' | translate }}</strong> </mat-hint>
                        <mat-error *ngIf="f.ManagerFertilizerAmount.invalid &&
                            (f.ManagerFertilizerAmount.dirty ||
                            f.ManagerFertilizerAmount.touched)">

                            {{'Not a valid number' | translate }}
                        </mat-error>
                    </mat-form-field>
                </ng-container>

                <ng-container *ngIf="units === 'lbsNPerAcre'">
                    <mat-form-field appearance="fill"
                        class="mat-form-field--short mat-form-field--two-wide__left">
                        <mat-label>
                            {{'Manager Amount' | translate }}
                        </mat-label>
                        <input matInput
                            type="number"
                            step="0.01"
                            formControlName="managerNRecommendation"
                            (change)="getFertilizerUnits()"/>
                        <span matSuffix class="mat-form-field-suffix--padded">
                            {{'lbs' | translate }} N/{{'acre' | translate }}
                        </span>

                        <mat-hint align="start"><strong>{{ 'Enter the amount recommended by a manager' | translate }}</strong> </mat-hint>
                        <mat-error *ngIf="f.managerNRecommendation.invalid &&
                            (f.managerNRecommendation.dirty ||
                            f.managerNRecommendation.touched)"
                            class="bk-invalid-text">
                            {{'Not a valid number' | translate }}
                        </mat-error>
                    </mat-form-field>
                </ng-container>

                <ng-container *ngIf="units === 'fertilizerUnit'">
                    <mat-form-field appearance="fill"
                        class="mat-form-field--short mat-form-field--two-wide__right">
                        <mat-label>
                            {{'Fertilizer Applied' | translate }}
                        </mat-label>
                        <input matInput
                            type="number"
                            step="0.01"
                            name="FertilizerApplied"
                            formControlName="FertilizerApplied"
                            (change)="getLbsNPerAcre()"/>
                        <span matSuffix class="mat-form-field-suffix--padded">
                            <span *ngIf="f.IsFormulationTypeDry.value">
                                {{'lbs' | translate }}/{{'acre' | translate }}
                            </span>
                            <span *ngIf="!f.IsFormulationTypeDry.value">
                                {{'gal' | translate }}/{{'acre' | translate }}
                            </span>
                        </span>
                        <mat-hint align="start"><strong>{{ 'Enter the amount that was actually applied' | translate }}</strong> </mat-hint>
                        <mat-error *ngIf="f.FertilizerApplied.invalid &&
                            (f.FertilizerApplied.dirty ||
                            f.FertilizerApplied.touched)"
                            class="bk-invalid-text">
                
                            {{'Not a valid number' | translate }}
                        </mat-error>
                    </mat-form-field>
                </ng-container>
                <ng-container *ngIf="units === 'lbsNPerAcre'">
                    <mat-form-field appearance="fill"
                        class="mat-form-field--short mat-form-field--two-wide__right">
                        <mat-label>
                            {{'Fertilizer N Applied' | translate}}
                        </mat-label>
                        <input matInput
                            type="number"
                            step="0.01"
                            name="NApplied"
                            formControlName="NApplied"
                            (change)="getFertilizerUnits()"/>
                        <span matSuffix class="mat-form-field-suffix--padded">
                            {{'lbs' | translate }} N/{{'acre' | translate }}
                        </span>
                        <mat-error *ngIf="f.NApplied.invalid &&
                            (f.NApplied.dirty ||
                            f.NApplied.touched)"
                            class="bk-invalid-text">

                            {{'Not a valid number' | translate }}
                        </mat-error>
                    </mat-form-field>
                </ng-container>
            </div>

            <span *ngIf='lastUpdated && !NContribution.UIVisible'
                class='bk-modal__last-updated-meta bk-clearfix'>
                {{ 'Last updated by' | translate }} {{lastUpdated}}
            </span>
            <br>
        </form>
    </div>

    <!-- footer -->

    <div mat-dialog-actions
        align="end">

        <ng-container *ngIf="!NContribution.UIVisible">

            <button [disabled]="(f && f.soilSampleEventId.value ===
                SOIL_SAMPLE_DROPDOWN_NEW) || (form && form.invalid)"
                class="bk-button__delete"
                *ngIf="eventId"
                type="button"
                (click)="onDeleteConfirmationOpen($event)">

                {{'Delete' | translate }}
            </button>

            <button mat-button
                [disabled]="(f && f.soilSampleEventId.value ===
                SOIL_SAMPLE_DROPDOWN_NEW)"
                class="bk-button bk-button--medium bk-button--text-only"
                type="button"
                (click)="onClose()">

                {{'Cancel' | translate }}
            </button>

            <button mat-button
                [disabled]="form && form.invalid"
                class="bk-button bk-button--medium bk-dialog__button--primary"
                *ngIf="!eventId && firstStep"
                type="button"
                (click)="nextStep()">

                {{'Calculate Recommendation' | translate }}
            </button>

            <button mat-button
                [disabled]="form && form.invalid"
                class="bk-button bk-button--medium bk-dialog__button--primary"
                *ngIf="!eventId && !firstStep"
                type="button"
                (click)="create()">

                {{(isSaving ? 'Creating' : 'Create') | translate }}
                <img class='bk-button__loader' *ngIf='isSaving' src='/assets/images/loader-20px.gif'/>
            </button>

            <button mat-button
                [disabled]="(f && f.soilSampleEventId.value ===
                SOIL_SAMPLE_DROPDOWN_NEW) ||
                NContribution.UIVisible || (form && form.invalid)"
                class="bk-button bk-button--medium bk-dialog__button--primary"
                *ngIf="eventId"
                type="button"
                (click)="update()">

                {{(isSaving ? 'Saving' : 'Save') | translate }}
                <img class='bk-button__loader' *ngIf='isSaving' src='/assets/images/loader-20px.gif'/>
            </button>
        </ng-container>

        <ng-container *ngIf="NContribution.UIVisible">
            <button mat-button
                class="bk-button bk-button--medium bk-button--text-only"
                type="button"
                (click)="cancelIncludingNContribution($event)">
                {{'Cancel' | translate }}
            </button>

            <button mat-button
                class="bk-button bk-button--medium bk-dialog__button--primary"
                [disabled]="nContributionForm.invalid"
                type="button"
                (click)="updateRecommendationWithNContribution($event)">
                {{'Update Recommendation With N Contribution' | translate }}
            </button>
        </ng-container>
    </div>
</div>

<popper-content #recommendedIrrigationPopper>
    <div class="bk-popper">
        <div class="bk-popper__header">
            {{ 'Recommended Irrigation Amount' | translate }}:
        </div>
        <div class="bk-popper__subheader">
            <span>
                {{ 'From' }} {{ (f && f.EventDate.value) | date:'shortDate' }} 
            </span>
            <span>
                {{ 'to' | translate }} {{ NContribution.nextFertilizationDate | date:'shortDate' }} 
                ({{ 'next planned fertilization' | translate }})
            </span>
        </div>
        <table class="bk-popper__table">
            <tr>
                <td class="bk-popper__column-1">
                    {{ 'Recommended Irrigation Amount' | translate }}:
                </td>
                <td class="bk-popper__column-2">
                    <ng-container *ngIf="irrigationUnit === irrigationUnits.INCHES">
                        {{ (f && f.IrrigationRecommendation.value) | number: '1.0-2' }} {{ 'Inches' | translate }}
                    </ng-container>
                    <ng-container *ngIf="irrigationUnit === irrigationUnits.HOURS">
                        {{ NContribution.recommendationHours }} {{ 'Hours' | translate }}
                    </ng-container>
                </td>
            </tr>
        </table>
    </div>
</popper-content>

<popper-content #fertilizerDetail>
    <div class="bk-popper">
        <div class="bk-popper__header">
            {{ f && f.FertilizerName.value }} 
        </div>
        <table class="bk-popper__table">
            <tr>
                <td class="bk-popper__column-1a">
                    {{'Formulation' | translate }}:
                </td>
                <td class="bk-popper__column-2a">
                    {{ f && f.IsFormulationTypeDry.value ? 'Dry' : 'Wet'}}
                </td>
            </tr>
            <tr>
                <td class="bk-popper__column-1a">
                    {{'Nitrogen' | translate }}:
                </td>
                <td class="bk-popper__column-2a">
                    {{ f && f.NPercentage.value}}%
                </td>
            </tr>
            <tr>
                <td class="bk-popper__column-1a">
                    {{'Other Nutrients' | translate }}:
                </td>
                <td class="bk-popper__column-2a">
                    {{ f && f.FertilizerOtherNutrients.value}}
                </td>
            </tr>
        </table>
    </div>
</popper-content>

<popper-content #nContributionPopper>
    <div class="bk-popper">
        <div class="bk-popper__header">
            {{ 'N Contribution From Water' | translate }}:
        </div>
        <table class="bk-popper__table">
            <tr>
                <td class="bk-popper__column-1">
                    {{ 'Expected Irrigation Amount' | translate }}:
                </td>
                <td class="bk-popper__column-2">
                    <ng-container *ngIf="irrigationUnit === 1 && fn && fn.IrrigationAmount">
                        {{ fn.IrrigationAmount.value | number: '1.0-2' }} {{ 'Inches' | translate }}
                    </ng-container>
                    <ng-container *ngIf="irrigationUnit === 2 && fn && fn.irrigationAmountHours">
                        {{ fn.irrigationAmountHours.value }} {{ 'Hours' | translate }}
                    </ng-container>
                </td>
            </tr>
            <tr>
                <td class="bk-popper__column-1">
                    {{ 'Avg Water N Concentration' | translate }}:
                </td>
                <td class="bk-popper__column-2">
                    <ng-container *ngIf="fn && !fn.UseWells.value">
                        {{ (fn && fn.PPMTotal.value) | number: '1.0-2' }} ppm
                    </ng-container>
                    <ng-container *ngIf="fn && fn.UseWells.value">
                        {{ NContribution.calculatedPPM | number: '1.0-2' }} ppm
                    </ng-container>
                </td>
            </tr>
            <tr>
                <td class="bk-popper__column-1">
                    {{ 'Total N Contribution From Water' | translate }}:
                </td>
                <td class="bk-popper__column-2">
                    <ng-container *ngIf="units === 'lbsNPerAcre'">
                        {{ (f && f.NAppliedFromWater.value) | number: '1.0-2' }} lbs N/{{'acre' | translate}}
                    </ng-container>
                    <ng-container *ngIf="units === 'fertilizerUnit'">
                        {{getNAppliedFromWaterInFertilizerUnits() | number: '1.0-2'}}
                        <span *ngIf="f && f.IsFormulationTypeDry.value">
                            {{'lbs' | translate }}/{{'acre' | translate }}
                        </span>
                        <span *ngIf="f && !f.IsFormulationTypeDry.value">
                            {{'gal' | translate }}/{{'acre' | translate }}
                        </span>
                    </ng-container>
                </td>
            </tr>
        </table>
    </div>
</popper-content>

<popper-content #nContributionEditPopper>
    <div class="bk-popper">
        {{ 'The N contribution from water is calculated from the equation: lbs N/acre = inches of applied water x concentration of nitrateN in water (ppm) x 0.227.' | translate }}
    </div>
</popper-content>