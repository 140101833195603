import { Component, Inject, OnInit, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppliedFertilizerChartDataSet, AppliedFertilizerChartRecord } from './interfaces';
import { DateUtility } from '../../classes/dateUtility';
import { PlantingService } from '../planting-settings/service';
import { IHighChartCustomSeries } from '../available-water-chart/interfaces';
import * as Highcharts from 'highcharts';
import highChartExportOptions from '../shared/highchartsExportOptions';

@Component({
	moduleId: module.id,
	selector: 'applied-fertilizer-chart',
	templateUrl: 'applied-fertilizer-chart.html'
})

export class AppliedFertilizerChartComponent implements OnInit, OnDestroy {

	@ViewChild('chartContainer', { static: true }) private _chartContainer: ElementRef;
	private _chartData: Highcharts.IndividualSeriesOptions[];
	public plantingName: string;
	private _subscriptions$: Subject<boolean>;
	private min: number; // min max date number values for displaying the chart
	private max: number;

	constructor(
		private _service: PlantingService,
		private _dialogRef: MatDialogRef<AppliedFertilizerChartComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: { lotPlantingId: number, name: string },
	) {
	}

	ngOnInit(): void {
		this._subscriptions$ = new Subject();
		this._configureClickOutside(this._subscriptions$, this._dialogRef);

		if (!this._data) {
			throw new Error('data is empty');
		}

		this.plantingName = this._data.name;

		this._service.getAppliedFertilizerChart(this._data.lotPlantingId).then(response => {
			this._convertToGraphData(response);

			this._drawChart();
		});
	}

	ngOnDestroy(): void {
		if (!this._subscriptions$) {
			return;
		}

		this._subscriptions$.next(true);
		this._subscriptions$.complete();
	}

	public cancel(): void {
		this._dialogRef.close();
	}

	private _configureClickOutside(s: Subject<boolean>, d: MatDialogRef<AppliedFertilizerChartComponent>): void {
		// custom click outside behavior
		d.disableClose = true;

		d.backdropClick().pipe(takeUntil(s)).subscribe(() => {
			d.close();
		});
	}

	private _convertToGraphData(records: AppliedFertilizerChartDataSet): void {

		let temp: {
			recommendations: IHighChartCustomSeries,
			AppliedAmounts: IHighChartCustomSeries,
			NUptake: Highcharts.LineChartSeriesOptions
		};

		temp = {
			recommendations: {
				name: 'Recommended',
				visible: true,
				data: [],
				tooltip: {
					valueSuffix: ' lbs N/acre'
				},
			},
			AppliedAmounts: {
				name: 'Fertilizer Applied',
				visible: true,
				data: [],
				tooltip: {
					valueSuffix: ' lbs N/acre'
				}
			},
			NUptake: {
				name: 'N Uptake',
				visible: true,
				data: [],
				tooltip: {
					valueSuffix: ' lbs N/acre'
				},
				marker: {
					enabled: false
				}
			}
		}

		for (let record of records.Recommendations) {
			let utc: number;

			utc = DateUtility.DateToUTC(DateUtility.DotNetToDate(record.EventDate));
			temp.recommendations.data.push([utc, record.Amount]);
		}

		for (let record of records.AppliedAmounts) {
			let utc: number;

			utc = DateUtility.DateToUTC(DateUtility.DotNetToDate(record.EventDate));
			temp.AppliedAmounts.data.push([utc, record.Amount]);
		}

		for (let record of records.NUptake) {
			let utc: number;

			utc = DateUtility.DateToUTC(DateUtility.DotNetToDate(record.EventDate));
			temp.NUptake.data.push([utc, record.Amount]);
		}

		const { minDate, maxDate } = records.NUptake.reduce((acc, curr) => {
			let date: number;

			date = DateUtility.DateToUTC(DateUtility.DotNetToDate(curr.EventDate));

			return {
				minDate: date < acc.minDate ? date : acc.minDate,
				maxDate: date > acc.maxDate ? date : acc.maxDate
			};
		}, { minDate: Infinity, maxDate: -Infinity });

		this.min = minDate;
		this.max = maxDate;

		this._chartData = [];

		this._chartData.push(temp.recommendations);
		this._chartData.push(temp.AppliedAmounts);
		this._chartData.push(temp.NUptake);

		this._chartData.push(this._generateDaysToNextFertilizaiton(records.DaysToNextFertilization,
			records.AppliedAmounts));
	}

	private _generateDaysToNextFertilizaiton(daysRecords: AppliedFertilizerChartRecord[],
		appliedRecords: AppliedFertilizerChartRecord[]): Highcharts.LineChartSeriesOptions {

		let result: Highcharts.LineChartSeriesOptions;

		result = {
			name: 'Days to Next Fertilization',
			visible: true,
			data: [],
			marker: {
				enabled: false
			}
		}

		daysRecords.forEach((record, index) => {
			let utcStart: number;
			let utcEnd: number;

			utcStart = DateUtility.DateToUTC(DateUtility.DotNetToDate(record.EventDate));

			utcEnd = DateUtility.DateToUTC(
				DateUtility.addDays(DateUtility.DotNetToDate(record.EventDate), record.Amount)
			);

			result.data.push([utcStart, appliedRecords[index].Amount]);
			result.data.push([utcEnd, appliedRecords[index].Amount]);
			result.data.push(null);
		});

		return result;
	}

	private _drawChart(): void {
		let options: Highcharts.Options;

		if (!this._chartContainer) {
			return;
		}

		options = {
			chart: {
			// type: 'line',
			},
			title: {
				text: null,
			},
			legend: {
				title: {
					text: null,
				}
			},
			tooltip: {
				backgroundColor: '#FFF',
				borderColor: '#FFF',
				valueDecimals: 2,
				valueSuffix: ' in.',
				// formatter: tooltipFormatter
			},
			xAxis: {
				type: 'datetime',
				gridLineWidth: 1,
				min: this.min,
				max: this.max
			},
			yAxis: [{
				title: {
					text: 'Lbs N/acre'
				}
			}],
			series: this._chartData,
			exporting: highChartExportOptions
		}

		Highcharts.chart(this._chartContainer.nativeElement, options);
	}
}
