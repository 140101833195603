import * as moment from 'moment';

import { ValidateService } from '../../services/validate.service';
import { DateUtility } from '../../classes/dateUtility';

import { IRawIrrigationEvent, IRainfallWeather, IIrrigationEvent, IIrrigationEventEditResponse,
	IIrrigationIntervalRecommendation, IIrrigationEventEditJSON, IIrrigationRecommendationSummaryModelJSON,
	IIrrigationRecommendationSummaryModel,
	IETEvent,
	IAdditionalWaterModel} from './interfaces';
import { IEventGroup } from '../../models/event/interfaces';

import { eIrrigationMethods, eCommodityTypes,
	IRRIGATION_RECENCY_DAYS, CUTTING_RECENCY_DAYS } from '../../interfaces/constants';

import { EventGroup } from '../../models/event/event';
import { IFlowmeterSetting } from './main';
import { TranslateService } from '../localization/service';

export enum eHistoricalIrrigationDateValidationStates {
	VALID = 1,
	EMPTY,
	OUT_OF_RANGE,
	PAST_IRRIGATION_EVENT
}

export interface IUnitRatios {
	ratio: number,
	inverse: number
}

export class IrrigationEvent implements IIrrigationEvent {

	// for use in the historical irrigation ui
	public static readonly targetTensionValues: { name: string, value: number }[] = [
		{
			name: 'Dry',
			value: 1
		},
		{
			name: 'Moderately Moist',
			value: 2
		},
		{
			name: 'Wet/Saturated',
			value: 3
		}
	];

	public Id: number
	public IrrigationMethod: string; // virtual
	public IrrigationMethodId: number;
	public LastUpdatedUser: string;
	public ManagerAmountRecommendation: number;
	public PlantingId: number;
	public Ratio: number;
	public RecommendedIrrigationAmount: number;
	public RecommendedIrrigationInterval: number;
	public WaterApplied: number;
	public IsRainfall: boolean;
	public Metainfo: string;

	public EventDate: string; // virtual
	public DisplayAmount: number; // virtual,

	// raw model properties from combined events list
	public Rainfall: number;
	public Interval: number;
	public IsOnWetDate: boolean;
	public RecommendationEventId: number;

	public RecommendedIrrigationTime: number;
	public SprinklerApplicationRate: number;
	public DripApplicationRate: number;
	public MicroSprinklerApplicationRate: number;
	public HasFlowMeterData: boolean;

	public WetDate: Date;
	public HasRecentCutEvent: boolean;
	public HasRecentIrrigationEvent: boolean;

	// Below are required for user icon component.
	// There USED TO BE both flowMeterGallons and FlowMeterGallons (but been unified in the backend)
	// because one appears attached to plantings and other
	// is in all events popup.
	public HasFlowmeterData: boolean;
	public FlowMeterGallons: number;
	public FlowMeterArea: number;
	public ContributingRainfall: number;
	public CumulativeRainfall: number; // combination of a day's rainfall and contributing rainfall from prior dates
	public CustomDeficit: number;

	public FlowMeterData: { // virtual - used to figure out which flowmeter area to display, based on irrigation method
		DripArea: number,
		SprinklerArea: number,
		CalculatedArea: number
	}

	// View properties
	public RecommendationInches: number;
	public RecommendationHours: number;
	public AppliedInches: number;
	public AppliedHours: number;
	public DisplayInches: number;
	public DisplayHours: number;
	public ShowDetails: boolean;
	// currently undefined structure
	public AdditionalData: IAdditionalWaterModel;

	private _ts: TranslateService; // used for translation

	/**
     * TODO: This method should not take methods. It should just get the ratio.
     * @param method
     * @param sprinklerRate
     * @param dripRate
     * @param microSprinklerRate
     */
	public static getUnitConversionRatio(method: eIrrigationMethods, sprinklerRate: number, dripRate: number,
		microSprinklerRate?: number): IUnitRatios {

		let ratios: IUnitRatios;

		ratios = {
			ratio: null,
			inverse: null
		};

		switch (method) {
			case eIrrigationMethods.SPRINKLER:
			case eIrrigationMethods.GERMINATION_SPRINKLER:
				ratios.ratio = sprinklerRate;
				break;
			case eIrrigationMethods.DRIP:
				ratios.ratio = dripRate;
				break;
			case eIrrigationMethods.MICRO_SPRINKLER:
				ratios.ratio = microSprinklerRate;
				break;
			default:
				ratios.ratio = 1;
		}

		if (!ratios.ratio) {
			ratios.ratio = 1;
			ratios.inverse = 1;
		} else {
			ratios.inverse = 1 / ratios.ratio;
		}

		return ratios;
	}

	/**
	 * TODO: This needs to be combined with calculate.service.ts.. these two methods are creating double points of entry
	 * Actually TRIPLE because of the function below
	 * Use the one with pure parameters and get rid of other versions
	 * @param inches
	 */
	public static convertInchesToHours(inches: number, method: eIrrigationMethods, sprinklerRate: number, dripRate: number,
		microSprinklerRate?: number): number {
		let ratios: IUnitRatios;

		if (!inches) {
			return 0;
		}

		ratios = IrrigationEvent.getUnitConversionRatio(method, sprinklerRate, dripRate, microSprinklerRate);

		return inches / ratios.ratio;
	}

	public static getCropManageRecommendedIrrigationDate(lastIrrigationDate: Date, interval: number): Date {

		if (!lastIrrigationDate || !ValidateService.isValidNumber(interval)) {
			return null;
		}

		interval = interval < 1 ? 1 : Math.round(interval);

		return DateUtility.addDays(lastIrrigationDate, interval);
	}

	// TODO: Get rid of this method
	public static isFloodEvent(IrrigationMethodId: eIrrigationMethods): boolean {
		return IrrigationMethodId === eIrrigationMethods.FLOOD;
	}

	/**
     * used to show "recommendation is NA" warning
     * if there are no applied or manager recommendation values and the cropmanage
	 * recommendation is null (last irrigation event was too long ago)
     * @param event
     */
	public static isIrrigationRecommendationNotGeneratedWarningShown(event: IEventGroup): boolean {
		if (!event || !event.Irrigation) {
			return false; ;
		}

		return !ValidateService.isValidNumber(event.Irrigation.WaterApplied) &&
			!ValidateService.isValidNumber(event.Irrigation.ManagerAmountRecommendation)
			&& !ValidateService.isValidNumber(event.Irrigation.RecommendedIrrigationAmount);
	}

	public static isRecommendationNA(event: IrrigationEvent, values: number[]): boolean {
		let eventDate: Date;

		if (!event || !values || !values.length) {
			return false;
		}

		eventDate = DateUtility.DotNetToDate(event.EventDate);

		if (event.IsRainfall || event.IrrigationMethodId === eIrrigationMethods.RAINFALL // it is rainfall
			|| (event.IrrigationMethodId === eIrrigationMethods.GERMINATION_SPRINKLER && event.IsOnWetDate)
			// it is germination sprinkler and on wet date
			|| !ValidateService.isValidNumber(event.RecommendationEventId) || event.RecommendationEventId <= 0 // recomendation event id is invalid
			|| DateUtility.daysBetweenDates(event.WetDate, eventDate) <= 0) { // it is on or before wet date
			return true;
		}

		for (let value of values) {
			if (ValidateService.isValidNumber(value) && value >= 0) {
				return false;
			}
		}

		return true;
	}

	/**
     * We simply show or hide leaf based on LastUpdatedUser string, which is empty
     * if system last updated the event. We no longer use the leaf to distinguish between
     * cropManage recommendation or manager
     * @param event
     */
	public static shouldShowLeafIcon(event: IIrrigationEvent): boolean {
		if (!event.LastUpdatedUser) {
			return true;
		} else {
			return false;
		}
	}

	/**
     * Return interval and date.
     * WARNING: Also sets last Irrigation event date and recommended interval
     * for the event
     *
     * @param event
     */
	public static getIntervalRecommendation(
		lastIrrigationDate: Date, recommendedInterval: number): IIrrigationIntervalRecommendation {

		let result: IIrrigationIntervalRecommendation;

		result = {
			date: null,
			interval: null
		};

		if (!event) {
			return result;
		}

		if (lastIrrigationDate === null) {
			return result;
		}

		result.date = IrrigationEvent.
			getCropManageRecommendedIrrigationDate(lastIrrigationDate,
				recommendedInterval);

		result.interval = DateUtility.daysBetweenDates(lastIrrigationDate,
			result.date);

		return result;
	}

	public static getEmptyEditResponse(): IIrrigationEventEditResponse {
		let result: IIrrigationEventEditResponse;

		result = {
			AverageDeficit: null,
			DaysSinceLastIrrigation: null,
			CIMISPrecipitation: null,
			DripApplicationRate: null,
			MicroSprinklerApplicationRate: null,
			DripFlowMeterArea: null,
			FlowMeterArea: null,
			MicroSprinklerFlowMeterArea: null,
			FlowMeterGallons: null,
			FlowMeterCalculatedArea: null,
			HasFlowMeterData: null,
			Id: null,
			IrrigationMethodId: null,
			IrrigationMethods: null,
			IsCustomDeficitEnabled: null,
			ManagerAmountRecommendation: null,
			Rainfall: null,
			Ratio: null,
			RecentIrrigationEventExists: null,
			RecentCutEventExists: null,
			RecommendationSummary: null,
			RecommendedHours: null,
			RecommendedInterval: null,
			RecommendedIrrigationAmount: null,
			SprinklerApplicationRate: null,
			WaterApplied: null,
			isLastUpdatedUserCropManage: null,
			newRecommendationAmount: null,
			newRecommendationTime: null,
			FlowMeterWaterApplied: null,
			recalculateFlag: null,
			recalculateReason: null,
			EndDate: null,
			EventDate: null,
			InitialDate: null,
			LastIrrigationEventDate: null,
			PlantingEndDate: null,
			PlantingStartDate: null,
			StartDate: null,
		}

		return result;
	}

	public static convertRecommendationSummaryJSON(d: IIrrigationRecommendationSummaryModelJSON): IIrrigationRecommendationSummaryModel {
		let result: IIrrigationRecommendationSummaryModel;

		if (!d) {
			return null;
		}

		if (!d) {
			return null;
		}

		result = {
			RecommendedIrrigationAmount: d.RecommendedIrrigationAmount,
			RecommendedIrrigationInterval: d.RecommendedIrrigationInterval,
			RecommendedIrrigationTime: d.RecommendedIrrigationTime,
			AverageRainfall: d.AverageRainfall,
			applicationRate: d.applicationRate,
			AvailableWater: d.AvailableWater,
			AverageCropET: d.AverageCropET,
			AverageCropCoefficient: d.AverageCropCoefficient,
			BaseIrrigationAmount: d.BaseIrrigationAmount,
			DaysSinceLastIrrigation: d.DaysSinceLastIrrigation,
			IsImpactedByMacroTunnel: d.IsImpactedByMacroTunnel,
			Distribution: d.Distribution,
			LeechingFactor: d.LeechingFactor,
			TotalPrecipitation: d.TotalPrecipitation,
			CIMISRainfall: d.CIMISRainfall,
			SumET: d.SumET,
			CropCoefficientSum: d.CropCoefficientSum,
			CropSensitivity: d.CropSensitivity,
			ETEvents: null,
			WetArea: d.WetArea,
			HasRecentCutEvents: d.HasRecentCutEvents,
			HasRecentIrrigationEvent: d.HasRecentIrrigationEvent,
			LastIrrigationEventDate: DateUtility.DotNetToDate(d.LastIrrigationEventDate),
			ValidRainEventsCount: d.ValidRainEventsCount,
		};

		if (d.ETEvents) {
			result.ETEvents = new Array();

			for (let etEvent of d.ETEvents) {
				let target: IETEvent;

				target = {
					ET: etEvent.ET,
					EventDate: DateUtility.DotNetToDate(etEvent.EventDate),
					LastUpdateDate: DateUtility.DotNetToDate(etEvent.LastUpdateDate),
					Source: etEvent.Source
				};

				result.ETEvents.push(target);
			}
		}

		return result;
	}

	public static convertEditJSONtoResponse(data: IIrrigationEventEditJSON): IIrrigationEventEditResponse {
		let result: IIrrigationEventEditResponse;

		result = {
			AverageDeficit: data.AverageDeficit,
			DaysSinceLastIrrigation: data.DaysSinceLastIrrigation,
			CIMISPrecipitation: data.CIMISPrecipitation,
			CustomDeficit: data.CustomDeficit,
			DripApplicationRate: data.DripApplicationRate,
			DripFlowMeterArea: data.DripFlowMeterArea,
			EndDate: DateUtility.DotNetToDate(data.EndDate),
			EventDate: DateUtility.DotNetToDate(data.EventDate),
			FlowMeterWaterApplied: null,
			FlowMeterArea: data.FlowMeterArea,
			FlowMeterCalculatedArea: null, // need to be calculated - virtual
			FlowMeterGallons: data.FlowMeterGallons,
			HasFlowMeterData: data.HasFlowMeterData,
			InitialDate: DateUtility.DotNetToDate(data.InitialDate),
			IrrigationMethodId: data.IrrigationMethodId,
			IrrigationMethods: data.IrrigationMethods,
			Id: data.Id,
			IsCustomDeficitEnabled: data.IsCustomDeficitEnabled,
			isLastUpdatedUserCropManage: data.isLastUpdatedUserCropManage,
			LastIrrigationEventDate: DateUtility.DotNetToDate(data.LastIrrigationEventDate),
			LastUpdated: data.LastUpdated,
			ManagerAmountRecommendation: data.ManagerAmountRecommendation,
			ManagerAmountRecommendationHours: data.ManagerAmountRecommendationHours,
			MicroSprinklerApplicationRate: data.MicroSprinklerApplicationRate,
			MicroSprinklerFlowMeterArea: data.MicroSprinklerFlowMeterArea,
			newRecommendationAmount: null,
			newRecommendationTime: null,
			PlantingEndDate: DateUtility.DotNetToDate(data.PlantingEndDate),
			PlantingStartDate: DateUtility.DotNetToDate(data.PlantingStartDate),
			Rainfall: data.Rainfall,
			Ratio: data.Ratio,
			recalculateFlag: data.recalculateFlag,
			recalculateReason: data.recalculateReason,
			RecentCutEventExists: data.RecentCutEventExists,
			RecentIrrigationEventExists: data.RecentIrrigationEventExists,
			RecommendationSummary: IrrigationEvent.convertRecommendationSummaryJSON(data.RecommendationSummary),
			RecommendedHours: data.RecommendedHours,
			RecommendedInterval: data.RecommendedInterval,
			RecommendedIrrigationAmount: parseFloat(data.RecommendedIrrigationAmount),
			SprinklerApplicationRate: data.SprinklerApplicationRate,
			StartDate: DateUtility.DotNetToDate(data.StartDate),
			WaterApplied: data.WaterApplied,
			WaterAppliedHours: data.WaterAppliedHours,
		}

		return result;
	}

	public static getFlowMeterArea(methodId: number, setting: IFlowmeterSetting): number {

		let result: number;

		if (!setting) {
			return;
		}

		switch (methodId) {
			case eIrrigationMethods.DRIP:
				result = setting.areas.drip;
				break;
			case eIrrigationMethods.MICRO_SPRINKLER:
				result = setting.areas.microSprinkler;
				break;
			default:
				// defaults to sprinkler flowmeter area, specified in planting settings
				result = setting.areas.default;
				break;
		}

		return result;
	}

	/**
	 * Determins if "missing applied value" warning should be displayed. If an irrigation
	 * method is not rainfall or germination sprinkler, the event is scheduled for
	 * the past, and if water applied is null, display a warning, even if the
	 * CropManage recommendation is N/A.
	 * @param irrigation
	 * @param past
	 * @returns
	 */
	public static shouldShowAppliedAmountMissingWarning(irrigation: IIrrigationEvent, past: boolean): boolean {
		if (!irrigation) {
			return false;
		}

		if (!past) {
			return false;
		}

		if (irrigation.WaterApplied !== null) {
			return false;
		}

		if (irrigation.IrrigationMethodId === eIrrigationMethods.RAINFALL ||
			irrigation.IrrigationMethodId === eIrrigationMethods.GERMINATION_SPRINKLER) {
			return false;
		}

		return true;
	}

	public constructor(translateService?: TranslateService) {
		this._ts = translateService;
	}

	/**
     * In event all tab, we show NA if recommendation is invalid AND applied value is null. We ignore manager amount.
     *
     * Used in events snapshot, events table and list.
     * Logic should be 1) is recommendation NA? (Ideally the backend should return -1 when recommendation is not calculated
     * then look at manager recommendation/applied value to resolve)
     *
     * @param event
     */
	public isNA(event: IIrrigationEvent, wetDate: Date): boolean {

		if (!event) {
			return true;
		}

		if (event.RecommendationInches > 0) {
			// if recommendation is non zero, display the recommendation - otherwise
			// recommendation not applied icon will display together with N/A and confuse users
			return false;
		}

		// show N/A if on or before wet date and there are no other values
		if (DateUtility.isOnOrBefore(DateUtility.DotNetToDate(event.EventDate), wetDate)
			&& !ValidateService.isValidNumber(event.WaterApplied) &&
			!ValidateService.isValidNumber(event.ManagerAmountRecommendation)) {
			return true;
		}

		if (event.DisplayAmount >= 0) {
			return false;
		}

		if (event.RecommendedIrrigationAmount === -1) {
			return true;
		}

		return false;
	}

		/**
     * Get inches for a "combined" field, which may show recommendation or applied value, depending on the situation
     *
     * @param event
     * @param rainfallWeather
     */
	public getDisplayInches(event: IIrrigationEvent, rainfallWeather?: IRainfallWeather): number {
		if (!event) {
			return;
		}

		if (event.IrrigationMethodId === eIrrigationMethods.RAINFALL) {
			return rainfallWeather ? rainfallWeather.Rainfall : event.DisplayInches ? event.DisplayInches : 0;
		}

		if (event.WaterApplied !== null) {
			return event.WaterApplied;
		}

		if (event.ManagerAmountRecommendation !== null) {
			return event.ManagerAmountRecommendation;
		}

		if (event.RecommendedIrrigationAmount !== null) {
			return event.RecommendedIrrigationAmount;
		}

		return -1;
	}

	/**
     * Get number of hours to show in a "combined" field, where applied, manager, or recommendation hours
     * may show, depending on the situation
     *
     * @param event
     * @param rainfallWeather
     */
	public getDisplayHours(event: IIrrigationEvent): number {
		let result: number;
		let displayInches: number; // number to display in the UI, depending on the situation

		if (!event) {
			return;
		}

		if (event.RecommendedIrrigationAmount) {
			event.RecommendedIrrigationAmount = ValidateService.roundTo2DecimalPlaces(event.RecommendedIrrigationAmount)
		}

		displayInches = this.getDisplayInches(event);

		result = IrrigationEvent.convertInchesToHours(displayInches, event.IrrigationMethodId, event.SprinklerApplicationRate,
			event.DripApplicationRate, event.MicroSprinklerApplicationRate);

		return result;
	}

	public getWarningTextForAllTab(eventGroup: EventGroup, commodityTypeId: number): string {
		let result: string;

		result = '';

		if (!eventGroup) {
			return '';
		}

		if (!eventGroup.Irrigation) {
			return '';
		}

		if (!eventGroup.EventDate) {
			return '';
		}

		if (IrrigationEvent.shouldShowAppliedAmountMissingWarning(eventGroup.Irrigation, eventGroup.isPast)) {
			result = `${this._ts.translate('Enter an amount in the Water Applied field')}. `;
		}

		if (eventGroup.isOnOrBeforeWetDate === true) { // we don't need a warning for NA recommendations before planting start date
			return result;
		}

		if (eventGroup.Irrigation.IrrigationMethodId === eIrrigationMethods.RAINFALL) { // don't need to worry about rainfall events
			return result;
		}

		if (eventGroup.Irrigation.RecommendedIrrigationAmount === -1 &&
			eventGroup.Irrigation.HasRecentIrrigationEvent === false) {

			result += this.getWarningTextForIrrigation(eventGroup.EventDate);
		}

		if (commodityTypeId === eCommodityTypes.ALFALFA &&
			eventGroup.Irrigation.HasRecentCutEvent === false) {

			result += this.getWarningTextForCutting(eventGroup.EventDate);
		}

		return result;
	}

	public getWarningTextForCutting(eventDate: Date): string {
		let result: string;
		let recencyDate: Date;

		recencyDate = new Date(eventDate.getTime());
		recencyDate.setDate(recencyDate.getDate() - CUTTING_RECENCY_DAYS);

		result = `${this._ts.translate('Please add a cutting event within')}
			${CUTTING_RECENCY_DAYS} ${this._ts.translate('days')} (${recencyDate.toLocaleDateString('en-US')})
			${this._ts.translate('prior to this event for more accurate recommendation')}. `;

		return result;
	}

	public getWarningTextForIrrigation(eventDate: Date): string {
		let result: string;
		let recencyDate: Date;

		if (!eventDate) {
			return null;
		}

		recencyDate = new Date(eventDate.getTime());
		recencyDate.setDate(recencyDate.getDate() - IRRIGATION_RECENCY_DAYS);

		result = `${this._ts.translate('Please add a recent irrigation event within')}
			${IRRIGATION_RECENCY_DAYS} ${this._ts.translate('days')} (${recencyDate.toLocaleDateString('en-US')})
			${this._ts.translate('prior to this event to receive a recommendation')}.`;

		return result;
	}

	/**
	 * Used to display a warning icon next to the recommendation in
	 * the irrigation event dialog
	 *
	 * @param isFloodEvent
	 * @param units
	 * @param recentCutEventExists
	 * @param commodityTypeId
	 * @param daysSinceLastIrrigation
	 * @param validRainEventsCount
	 */
	public getWarningTextRecommendation(isFloodEvent: boolean,
		units: string, recentCutEventExists: boolean, commodityTypeId: number,
		daysSinceLastIrrigation: number, validRainEventsCount: number, zeroETEventsCount: number, isFuture: boolean): string {

		let result = '';

		if (units === 'inches' || isFloodEvent) {
			if (!recentCutEventExists && commodityTypeId === eCommodityTypes.ALFALFA) {
				result += `${this._ts.translate('Recommendation is NA or inaccurate due to missing cut events')}.`;
			}
		}

		if (isFuture) {
			result += `${this._ts.translate('Irrigation recommendations for future dates warning')}`;
		} else if (daysSinceLastIrrigation > validRainEventsCount) {
			let difference: number;

			difference = daysSinceLastIrrigation - validRainEventsCount;

			if (result) {
				result += ' ';
			}

			result += `${this._ts.translate('Recommendation is based on incomplete weather data')}.
				${difference} ${this._ts.translate('day(s) of rainfall data are missing')}.`;
		}

		if (zeroETEventsCount > 0) {
			result += `${this._ts.translate('Inaccurate ET warning')}. `;
		}

		return result;
	}

	public convert(rawIrrigationEvent: IRawIrrigationEvent, rainfallWeather: IRainfallWeather, isFuture = true): void {

		this.Metainfo = rawIrrigationEvent.Metainfo;
		this.Id = rawIrrigationEvent.Id;
		this.IrrigationMethod = rawIrrigationEvent.IrrigationMethod;
		this.IrrigationMethodId = rawIrrigationEvent.IrrigationMethodId;
		this.EventDate = rawIrrigationEvent.EventDate;
		this.Ratio = rawIrrigationEvent.Ratio;
		this.WaterApplied = rawIrrigationEvent.WaterApplied;
		this.ContributingRainfall = rawIrrigationEvent.ContributingRainfall;
		if (rawIrrigationEvent.Rainfall !== null && rawIrrigationEvent.Rainfall !== undefined) {
			this.Rainfall = rawIrrigationEvent.Rainfall;
		} else if (rainfallWeather) {
			this.Rainfall = rainfallWeather.Rainfall;
		}

		this.CumulativeRainfall = (this.Rainfall ? this.Rainfall : 0) +
			(rawIrrigationEvent.ContributingRainfall ? rawIrrigationEvent.ContributingRainfall : 0);

		this.Interval = rawIrrigationEvent.Interval;
		this.IsOnWetDate = rawIrrigationEvent.IsOnWetDate;

		this.RecommendationEventId = rawIrrigationEvent.RecommendationEventId;

		this.RecommendedIrrigationInterval = rawIrrigationEvent.RecommendedIrrigationInterval;
		this.RecommendedIrrigationAmount = rawIrrigationEvent.RecommendedIrrigationAmount;
		this.RecommendedIrrigationTime = rawIrrigationEvent.RecommendedIrrigationTime;

		this.ManagerAmountRecommendation = rawIrrigationEvent.ManagerAmountRecommendation;
		this.PlantingId = rawIrrigationEvent.PlantingId;

		this.SprinklerApplicationRate = rawIrrigationEvent.SprinklerApplicationRate;
		this.DripApplicationRate = rawIrrigationEvent.DripApplicationRate;
		this.MicroSprinklerApplicationRate = rawIrrigationEvent.MicroSprinklerApplicationRate;

		this.LastUpdatedUser = rawIrrigationEvent.LastUpdatedUser;
		this.HasFlowMeterData = rawIrrigationEvent.HasFlowMeterData;
		this.FlowMeterGallons = rawIrrigationEvent.FlowMeterGallons;
		this.FlowMeterArea = rawIrrigationEvent.FlowMeterArea;
		this.RecommendationInches = 0;
		this.RecommendationHours = 0;
		this.AppliedInches = 0;
		this.AppliedHours = 0;
		this.IsRainfall = false;
		this.HasRecentCutEvent = rawIrrigationEvent.HasRecentCutEvent;
		this.HasRecentIrrigationEvent = rawIrrigationEvent.HasRecentIrrigationEvent;
		this.CustomDeficit = rawIrrigationEvent.CustomDeficit;

		this.WetDate = DateUtility.DotNetToDate(rawIrrigationEvent.WetDate);

		if (!isFuture) {
			this.AppliedInches = this.WaterApplied; // REFACTOR - appliedInches doesn't need to exit

			this.AppliedHours = IrrigationEvent.convertInchesToHours(this.AppliedInches, this.IrrigationMethodId,
				this.SprinklerApplicationRate, this.DripApplicationRate, this.MicroSprinklerApplicationRate);
		}

		// do not show manager recommendations in the table
		this.RecommendationInches = this.RecommendedIrrigationAmount;
		this.RecommendationHours = this.RecommendedIrrigationTime;

		if (this.IrrigationMethodId === eIrrigationMethods.RAINFALL) {
			this.IsRainfall = true; // REFACTOR - this property doesn't need to exist

			this.DisplayAmount = this.CumulativeRainfall; // REFACTOR - get rid of these redundant variables with unclear names
			this.DisplayInches = this.CumulativeRainfall;

			if (rainfallWeather) {
				this.DisplayHours = this.CumulativeRainfall;
			} else {
				this.DisplayHours = this.CumulativeRainfall
			}
		} else {
			this.IsRainfall = false;

			this.DisplayInches = this.getDisplayInches(this, rainfallWeather);
			this.DisplayAmount = this.DisplayInches;
			this.DisplayHours = this.getDisplayHours(this);
		}

		this.ShowDetails = false;
	}

	/**
     * We display hours or inches for flood, depending on whether we're displaying calculated recommendation
     * or not
     */
	public getFloodAmount(): number {

		if (this.WaterApplied || this.ManagerAmountRecommendation) {
			return this.RecommendationInches;
		} else {
			return this.RecommendationHours;
		}
	}
}
