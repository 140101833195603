import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { RanchService } from '../ranch-settings/service';
import { UpdateService } from '../../services/update.service';
import { ValidateService } from '../../services/validate.service';
import { SoilSampleEventService } from './service';
import * as moment from 'moment';

import { SoilSampleEvent } from './soilSampleEvent';

import { IEventGroup, IEventPostResponseJSON } from '../../models/event/interfaces';
import { IPlanting } from '../planting-settings/interfaces';
import { eNotificationTypes, eSampleTypeIds, EventsType } from '../../interfaces/constants';
import { EventGroup } from '../../models/event/event';
import { DateUtility } from '../../classes/dateUtility';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IFormNutrient } from '../../models/nutrient/interfaces';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormGroup, AbstractControl, FormBuilder, FormArray, Validators, ValidatorFn } from '@angular/forms';
import { IFormCropStage } from '../../models/crop-stage/interfaces';
import { ISelectOptions } from '../../interfaces/abstract';
import { FormValidation } from '../../classes/formValidation';
import { BaseDialog } from '../shared/dialogs/base-dialog';
import { SharedUpdateService } from '../shared/dialogs/update.service';
import { SoilSampleWarningDialog } from './warning';
import { PlantingRecalculatorService } from '../planting-recalculate-modal/plantingRecalculatorService';
import { NotificationService } from '../../services/notification.service';

export type SoilSampleDialogFields = 'EventDate' | 'SampleTypeId' |
	'SampleDepth' | 'CropStageId' | 'SoilMoistureId' | 'Nitrogen' |
	'OtherNutrients';

export interface SoilSampleDialogForm {
	Id: number,
	EventDate: Date,
	SampleTypeId: number,
	SampleDepth: number,
	CropStageId: number,
	SoilMoistureId: number,
	Nitrogen: number,
	OtherNutrients: OtherNutrientsForm[],
	isFuture: boolean,
	isNDependent: boolean,
}

export interface OtherNutrientsForm {
	Id: number,
	Name: string,
	value: number,
	MeasurementUnitName: string,
}

export enum eSoilMoistures {
	DRY = 1,
	MOIST = 2
}

export enum eSoilSampleDepths {
	FEET_ONE = 1,
	FEET_TWO = 2
}

@Component({
	moduleId: module.id,
	selector: 'soil-sample-event',
	templateUrl: 'main.html',
	styleUrls: [ 'main.scss']
})

export class SoilSampleEventDialog extends BaseDialog implements OnInit, OnDestroy {

	public affectedEvents: IEventGroup[] = new Array();
	public cropStages: IFormCropStage[];
	public eventId: number;
	public form: FormGroupTyped<SoilSampleDialogForm>;
	public isSaving = false; // used to display a spinner in the save button
	public isLoaded = false;
	public lastUpdatedUser: string;
	public maxDate: Date; // maximum valid date for EventDate
	public moistureTypes: ISelectOptions[];
	public otherNutrients: IFormNutrient[];
	public readonly SAMPLE_TYPE_LAB_TEST = 2;
	public readonly SAMPLE_TYPE_QUICK_NITRATE_STRIP = 1;
	public sampleTypes: ISelectOptions[];
	public DEPTHS = eSoilSampleDepths;

	private _allEvents: IEventGroup[] = new Array();
	private _dateDefault: Date; // used for Calendar UI
	private _harvestDate: Date;
	private _originalEventDate: Date;
	private _plantingId: number = null;

	public get f(): { [key in SoilSampleDialogFields]: AbstractControl } {
		return this.form.controls as { [key in SoilSampleDialogFields]: AbstractControl };
	}

	constructor(
		@Inject(MAT_DIALOG_DATA) private _data: { plantingId: number, id?: number, dateDefault?: Date },
		private dialog: MatDialog,
		private dialogRef: MatDialogRef<SoilSampleEventDialog>,
		private _fb: FormBuilder,
		private _notificationService: NotificationService,
		private _plantingRecalculatorService: PlantingRecalculatorService,
		private _ranchService: RanchService,
		private _soilSampleEventService: SoilSampleEventService,
		private _updateService: UpdateService,
		private sharedUpdateService: SharedUpdateService
	) {

		super(dialog, dialogRef, sharedUpdateService);

		if (this._data) {
			this.eventId = this._data.id;
			this._plantingId = this._data.plantingId;
			this._dateDefault = this._data.dateDefault;
		}
	}

	ngOnInit() {
		this._subscriptions$ = new Subject();
		this.clear();

		if (this.eventId) {
			this._getEditModel(this.eventId);
		} else {
			this._getCreateModel();
		}

		super.ngOnInit();
	}

	ngOnDestroy(): void {
		if (!this._subscriptions$) {
			return;
		}

		this._subscriptions$.next(true);
		this._subscriptions$.complete();
	}

	private clear(): void {
		this.affectedEvents = new Array();
		this._allEvents = new Array();
	}

	private _configureDeleteConfirmationText(): void {

		if (this.f.EventDate.invalid) {
			return;
		}

		this._deleteConfirmationData = {
			objectName: 'Soil Sample Event',
			specificName: null,
			additionalMessage: 'Are you sure you want to delete this soil sample event dated '
				+ (this.f.EventDate.value as Date).toDateString() + '?'
		};
	}

	public close(): void {
		this._updateService.setEventDialogClosed(); // tell event table to re-display
		super.close();
	}

	public create(): void {
		let event: SoilSampleEvent;

		if (this.form.invalid) {
			return;
		}

		event = this._getModelFromForm();

		this._truncateNutrientValues();

		if (this.isSaving) {
			return;
		}

		this.isSaving = true;

		this._soilSampleEventService.create(this._plantingId, event)
			.then(() => {
				this.isSaving = false;
				this.close();
				this._plantingRecalculatorService.recalculate(this._plantingId, false, true);
			});
	}

	public delete(): void {
		if (this.isSaving) {
			return;
		}

		this.isSaving = true;

		this._soilSampleEventService.delete(this.eventId)
			.then(() => {
				this.isSaving = false;

				this._checkEventsAffected();
				this.sharedUpdateService.deleteComplete();
				this._updateService.setEventDialogClosed();
				this._plantingRecalculatorService.recalculate(this._plantingId, false, true);
			});
	}

	public update(): void {
		if (this.form.invalid) {
			return;
		}

		this._truncateNutrientValues();

		if (this.isSaving) {
			return;
		}

		this.isSaving = true;

		this._soilSampleEventService.update(this.eventId,
			this._getModelFromForm())
			.then(() => {
				this.isSaving = false;

				this._plantingRecalculatorService.recalculate(this._plantingId, false, true);
				this._checkEventsAffected();
			});
	}

	private _checkEventsAffected(): void {

		let planting: IPlanting;
		let events: IEventGroup[];
		let eventDate: Date;

		planting = this._ranchService.plantings.getPlantingById(this._plantingId);
		events = planting.Events;

		eventDate = this.f.EventDate.value;

		if (eventDate === null) {
			this.close();
			return;
		}

		eventDate.setHours(0, 0, 0, 0);

		this._allEvents = events.sort(function (a, b) { return new Date(a.EventDate).getTime() - new Date(b.EventDate).getTime(); })

		this.affectedEvents = new Array();

		for (let event of this._allEvents) {

			if (event.Fertilization != null && event.Fertilization.length > 0
				&& event.Fertilization[0].SoilSampleEventDate
				&& event.Fertilization[0].SoilSampleEventDate.getTime() === eventDate.getTime()
			) {
				this.affectedEvents.push(event);
			}
		}

		if (this.affectedEvents.length > 0) {
			this.close();

			this.dialog.open(SoilSampleWarningDialog, {
				disableClose: true,
				data: {
					affectedEvents: this.affectedEvents
				}
			});
		} else {
			this.close()
		}
	}

	private _createNutrientFormArray(nutrients: IFormNutrient[]): void {

		for (let nutrient of nutrients) {
			let g: FormGroup;

			g = this._fb.group({
				Id: [nutrient.Id],
				value: [nutrient.value, [Validators.min(0), Validators.max(99999999)]],
				Name: [nutrient.Name],
				MeasurementUnitName: [nutrient.MeasurementUnit.Name]
			});

			(this.form.get('OtherNutrients') as FormArrayTyped<OtherNutrientsForm>).push(g);
		}
	}

	private _getCreateModel(): void {
		this._soilSampleEventService.getCreateModel(this._plantingId)
			.then((res) => {
				if (!res) {
					this._notificationService.generateNotifcation({
						type: eNotificationTypes.ERROR,
						message: `Server didn't respond. Please try again.`
					});

					return;
				}

				if (this._dateDefault) {
					res.EventDate = this._dateDefault;
				} else {
					res.EventDate = null;
				}

				res.SampleDepth = null;
				this._loadFormFromModel(res);
				this.isLoaded = true;
				this._configureDeleteConfirmationText();
				// Modal.resize();
			});
	}

	private _getEditModel(id: number): Promise<boolean> {
		return this._soilSampleEventService.getEditModel(id)
		.then(res => {
			if (!res) {
				return;
			}

			this._loadFormFromModel(res);
			this.form.markAsDirty();
			this._originalEventDate = res.EventDate;
			this.isLoaded = true;
			this._configureDeleteConfirmationText();
			// Modal.resize();
			return true;
		});
	}

	private _getModelFromForm(): SoilSampleEvent {
		let event: SoilSampleEvent;
		event = new SoilSampleEvent(this.form.get('EventDate').value);
		event.CropStageId = this.form.get('CropStageId').value;
		event.SoilMoistureId = this.form.get('SoilMoistureId').value;
		event.SampleDepth = this.form.get('SampleDepth').value;
		event.SampleTypeId = this.form.get('SampleTypeId').value;

		event.Nitrogen = {
			Id: 1,
			NutrientId: 1,
			Value: this.form.get('Nitrogen').value
		};

		// update model
		for (let control of (this.form.get('OtherNutrients') as FormArrayTyped<OtherNutrientsForm>).controls) {
			let nutrient: IFormNutrient;
			let fg: FormGroupTyped<OtherNutrientsForm>;

			fg = control as FormGroupTyped<OtherNutrientsForm>;

			nutrient = this.otherNutrients.find(x => x.Id ===
				fg.get('Id').value);

			nutrient.value = fg.get('value').value;
		}

		event.OtherNutrients = this.otherNutrients;

		return event;
	}

	private _initializeForm(model: SoilSampleEvent): void {
		let isNDependent: boolean;

		if (!model) {
			throw new Error('soil sample event is empty');
		}

		isNDependent = this._ranchService.getIsNDependent(this._plantingId);

		if (model.SampleTypeId === 0) {
			model.SampleTypeId = null;
		}

		if (!isNDependent) {
			model.SampleTypeId = eSampleTypeIds.LAB_TEST;
		}

		this.form = this._fb.group({
			Id: [ model.Id],
			EventDate: [model.EventDate ? model.EventDate : null,
				[Validators.required,
				FormValidation.maxDateValidator(DateUtility.DotNetToDate(model.EndDate))]],
			SampleTypeId: [model.SampleTypeId],
			SampleDepth: [model.SampleDepth, [Validators.required, Validators.min(1), Validators.max(2)]],
			CropStageId: [model.CropStageId], // optional
			SoilMoistureId: [model.SoilMoistureId ? model.SoilMoistureId : eSoilMoistures.MOIST],
				// we need a value if quick nittrate strip
			Nitrogen: [model.Nitrogen.Value],
			OtherNutrients: this._fb.array([]),
			isFuture: [null],
			isNDependent: [isNDependent],
		}, { validator: this._validateGroup }) as FormGroupTyped<SoilSampleDialogForm>;

		this.form.get('isFuture').setValue(DateUtility.isFuture(this.form.get('EventDate').value));
		this.form.get('SampleTypeId').setValidators(this._sampleTypeValidator(isNDependent));
		this.f.Nitrogen.setValidators([
			// required if event date is today or past
			Validators.min(0),
			Validators.max(99999999),
		]);

		this._createNutrientFormArray(model.OtherNutrients.sort((a, b) => { return a.Name.localeCompare(b.Name); }));

		this.f.EventDate.valueChanges.pipe(takeUntil(this._subscriptions$)).subscribe(() => {
			this.form.get('isFuture').setValue(DateUtility.isFuture(this.form.get('EventDate').value));
			this._configureDeleteConfirmationText(); // reconfigure delete confirmation text
		});
	}

	private _loadFormFromModel(model: SoilSampleEvent): void {
		if (!model) {
			return;
		}

		this._initializeForm(model);

		this.otherNutrients = model.OtherNutrients;
		this.cropStages = model.CropStages;
		this.sampleTypes = model.SampleTypes;
		this._harvestDate = DateUtility.DotNetToDate(model.EndDate);
		this.lastUpdatedUser = model.LastUpdatedUser;
		this.moistureTypes = model.MoistureTypes;

		this.maxDate = moment(this._harvestDate).subtract(1, 'days').toDate();
	}

	private _sampleTypeValidator(isNDependent: boolean): ValidatorFn {
		return (control: AbstractControl): {[key: string]: any} | null => {
			let valid: boolean;
			let error: { requiredIfNDependent: { value: number }};

			error = {
				requiredIfNDependent: { value: control.value }
			};

			if (isNDependent) {
				valid = control.value > 0;
			} else {
				valid = true;
			}

			return valid ? null : error;
		};
	}

	// only save 2 decimal places
	private _truncateNutrientValues(): void {
		this.form.get('Nitrogen').setValue(ValidateService.truncateDecimals(this.form.get('Nitrogen').value, 2));

		for (let nutrient of (this.form.get('OtherNutrients') as FormArray).controls) {
			let group: FormGroupTyped<OtherNutrientsForm>;

			group = nutrient as FormGroupTyped<OtherNutrientsForm>;
			group.get('value').setValue(ValidateService.truncateDecimals(group.get('value').value, 2));
		}
	}

	private _validateGroup(g: FormGroupTyped<SoilSampleDialogForm>): void {
		let isFuture: AbstractControlTyped<boolean> = g.get('isFuture');
		let nitrogen: AbstractControlTyped<number> = g.get('Nitrogen');
		let isNDependent: AbstractControlTyped<boolean> = g.get('isNDependent');
		let error: { invalidNitrogen: boolean } = { invalidNitrogen: true };
		const errorString = 'invalidNitrogen';

		if (isFuture.value) {
			FormValidation.removeErrorFromContrl(nitrogen, errorString);
		} else if (nitrogen.value !== null) {
			FormValidation.removeErrorFromContrl(nitrogen, errorString);
		} else if (isNDependent.value) {
			nitrogen.setErrors(error);
		}
	}
}
