import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { ILocalizationDictionary, ITranslation, ILocalizatinDictionaryJSON,
	LanguageCode, ITranslationService, eLanguageCodes } from './interfaces';

import { HttpService, HttpServiceGetOptions, HttpServicePostOptions } from '../../services/http.service';
import { SuccessResponse, CMError } from '../../interfaces/interfaces';
import { Translation } from './translation.class';
import { environment } from '../../../environments/environment';

@Injectable()
export class TranslateService implements ITranslationService {
	private _languages: LanguageCode[] = new Array();
	private _translations: ILocalizatinDictionaryJSON[] = new Array();
	public currentLanguageId: number;
	public currentLanguageName: string;
	private _languageHashMap: Record<number, Record<string, string>>;

	private _currentLanguage: Subject<number> = new Subject<number>();
	public currentLanguage$ = this._currentLanguage.asObservable();

	private _urls = {
		localization: {
			create: '/v2/localized-dictionary.json',
			list: '/v2/localized-dictionary.json',
			update: '/v2/localized-dictionary.json',
			delete: (id: number) => `/v2/localized-dictionary/${id}.json`
		},
		languageCodes: {
			get: '/v2/language-codes.json'
		}
	}

	constructor(
		private httpService: HttpService
	) { }

	public setCurrentLanguage(language: number): void {
		this.currentLanguageId = language;
		this._currentLanguage.next(language);
	}

	public loadLanguages(): Promise<LanguageCode[]> {

		let options: HttpServiceGetOptions;

		options = {
			url: this._urls.languageCodes.get,
			useLocalStorage: true,
			callback: (data: LanguageCode[]): LanguageCode[] => {
				this._languages = data;

				return data;
			},
			isWebAPI: true
		};

		return this.httpService.get(options);
	}

	public loadTranslations(override = false): Promise<ITranslation[]> {

		let options: HttpServiceGetOptions;
		let useLocalStorage: boolean;

		useLocalStorage = override ? false : true;

		options = {
			url: this._urls.localization.list,
			useLocalStorage: useLocalStorage,
			callback: (res: ILocalizatinDictionaryJSON[]): ITranslation[] => {
				let result: {
					list: ITranslation[],
					map: Record<number, Record<string, string>>;
				};

				this._translations = res;
				result = Translation.processTranslationsList(this._translations);
				this._languageHashMap = result.map;

				return result.list;
			},
			isWebAPI: true
		};

		return this.httpService.get(options);
	}

	/**
	 * Notes: Tried conditionally displaying markers using cookies, but checking cookies every run made things
	 * very slow
	 *
	 * @param keyword
	 */
	public translate(keyword: string): string {
		let result: string;

		if (!this.currentLanguageId) {
			// can't translate
			return keyword;
		}

		if (!this._languageHashMap || !this._languageHashMap[this.currentLanguageId]) {
			return keyword;
		}

		result = this._languageHashMap[this.currentLanguageId][keyword];

		if (result === undefined) {
			if (environment.production === true) {
				result = keyword;
			} else {
				result = '{' + keyword + '}*'; // mark entries that are missing entries in localization dictionary on the grant server
					// only. Hide this on production to reduce visual noise
			}
		} else if (result === '') {
			result = keyword;
		}

		return result;
	}

	/**
	 * Method called when creating a new system label
	 * @param keyword
	 * @param translations
	 * @returns number of errors, if any
	 */
	public addTranslation(keyword: string, translations: { languageCode: number, word: string }[]): Promise<string> {
		let promises: Promise<any>[] = new Array();
		let errorMessage: string;

		errorMessage = '';

		for (let entry of translations) {
			let body = {
				LanguageCode: entry.languageCode,
				Keyword: keyword,
				Word: entry.word
			}

			let options: HttpServicePostOptions;

			options = {
				url: this._urls.localization.create,
				isWebAPI: true,
				shouldBypassServerErrorAlert: true,
				callback: (response: SuccessResponse | CMError) => {

					let error: CMError;

					error = response as CMError;

					if (error && error.code) {
						errorMessage = error.message;
					}
				},
				body: body
			}

			promises.push(this.httpService.post(options));
		}

		return Promise.all(promises)
			.then(() => {
				sessionStorage.removeItem(this._urls.localization.list);
				return errorMessage;
			});
	}

	public saveTranslation(translations: ILocalizationDictionary[]): Promise<void> {
		let promises: Promise<any>[] = new Array(); // any is inavoidable here

		for (let entry of translations) {
			let obj = {
				Id: entry.id,
				LanguageCode: entry.languageCode,
				Keyword: entry.keyword,
				Word: entry.word
			}

			promises.push(this.httpService.put({
				url: this._urls.localization.update,
				body: obj,
				isWebAPI: true
			}));
		}

		return Promise.all(promises)
			.then(() => {
				sessionStorage.removeItem(this._urls.localization.list);
				return;
			} );
	}

	public getLanguages(): LanguageCode[] {
		return this._languages;
	}

	public deleteTranslation(ids: number[]): Promise<void> {
		let promises: Promise<any>[] = new Array();

		for (let id of ids) {
			promises.push(this.httpService.delete({
				url: this._urls.localization.delete(id),
				isWebAPI: true,
			}));
		}

		return Promise.all(promises)
			.then(() => {
				sessionStorage.removeItem(this._urls.localization.list);
				return;
			});
	}

	public getStandardLanguageCode(): string {
		switch (this.currentLanguageId) {
			case eLanguageCodes.ENGLISH:
				return 'en-us';
			case eLanguageCodes.SPANISH:
				return'es';
		}
	}
}
