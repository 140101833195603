import { Injectable } from '@angular/core';
import { of } from 'rxjs';

import { HttpService, HttpServicePostOptions } from '../../services/http.service';

import { UpdateService } from '../../services/update.service'
import { DateUtility } from '../../classes/dateUtility';
import { EventUtility } from '../../models/event/eventUtility';
import { EventGroup } from '../../models/event/event';
import { PlantingAdvancedEdit } from './plantingAdvancedEdit';

import { CMError, SuccessResponse } from '../../interfaces/interfaces';

import { IPlantingAdvancedEdit, IPlantingCreateViewModel, ILotPlantingWithEventsJSON, IPlanting, IPlantingJSON,
	RainfallDataViewModelJSON,
	RainfallDataViewModel,
	RainfallDataByMonth,
	IPlantingAdvancedViewModel,
	IPlantingService,
	CustomSaturationSettings,
	RecalculationType} from './interfaces';
import { IIrrigationMethod } from '../../models/irrigation-method/interfaces';

import { IRawEvent } from '../../models/event/interfaces';

import { EventsType } from '../../interfaces/constants';
import { PersistentDatabase } from '../../services/persistent-database';
import { Planting } from './planting';
import { RainWeatherEventPostModel, WeatherEventViewModel } from '../et-chart/interface';
import { HttpParams } from '@angular/common/http';
import { IAdditionalWaterModel } from '../irrigation-event/interfaces';
import { IAppliedWaterChartDataSet } from '../applied-water-chart/interfaces';
import { IAvailableWaterJSON, IAvailableWater } from '../available-water-chart/interfaces';
import { AppliedFertilizerChartDataSet } from '../applied-fertilizer-chart/interfaces';
import { PlantingParamsInternal } from './plantingParam.interfaces';
import { PlantingSettingsFormConverter } from './PlantingSettingsFormConverter';
import { IPlantingSettingsForm } from './planting-settings-form.interfaces';
import { ISoilLayer } from '../../models/soil-type/interfaces';

@Injectable()
export class PlantingService implements IPlantingService {

	private urls = { // refactoring start
		IrrigationMethod: {
			listNContribution: (plantingId: number) => `/v2/plantings/${plantingId}/irrigation-methods/list-n-contribution.json`
		},
		LotPlanting: {
			save: `/v2/plantings.json`,
			update: (plantingId: number) => `/v0/plantings/${plantingId}.json`,
			create: (ranchGuid: string) => `/v0/ranches/${ranchGuid}/plantings.json`,
			getCreateForm: (ranchGuid: string) => `/v2/ranches/${ranchGuid}/plantings/form.json`,
			delete: (id: number) => `/v2/plantings/${id}.json`,
			advancedEdit: (id: number) => `/v2/plantings/${id}.json`,
			recalculate: (id: number) => `/v2/plantings/${id}/calculation.json`,
			isWetDateValid: (lotPlantingId: number) => `/v2/plantings/${lotPlantingId}/wet-date-status.json`,
			slideInPanel: (lotPlantingId: number) => `/v2/plantings/${lotPlantingId}/slide-in-panel.json`,
			appliedWaterChart: (lotPlantingId: number) => `/v2/plantings/${lotPlantingId}/applied-water-chart.json`,
			appliedFertilizerChart: (lotPlantingId: number) => `/v2/plantings/${lotPlantingId}/applied-fertilizer-chart.json`,
			isLocationValid: `/v2/is-location-valid.json`,
			rainfall: {
				list: (id: number) => `/v2/plantings/${id}/rainfall.json`,
				byMonth: (plantingId: number) => `/v2/plantings/${plantingId}/rainfall/monthly.json`,
				update: (plantingId: number) => `/v2/plantings/${plantingId}/rainfall.json`,
				reset: (plantingId: number) => `/v2/plantings/${plantingId}/rainfall/reset.json`
			},
			availableWater: {
				getChart: (lotPlantingId: number) => `/v2/plantings/${lotPlantingId}/available-water-chart.json`,
				refresh: (lotPlantingId: number) => `/v2/plantings/${lotPlantingId}/available-water-chart.json`,

				getCustomSaturationSettings: (lotPlantingId: number) =>
				`/v2/plantings/${lotPlantingId}/available-water-chart/custom-saturation-settings.json`,
			}
		},
		Dashboard: {
			getAllEventsUrl: (plantingId: number) => `/v2/plantings/${plantingId}/events.json`
		}
	};

	/* Other Methods */

	// event table sorting

	/**
	 * For each tab in the event table, maintains the active column being sorted
	 */
	public sortField: Map<string, string>;

	/**
	 * For each tab, maintains the sort order (ascending / descending)
	 */
	public sortDescending: Map<string, boolean>;

	public units = {
		water: 'hours',
		fertilizer: 'fertilizerUnit',
		soilSamples: 'ppmNitrate-N'
	};

	public irrigationMethods: IIrrigationMethod[];

	constructor(
		private updateService: UpdateService,
		private httpService: HttpService,
		private persistentDatabase: PersistentDatabase
	) {
		this.sortField = new Map<string, string>();
		this.sortDescending = new Map<string, boolean>();

		this.resetEventTableSorting();
	}

	public resetEventTableSorting() {
		const EVENT_DATE_STRING = 'EventDateString';

		this.sortField.set('All', EVENT_DATE_STRING);
		this.sortField.set('Water', EVENT_DATE_STRING);
		this.sortField.set('Fertilizer', EVENT_DATE_STRING);
		this.sortField.set('SoilSamples', EVENT_DATE_STRING);
		this.sortField.set('Cutting', EVENT_DATE_STRING);
		this.sortField.set('TissueSamples', EVENT_DATE_STRING);

		this.sortDescending.set('All', false);
		this.sortDescending.set('Water', false);
		this.sortDescending.set('Fertilizer', false);
		this.sortDescending.set('SoilSamples', false);
		this.sortDescending.set('Cutting', false);
		this.sortDescending.set('TissueSamples', false);
	}

	/**
	 *
	 * @param tabName
	 * @param columnName
	 * @param eventsType event table's active tab
	 */
	public sort(tabName: string, columnName: string, eventsType: EventsType): void {
		let eventTypeName: string;

		eventTypeName = EventUtility.getStringFromEventsType(eventsType);

		if (tabName === eventTypeName && columnName === this.sortField.get(tabName)) {
			this.sortDescending.set(tabName, !this.sortDescending.get(tabName));
		} else {
			this.sortDescending.set(tabName, false);
		}

		this.sortField.set(tabName, columnName);
	}

	public sortColumn(type: string): string {
		return this.sortDescending.get(type)  ? '-' + this.sortField.get(type) : this.sortField.get(type);
	}

	private get(url: string, callback: Function, params?: HttpParams): Promise<any> {
		if (params) {
			return this.httpService.get({
				url: url.toLocaleLowerCase(),
				searchParams: params,
				callback: callback,
				isWebAPI: true
			});
		} else {
			return this.httpService.get({
				url: url.toLocaleLowerCase(),
				searchParams: null,
				callback: callback,
				isWebAPI: true
			});
		}
	}

	private post(url: string, callback: Function, params: any): Promise<any> {
		return this.httpService
			.post({
				url: url.toLocaleLowerCase(),
				body: params,
				isWebAPI: true,
				callback: callback
			});
	}

	/* Planting Methods */

	public getAppliedFertilizerChart(lotPlantingId: number): Promise<AppliedFertilizerChartDataSet> {

		if (!lotPlantingId) {
			throw new Error('LotPlantingId is empty');
		}

		return this.get(this.urls.LotPlanting.appliedFertilizerChart(lotPlantingId),
		(data: AppliedFertilizerChartDataSet): AppliedFertilizerChartDataSet => {

			return data;
		})
	}

	public getAppliedWaterChart(lotPlantingId: number): Promise<IAppliedWaterChartDataSet> {

		if (!lotPlantingId) {
			throw new Error('LotPlantingId is empty');
		}

		return this.get(this.urls.LotPlanting.appliedWaterChart(lotPlantingId),
		(data: IAppliedWaterChartDataSet): IAppliedWaterChartDataSet => {

			return data;
		});
	}

	/**
	 * refresh the available water chart using a new saturation start date
	 * and initial saturation
	 * @param lotPlantingId
	 * @param startDate
	 * @param initialSaturation
	 * @returns
	 * @throws Error if startDate is empty
	 */
	public refreshAvailableWaterChart(lotPlantingId: number, startDate: Date,
		initialSaturation: number): Promise<SuccessResponse> {

		let params: HttpParams;

		if (!startDate) {
			throw new Error('startDate empty');
		}

		params = new HttpParams().set('startDate', startDate.toLocaleDateString())
			.set('initialSaturation', (initialSaturation / 100).toString());

		return this.httpService.put({
			url: this.urls.LotPlanting.availableWater.refresh(lotPlantingId),
			body: null,
			params: params,
			isWebAPI: true,

			callback: (response: SuccessResponse) => {
				return response;
			}
		});
	}

	public getCustomSaturationSettings(lotPlantingId: number): Promise<CustomSaturationSettings> {
		if (!lotPlantingId) {
			throw new Error('LotPlantingId is empty');
		}

		return this.get(this.urls.LotPlanting.availableWater.getCustomSaturationSettings(lotPlantingId),
		(response: CustomSaturationSettings): CustomSaturationSettings => {
			return response;
		})
	}

	public getAvailableWaterChart(lotPlantingId: number): Promise<IAvailableWater[]> {

		if (!lotPlantingId) {
			throw new Error('LotPlantingId is empty');
		}

		return this.get(this.urls.LotPlanting.availableWater.getChart(lotPlantingId),
		(records: IAvailableWaterJSON[]): IAvailableWater[] => {
			let result: IAvailableWater[];

			result = [];

			for (let record of records) {
				result.push({
					Id: record.Id,
					LotPlantingId: record.LotPlantingId,
					Date: DateUtility.DotNetToDate(record.Date),
					Calculated: record.Calculated,
					Potential: record.Potential,
					ETc: record.ETc,
					Kc: record.Kc,
					Rainfall: record.Rainfall,
					WaterApplied: record.WaterApplied,
					RootDepth: record.RootDepth,
					PotentialPAW: record.PotentialPAW,
					ETe: record.ETe,
					ETo: record.ETo,
					KcMax: record.KcMax,
					Ks: record.Ks,
					Dr: record.Dr
				});
			}

			return result;
		})
	}

	public getSlideInPanel(lotPlantingId: number): Promise<IPlanting> {

		if (!lotPlantingId) {
			throw new Error('LotPlantingId is empty');
		}

		return this.get(this.urls.LotPlanting.slideInPanel(lotPlantingId), (data: IPlantingJSON): IPlanting => {
			let result: IPlanting;

			result = Planting.convertPlantingJSON(data);
			return result;
		})
	}

	/**
     * TODO: Instead of returning a restructured object, rewrite C# to return the same data structure as advancedEdit (GET)
     */
	public getAddPlantingData(): Promise<IPlantingAdvancedEdit> {

		return this.get(this.urls.LotPlanting.getCreateForm(this.updateService.currentRanchId),
		(data: IPlantingCreateViewModel): IPlantingAdvancedEdit => {
			let result: PlantingAdvancedEdit;

			result = new PlantingAdvancedEdit();
			result.initializeWithCreateViewModel(data);

			return result;
		});
	}

	public isLocationValid(lat: number, lng: number, isSpatialEnabled: boolean): Promise<string | CMError> {
		let params: HttpParams;

		if (!lat || !lng) {
			let emptyError: CMError;

			emptyError = {
				code: 400,
				message: 'Coordinates are invalid'
			}

			return of(emptyError).toPromise();
		}

		params = new HttpParams()
			.set('lat', lat.toString())
			.set('lng', lng.toString())
			.set('isSpatialEnabled', isSpatialEnabled ? 'true' : 'false');

		return this.httpService.get({
			url: this.urls.LotPlanting.isLocationValid,
			searchParams: params,
			isWebAPI: true,
			shouldBypassServerErrorAlert: true,
			callback: (data: SuccessResponse | CMError): string | CMError => {
				let success: SuccessResponse;
				let error: CMError;

				error = data as CMError;
				success = data as SuccessResponse;

				if (!data) {
					return null;
				} else if (error.code) {
					return error;
				} else {
					return success.Message;
				}
			}
		});
	}

	public isWetDateValid(lotPlantingId: number, wetDate: Date): Promise<boolean> {
		let params: HttpParams;

		if (!lotPlantingId || !wetDate) {
			return of(null).toPromise();
		}

		params = new HttpParams()
			.set('wetDate', wetDate.toLocaleDateString());

		return this.get(this.urls.LotPlanting.isWetDateValid(lotPlantingId), (data: boolean): boolean => {
			return data;
		}, params);
	}

	// get a list of irrigation methods//////
	public getIrrigationMethods(plantingId: number): Promise<IIrrigationMethod[]> {

		if (!plantingId) {
			return of(null).toPromise();
		}

		if (this.irrigationMethods) {
			return of(this.irrigationMethods).toPromise();
		}

		return this.get(this.urls.IrrigationMethod.listNContribution(plantingId), (response: IIrrigationMethod[]) => {
			if (!response) {
				return null;
			} else {
				this.irrigationMethods = response;

				return response;
			}
		});
	}

	public create(planting: IPlantingSettingsForm, soilLayers: ISoilLayer[]): Promise<ILotPlantingWithEventsJSON | CMError> {
		let options: HttpServicePostOptions;
		let body: PlantingParamsInternal;

		body = PlantingSettingsFormConverter.convert(planting, soilLayers);

		options = {
			body: body,
			url: this.urls.LotPlanting.create(this.updateService.currentRanchId),
			isWebAPI: true,
			callback: this._processAddPlantingData,
			shouldBypassServerErrorAlert: true
		}

		return this.httpService.post(options);
	}

	public update(planting: IPlantingSettingsForm, soilLayers: ISoilLayer[]): Promise<ILotPlantingWithEventsJSON | CMError> {
		let options: HttpServicePostOptions;
		let body: PlantingParamsInternal;

		body = PlantingSettingsFormConverter.convert(planting, soilLayers);

		options = {
			body: body,
			url: this.urls.LotPlanting.update(planting.Id),
			isWebAPI: true,
			callback: this._processAddPlantingData,
			shouldBypassServerErrorAlert: true
		}

		return this.httpService.put(options);
	}

	private _processAddPlantingData(response: ILotPlantingWithEventsJSON | CMError): ILotPlantingWithEventsJSON | CMError {
		let error: CMError;

		if (!response) {
			return null;
		}

		error = response as CMError;

		if (error.message) {
			return error;
		}

		return response as ILotPlantingWithEventsJSON;
	}

	public getEditModel(id: number): Promise<PlantingAdvancedEdit> {
		let params: HttpParams;

		return this.get(this.urls.LotPlanting.advancedEdit(id),
			(data: IPlantingAdvancedViewModel): PlantingAdvancedEdit => {
				let result: PlantingAdvancedEdit;

				result = new PlantingAdvancedEdit();
				result.initializeWithPlantingAdvancedViewModel(data);

				return result;
			}, params
		);
	}

	public deletePlanting(id: number): Promise<SuccessResponse> {
		return this.httpService.delete({
			url: this.urls.LotPlanting.delete(id),
			isWebAPI: true,
			callback: this._processDeletePlanting
		});
	}

	private _processDeletePlanting(data: SuccessResponse): SuccessResponse {
		return data;
	}

	public recalculatePlanting(plantingId: number, irrigationOnly = false, fertilizationOnly = false): Promise<IPlanting> {

		let body: RecalculationType;

		body = {
			IrrigationOnly: irrigationOnly,
			FertilizationOnly: fertilizationOnly
		};

		return this.httpService
			.put({
				url: this.urls.LotPlanting.recalculate(plantingId),
				isWebAPI: true,
				body: body,

				callback: (response: IPlantingJSON) => {
					let result: IPlanting;

					result = Planting.convertPlantingJSON(response);
					return result;
				}
			});
	}

	/*
     * Other Methods
     */
	public getAllEvents(plantingId: number): Promise<EventGroup[]> {

		if (!plantingId) {
			throw new Error('PlantingService.getallEvents() - plantingId is null');
		}

		return this.httpService
			.get({
				url: this.urls.Dashboard.getAllEventsUrl(plantingId),
				isWebAPI: true,

				callback: (response: { Events: IRawEvent[], WetDate: string }) => {
					return Planting.processAllEvents(response, this.persistentDatabase);
				}
			});
	}

	public combineAdditionalDataWithAllEvents(events: EventGroup[],
		additionalIrrigationData: IAdditionalWaterModel[]): EventGroup[] {

		if (!additionalIrrigationData) {
			return events;
		}

		if (!events || events.length === 0) {
			return new Array();
		}

		if (!additionalIrrigationData || additionalIrrigationData.length === 0) {
			return events;
		}

		for (let event of events) {
			if (!event.Irrigation) {
				continue;
			}

			for (let additionalIrrigation of additionalIrrigationData) {
				if (event.Irrigation.Id === additionalIrrigation.Id) {
					event.Irrigation.AdditionalData = additionalIrrigation;
					break;
				}
			}
		}

		return events;
	}

	public async getRainfallData(id: number): Promise<RainfallDataViewModel> {
		let response: RainfallDataViewModel;

		response = await this.get(this.urls.LotPlanting.rainfall.list(id), this.processRainfallData);

		return response;
	}

	public processRainfallData(json: RainfallDataViewModelJSON): RainfallDataViewModel {

		let result: RainfallDataViewModel;

		if (!json) {
			return;
		}

		result = {
			AveragedDataMode: json.AveragedDataMode,
			EndDate: DateUtility.DotNetToDate(json.EndDate),
			StartDate: DateUtility.DotNetToDate(json.StartDate),
			PlantingId: json.PlantingId,
			WeatherStations: json.WeatherStations,
			RainfallModel: null,
		};

		if (json.RainfallModel && json.RainfallModel.length > 0 &&
			json.RainfallModel[0] !== null) {

			result.RainfallModel = [
				{
					EventDate: DateUtility.DotNetToDate(json.RainfallModel[0].EventDate),
					RainfallAmount: json.RainfallModel[0].RainfallAmount,
					ET: json.RainfallModel[0].ET
				}
			];
		}

		return result;
	}

	public getRainfallDataByMonth(id: number, month: number, year: number): Promise<RainfallDataByMonth[]> {
		let params: HttpParams;

		if (!id || id === null || id === undefined) {
			throw new Error('id is missing');
		}

		if (!month || month === null || month === undefined) {
			throw new Error('month is missing');
		}

		if (!year || year === null || year === undefined) {
			throw new Error('year is missing');
		}

		params = new HttpParams()
			.set('month', '' + month)
			.set('year', '' + year);

		return this.get(this.urls.LotPlanting.rainfall.byMonth(id), this.processRainfallDataByMonth, params);
	}

	private processRainfallDataByMonth(data: string[][]): RainfallDataByMonth[] {
		let processedData: RainfallDataByMonth[] = new Array();

		for (let dataItem of data) {
			let date: string;
			let rainfall: string;
			let weatherStationsRainfall: string[] = new Array();

			for (let i in dataItem) {
				if (i === '0') {
					date = dataItem[i];
				} else if (i === '1') {
					rainfall = dataItem[i];
				} else {
					weatherStationsRainfall.push(dataItem[i]);
				}
			}

			processedData.push({
				Date: date,
				RainfallAmount: Number(rainfall),
				isFuture: null,
				RainfallDate: null,
				Rainfall: rainfall,
				WeatherStationsRainfall: weatherStationsRainfall
			});
		}

		return processedData;
	}

	public updateRainfall(id: number, rainfallRecord: RainWeatherEventPostModel): Promise<IPlanting> {

		return this.httpService.put({
			url: this.urls.LotPlanting.rainfall.update(id),
			isWebAPI: true,

			callback: this.processUpdateRainfall,
			body: rainfallRecord
		});
	}

	private processUpdateRainfall(data: IPlantingJSON): IPlanting {
		let result: IPlanting;

		result = Planting.convertPlantingJSON(data);
		return result;
	}

	public async resetRainfall(id: number, month: number, year: number): Promise<IPlanting> {
		let params: {
			Month: number,
			Year: number
		};

		let response: IPlantingJSON;
		let result: IPlanting;

		params = {
			Month: month,
			Year: year
		}

		response = await this.post(this.urls.LotPlanting.rainfall.reset(id), null, params);
		result = Planting.convertPlantingJSON(response);

		return result;
	}

	public toggleUnits(type: string, value: string) {
		if (type === 'Water') {
			this.units.water = value;
		} else if (type === 'Fertilizer') {
			this.units.fertilizer = value;
		} else if (type === 'SoilSamples') {
			this.units.soilSamples = value;
		}
	}
}
