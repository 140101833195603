<button mat-menu-item
    [ngClass]="{ 'cm-main-menu__item--active': currentPage === NAVIGATION_VIEWS.RANCHES }"
    (click)="navigateToMyRanches()">
    <span class="cm-svg-icon cm-svg-icon--menu-items cm-svg-icon--thick"
        style="top: 5px; position: relative;">
        <svg-ranch-marker></svg-ranch-marker>
    </span>
    <span>{{'My Ranches' | translate }}</span>
</button>
<button mat-menu-item
    *ngIf="user && user.Roles && user.Roles[0] && user.Roles[0].Id == 1"
    [ngClass]="{ 'cm-main-menu__item--active': currentPage === NAVIGATION_VIEWS.LOCALIZATION }"
    (click)="navigateToAdministration()">
    <mat-icon style="margin-top:-3px;" fontSet="material-icons-outlined">admin_panel_settings</mat-icon>
    {{'Admin' | translate }}
</button>
<button mat-menu-item
    [ngClass]="{ 'cm-main-menu__item--active': currentPage === NAVIGATION_VIEWS.PROFILE }"
    (click)="navigateToProfile()">
    <mat-icon  style="margin-top:-3px;" fontSet="material-icons-outlined">person_outline</mat-icon>
    {{'Profile' | translate }}
</button>
<button mat-menu-item
    (click)="logout()">
    <mat-icon fontSet="material-icons-outlined">logout</mat-icon>
    {{'Log Out' | translate }}
</button>
<mat-divider></mat-divider>
<button mat-menu-item
    (click)="openDonationLink()">
    <mat-icon fontSet="material-icons-outlined">volunteer_activism</mat-icon>
    {{'Donate' | translate }}
</button>
<button mat-menu-item
    (click)="openDiscordLink()">
    <mat-icon fontSet="material-icons-outlined">group</mat-icon>
    {{'Join Our Discord' | translate }}
</button>