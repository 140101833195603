import { Component, OnInit } from '@angular/core';
import { RanchService } from '../../service';
import { UpdateService } from '../../../../services/update.service';
import { IRanchBasicInfoViewModel, RanchUpdateCIMISParams } from '../../interfaces';
import { MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from '../../../../services/notification.service';
import { eNotificationTypes } from '../../../../interfaces/constants';

@Component({
	moduleId: module.id,
	selector: 'cimis-options-modal',
	templateUrl: 'main.html',
})

export class CimisOptionsModalComponent implements OnInit {

	public cimisOptions: RanchUpdateCIMISParams;
	public SpatialETAvailable: boolean;
	public loadingData = true;

	constructor(
		private _dialogRef: MatDialogRef<CimisOptionsModalComponent>,
		private _notificationService: NotificationService,
		private _ranchService: RanchService,
		private _updateService: UpdateService) {
	}

	ngOnInit() {
		this.clear();

		this._ranchService.getRanchBasicInfo()
			.then(response => {
				this.loadingData = false;

				if (!response || response === null || response === undefined) {
					return;
				}

				this.cimisOptions = {
					UseCIMISAverageMode: response.UseCIMISAverageMode,
					UseSpatialCIMIS: response.UseSpatialCIMIS,
				}

				this.SpatialETAvailable = response.SpatialETAvailable;
			})
	}

	public close(): void {
		this._dialogRef.close();
	}

	private clear() {
		this.cimisOptions = null;
		this.loadingData = true;
	}

	public saveCimisOptions(): void {
		this._ranchService.updateCIMISOptions(this.cimisOptions)
			.then(() => {
				this._updateService.setCIMISOptionsUpdated({
					UseSpatialCIMIS: this.cimisOptions.UseSpatialCIMIS,
					UseCIMISAverageMode: this.cimisOptions.UseCIMISAverageMode
				});

				if (this.cimisOptions.UseSpatialCIMIS) {
					this._notificationService.generateNotifcation({
						type: eNotificationTypes.UPDATE,
						message: 'Spatial ET will be fetched soon from the CIMIS Server in the background. You may not see the weather data immediately.'
					});
				}
				this.close();
			});
	}
}
