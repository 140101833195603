import { Component, OnInit, OnDestroy } from '@angular/core';
import { ValidateService } from '../../services/validate.service';
import { UserService } from '../../models/user/service';
import { TranslateService } from '../localization/service';
import { ICurrentUser } from '../../models/user/interfaces';
import { CaspService } from '../casp-dialog/casp.service';
import { MatDialog } from '@angular/material/dialog';
import { CaspDisconnectConfirmationDialog } from './casp-disconnect-confirmation-dialog';
import { ProfileUpdateService } from './profile.update.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LanguageCode } from '../localization/interfaces';

@Component({
	moduleId: module.id,
	selector: 'user-information',
	templateUrl: 'user-information.html',
	styleUrls: [ 'profile.scss']
})

export class UserInformationComponent implements OnInit, OnDestroy {
	public betaCommodityTypeCount: number;
	public languages: LanguageCode[] = new Array();
	public userInfo: ICurrentUser;
	public saveProfileSuccess = false;
	public isCASPEnabled: boolean;
	public isSaving: boolean;

	private formStarted = {
		firstName: false,
		lastName: false,
	}

	private formValid = {
		firstName: true,
		lastName: true,
	}

	private _subscriptions$: Subject<boolean>;

	constructor(
		private _caspService: CaspService,
		private validateService: ValidateService,
		private userService: UserService,
		private _dialog: MatDialog,
		private _profileUpdateService: ProfileUpdateService,
		private translateService: TranslateService) { }

	ngOnInit(): void {
		this.isSaving = false;
		this.getUserInformation();
		this._subscriptions$ = new Subject();

		this._profileUpdateService.caspUpdated.pipe(takeUntil(this._subscriptions$)).subscribe(() => {
			// if casp is disconnected, this subscription triggers
			this.isCASPEnabled = false;
		});
	}

	ngOnDestroy(): void {
		if (!this._subscriptions$) {
			return;
		}

		this._subscriptions$.next(true);
		this._subscriptions$.complete();
	}

	private getUserInformation(): void {
		this.getLanguages()
		.then(response => {
			this.languages = response;
		});

		this.userService.getBETACommodityTypeCount().then((resp) => {
			this.betaCommodityTypeCount = resp;
		});

		this.userService.getCurrentUser()
		.then((res: ICurrentUser) => {
			if (res) {
				this.userInfo = res;
				this.validateFirstName(this.userInfo.FirstName);
				this.validateLastName(this.userInfo.LastName);
			}
		});

		this._caspService.isEnabled().then(response => {
			this.isCASPEnabled = response;
		});
	}

	/**
	 * Display disconnect confirmation dialog
	 */
	public disconnectFromCASP(): void {
		this._dialog.open(CaspDisconnectConfirmationDialog);
	}

	private getLanguages(): Promise<LanguageCode[]> {
		return this.translateService.loadLanguages();
	}

	/*user information validation*/

	private validateFirstName(firstName: string) {
		this.formStarted.firstName = true;
		this.formValid.firstName = this.validateService.validateNotEmptyString(firstName);
	}

	private validateLastName(lastName: string) {
		this.formStarted.lastName = true;
		this.formValid.lastName = this.validateService.validateNotEmptyString(lastName);
	}

	private userInfoInvalid(): boolean {
		if (!this.formStarted.firstName || !this.formStarted.lastName) {
			return true;
		} else if (!this.formValid.firstName || !this.formValid.lastName) {
			return true;
		} else {
			return false;
		}
	}

	private updateUserDetails(): void {

		if (!this.userInfo) {
			throw new Error('userInfo is empty');
		}

		if (this.isSaving) {
			return;
		}

		this.isSaving = true;

		this.userService.updateUserDetails(this.userInfo)
			.then(() => {
				this.isSaving = false;
				this.saveProfileSuccess = true;
				this.userService.getCurrentUser(true);
			}
		);

		this.translateService.setCurrentLanguage(this.userInfo.LanguageCodeId);
	}

	public submit(): void {
		if (this.userInfoInvalid()) {
			return;
		}
		this.updateUserDetails();
	}
}

