<div class="bk-dialog bk-dialog__event">
    <button type="button"
        *ngIf="!loadingData"
        class="bk-dialog__close"
        (click)="initiateCloseModal()">

        &times;
    </button>

    <h4 mat-dialog-title>
        <ng-container *ngIf="!eventId">
            {{'Add' | translate }}
        </ng-container>
        
        <span *ngIf="eventId && f && f.IrrigationMethodId.value !== IRRIGATION_METHODS.RAINFALL">
            {{'Edit' | translate }} {{'Watering Event' | translate }}
        </span>
        <span *ngIf="eventId && f && f.IrrigationMethodId.value === IRRIGATION_METHODS.RAINFALL">
            {{ 'Significant'}} {{'Rainfall Event' | translate }}
        </span>
    </h4>

    <div mat-dialog-content
        *ngIf="loadingData || !form"
        class="cm-spinner-container">
        
        <mat-spinner></mat-spinner>
    </div>

    <div mat-dialog-content
        *ngIf="!loadingData"
        [ngClass]="{'bk-modal__irrigation--datepicker-open' : datePickerOpen }">

        <!-- event date-->

        <form [formGroup]=form
            class="bk-form">

            <div *ngIf="f.IrrigationMethodId.value === IRRIGATION_METHODS.RAINFALL">
                <div class="cm-dialog__form-section">

                    <div class="cm-dialog__label">
                        {{ 'Event Date' | translate }}
                    </div>
                    <div class="cm-dialog__text-below-label">
                        {{ f.EventDate.value | date:'M/d/yyyy'}}
                    </div>
                </div>

                <div class="cm-dialog__form-section">
                    <div class="cm-dialog__label">
                        {{'Rainfall' | translate }}
                    </div>
                    <div class="cm-dialog__text-below-label">
                        {{CIMISPrecipitation}} {{'inches' | translate }}
                    </div>
                </div>
                <div *ngIf="irrigationRecommendationDetails"
                    class="cm-dialog__form-section">

                    <div class="cm-dialog__label">
                        {{'Previous Rainfall' | translate }} <mat-icon class="bk-info-icon" matTooltip="{{ 'Previous Rainfall Help Text' | translate }}">
                            info_outline
                        </mat-icon>
                    </div>
                    <div class="cm-dialog__text-below-label">
                        {{irrigationRecommendationDetails.TotalPrecipitation}} {{'inches' | translate }}
                    </div>
                </div>
                <div *ngIf="irrigationRecommendationDetails"
                    class="cm-dialog__form-section">

                    <div class="cm-dialog__label">
                        {{'Total Rainfall' | translate }}
                    </div>
                    <div class="cm-dialog__text-below-label--large">
                        {{irrigationRecommendationDetails.TotalPrecipitation + CIMISPrecipitation}} {{'inches' | translate }}
                    </div>
                </div>
            </div>

            <mat-form-field *ngIf="f.IrrigationMethodId.value !== IRRIGATION_METHODS.RAINFALL"
                appearance="fill"
                class='mat-form-field--one-line mat-form-field--short'>
                <mat-label>{{ 'Event Date' | translate }}</mat-label>
                <input matInput [matDatepicker]="picker"
                    formControlName="EventDate"
                    required
                    [max]="maxDate">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-error *ngIf="f.EventDate.invalid && (f.EventDate.dirty || f.EventDate.touched)">
                    <span *ngIf="f.EventDate.errors.hasDuplicates">
                        {{ "There's already another irrigation event scheduled on this date" | translate }}
                    </span>
                    <span *ngIf="!f.EventDate.errors.hasDuplicates">
                        {{'Planting Event Date Error Text' | translate }}
                    </span>
                </mat-error>
            </mat-form-field>
            <ng-container *ngIf="recommendationUpdating">
                <div class="bk-loader__container--small">
                    <div class="bk-loader--small">
                        {{'Loading' | translate }}...
                    </div>
                </div>
            </ng-container>

            <!-- irrigation method -->

            <mat-form-field  *ngIf="f.IrrigationMethodId.value !== IRRIGATION_METHODS.RAINFALL"
                appearance="fill"
                class="mat-form-field--one-line mat-form-field--short">
                <mat-label>{{'Irrigation Method' | translate }}</mat-label>
                <mat-select required
                    formControlName="IrrigationMethodId">
                    <mat-option *ngFor="let method of irrigationMethods"
                        [value]="method.Id">
                        {{method.Name | translate }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="f.IrrigationMethodId.invalid && (f.IrrigationMethodId.dirty ||
                f.IrrigationMethodId.touched)">
                    {{'Cannot be empty. Please select an option.' | translate }}
                </mat-error>
            </mat-form-field>

            <!-- Deficit UI -->

            <ng-container *ngIf="(isTree || planting.CommodityTypeId == COMMODITY_TYPES.PROCESSING_TOMATO)
                && f.EventDate.value && averageDeficit && averageDeficit.Days > 0 &&
                f.IrrigationMethodId.value !== IRRIGATION_METHODS.RAINFALL">
             
                <mat-accordion>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{ 'Deficit Irrigation' | translate }}
                            </mat-panel-title>
                            <mat-panel-description></mat-panel-description>
                        </mat-expansion-panel-header>
                        <div class="cm-irrigation-dialog__deficit-ui__text">
                            {{ 'Based on your planting settings, CropManage will recommend' | translate }}
                            <strong>{{ averageDeficit.Deficit }}%</strong> {{ 'of Crop ET
                            between' | translate }} {{ lastIrrigationEventDate | date:'shortDate' }} {{ 'and' | translate }} {{ f.EventDate.value | date:'shortDate' }}.
                        </div>
        
                        <mat-radio-group
                            name="IsCustomDeficitEnabled"
                            formControlName="IsCustomDeficitEnabled"
                            aria-label="">
        
                            <mat-radio-button
                                [value]="false"
                                name="IsCustomDeficitEnabled">
                                {{ 'Keep Settings' | translate }}
                            </mat-radio-button>
        
                            <mat-radio-button [value]="true"
                                name="IsCustomDeficitEnabled">
                                {{ 'Customize deficit for this irrigation' | translate }}
                            </mat-radio-button>
                        </mat-radio-group>
            
                        <mat-form-field appearance="fill"
                            *ngIf="f.IsCustomDeficitEnabled.value"
                            class="mat-form-field--one-line mat-form-field--short cm-irrigation-dialog__deficit-ui__input">
                            <mat-label>
                                {{ 'Custom Deficit Irrigation' | translate }}
                            </mat-label>
                            <input matInput
                                type="number"
                                step="0.01"
                                name="CustomDeficit"
                                formControlName="CustomDeficit"/>
                            <span matSuffix class="mat-form-field-suffix--padded">
                                %
                            </span>
                            <mat-hint align="start"><strong>{{ 'Enter percentage of Crop ET' | translate }}</strong> </mat-hint>
                        </mat-form-field>
                    </mat-expansion-panel>
                </mat-accordion>
                <br>
            </ng-container>

            <!-- Recommendation in progress.. -->

            <ng-container *ngIf="isFetchingRecommendation">
                <div class="bk-recommendations">
                    <div>
                        <h3>Please Wait</h3>
                        {{ (f.IrrigationMethodId.value !== IRRIGATION_METHODS.RAINFALL ? 'Calculating irrigation recommendation' 
                            : 'Fetching weather data')| translate }}...
                    </div>
                    <div class="bk-loader__container">
                        <div class="bk-loader"></div>
                    </div>
                </div>
            </ng-container>

            <!--Historical UI (for perennial plantings)-->

            <div *ngIf="isHistoricalUIVisible && !isFetchingRecommendation"
                class="bk-irrigation__historical-ui" 
                [ngClass]="{ 'bk-irrigation__historical-ui--datepicker-open' : isHistoricalIrrigationDatePickerOpen }">

                    <!-- wrapping this in ngIf here, to automatically destroy the component on hidden, so dates etc
                        can be automatically reinitiallized -->

                <historical-ui
                    [parentForm]='form'
                    [irrigationMethods]='irrigationMethods'
                    [plantingId]="planting.Id"
                    [wetDate]="planting.WetDate"
                    [historicalEventSaved$]='historicalEventSaved$'
                    (isHistoricalIrrigationDatePickerOpen)="onHistoricalCalendarToggle($event)"></historical-ui>

            </div>
            
            <!-- Recommendations Section -->

            <ng-container *ngIf="isRecommendationVisible && !isHistoricalUIVisible">
                <div *ngIf="f.IrrigationMethodId.value != IRRIGATION_METHODS.RAINFALL"
                    class="bk-recommendations">
                    <div class="bk-form-group">
                        <h4 class="bk-recommendations__title">
                            {{'Recommendation' | translate }} 
                            <mat-icon class="bk-info-icon" matTooltip="{{ 'Irrigation Recommendations Help Text' | translate }}">
                                info_outline
                            </mat-icon>
                        </h4>

                        <!-- unit toggle -->
                        <div *ngIf="!isFloodEvent"
                            class="bk-button-set bk-button-set--right bk-button-set_event--right">

                            <button class="bk-button bk-button-set__button
                                bk-unit-menu__button bk-button--gray-icon"
                                type="button"
                                [ngClass]="{'bk-button-set__button--selected': units === 'inches'}"
                                (click)="toggleUnits('inches')">
                                {{'inches' | translate }}
                            </button>

                            <button class="bk-button bk-button-set__button
                                bk-unit-menu__button bk-button--gray-icon"
                                [ngClass]="{'bk-button-set__button--selected': units === 'hours'}"
                                type="button"
                                (click)="toggleUnits('hours')">
                                {{'hours' | translate }}
                            </button>
                        </div>

                    </div>
                    
                    <ng-container *ngIf="(units === 'inches' || isFloodEvent) && irrigationRecommendationDetails">
                        <div class="bk-modal__recommendation">
                            {{recommendation.inches | number: '1.2-2'}} 
                            <span class="bk-modal__recommendation--unit">
                                {{'inches' | translate }}
                            </span>
                            <i *ngIf="warningTextRecommendation"
                                class="bk-event-recommendation-not-applied-icon fa fa-warning"
                                matTooltip="{{ warningTextRecommendation }}">
                            </i>       
                        </div>
                    </ng-container>
                    <ng-container *ngIf="units === 'hours' && !isFloodEvent && irrigationRecommendationDetails">
                        <div class="bk-modal__recommendation">
                            {{recommendation.hours | number: '1.0-1'}} <span class="bk-modal__recommendation--unit">
                                {{'hours' | translate }}
                            </span>
                            <i *ngIf="warningTextRecommendation"
                                class="bk-event-recommendation-not-applied-icon fa fa-warning"
                                matTooltip="{{ warningTextRecommendation }}">
                            </i>                          
                        </div>
                    </ng-container>
                    <ng-container *ngIf="irrigationRecommendationDetails === null">
                        <div class="bk-modal__recommendation">
                            {{ 'N/A' | translate }} 
                            <mat-icon *ngIf="isOnOrBeforeWetDate"
                                class="bk-info-icon bk-info-icon__irrigation-recommendation"
                                matTooltip="{{ 'Irrigation event date is on or before wet date' | translate }}">
                                info_outline
                            </mat-icon>
                            <i *ngIf="!isOnOrBeforeWetDate &&
                                isPerennial &&
                                (!recentIrrigationEventExists || !recentCutEventExists)"
                                class="bk-event-recommendation-not-applied-icon fa fa-warning"
                                matTooltip="{{ 'recommendation is NA or inaccurate due to missing information' | translate }}">
                            </i>
                        </div>
                    </ng-container>

                    <a *ngIf="irrigationRecommendationDetails"
                        href="javascript:void(0);" 
                        class="bk-modal__dropdown-link"
                        (click)="showRecommendationSummary()">
                        {{'Recommendation Summary' | translate }}
                        <i *ngIf="!recommendationSummaryVisible"
                            class="fa fa-angle-down bk-modal__dropdown-link-icon">
                        </i>
                        <i *ngIf="recommendationSummaryVisible"
                            class="fa fa-angle-up bk-modal__dropdown-link-icon">
                        </i>
                    </a>
                    <div *ngIf="recommendationSummaryVisible && irrigationRecommendationDetails"
                        class="cm-recommendation-summary">
                        <irrigation-recommendation-summary [summary]='irrigationRecommendationDetails'
                            [isPlantingPerennial]="isPerennial">
                        </irrigation-recommendation-summary>

                        <!-- ET Table -->

                        <table *ngIf="irrigationRecommendationDetails.ETEvents && irrigationRecommendationDetails.ETEvents.length"
                            class="bk-modal__recommendation-summary--et-table" >
                            <thead>
                                <tr>
                                    <th>{{ 'Date' | translate }}</th>
                                    <th>{{ 'ET' | translate }}</th>
                                    <th>
                                        {{ 'Source' | translate }}
                                        <info-icon matTooltip="{{'ET Table Source Details Text' | translate}}"></info-icon>
                                    </th>
                                    <th>{{ 'Last Modified' | translate }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let etEvent of irrigationRecommendationDetails.ETEvents">
                                    <td>
                                        {{ etEvent.EventDate | date:'shortDate' }}
                                    </td>
                                    <td>
                                        {{ etEvent.ET | number: '1.0-4'}}
                                    </td>
                                    <td>
                                        <div class="bk-modal__recommendation-summary--et-source">
                                            {{ etEvent.Source }}
                                        </div>
                                    </td>
                                    <td>
                                        {{ etEvent.LastUpdateDate | date:'M/d/yy, h:mm a' }}
                                    </td>
                                </tr>
                                <tr class="bk-modal__recommendation-summary--et-totals">
                                    <td>
                                        {{ 'Total' | translate }}
                                    </td>
                                    <td colspan="3">
                                        {{ irrigationRecommendationDetails.SumET | number: '1.0-4' }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div *ngIf="isWarningSectionVisible || warningTextRecommendation"
                    class="bk-modal__warning-section">
                    <div *ngIf="warningTextRecommendation">

                        {{ warningTextRecommendation }}
                        <br *ngIf="!recentIrrigationEventExists">
                    </div>
                    <div *ngIf="!recentIrrigationEventExists"
                        class="bk-modal__warning-section__subsection">
                        <h3>
                            {{ 'Missing Past Irrigation Information' | translate}}
                        </h3>

                        {{ irrigationEvent.getWarningTextForIrrigation(f.EventDate.value) }}

                        <a href="javascript:void(0);"
                            class="bk-historical-ui__link" 
                            (click)="showHistoricalUI()">
                            {{'Add past irrigation information' | translate}}
                            <i class="fa fa-arrow-circle-right fa-fw"></i>
                        </a>
                    </div>

                    <div *ngIf="!recentCutEventExists && planting.CommodityTypeId === COMMODITY_TYPES.ALFALFA"
                        class="bk-modal__warning-section__subsection">
                        <h3>
                            {{ 'Missing Recent Cut Event' | translate}}
                        </h3>

                        {{ irrigationEvent.getWarningTextForCutting(f.EventDate.value) }}
                    </div>
                </div>

                <div class="bk-clearfix"></div>
                <div *ngIf="isRecommendationVisible && f.IrrigationMethodId.value != 2">
                    <ng-container *ngIf="units === 'inches' || isFloodEvent">
                        <mat-form-field appearance="fill"
                            class="mat-form-field--two-wide__left">
                            <mat-label>{{'Manager Amount' | translate }}</mat-label>
                            <input matInput
                                type="number"
                                step="0.01"
                                name="ManagerAmountRecommendation"
                                formControlName="ManagerAmountRecommendation"
                                (blur)="setManagerRec('ManagerAmountRecommendation', f.ManagerAmountRecommendation.value)"/>
                            <span matSuffix class="mat-form-field-suffix--padded">
                                {{'inches' | translate }}
                            </span>
                            <mat-hint align="start"><strong>{{ 'Enter the amount recommended by a manager' | translate }}</strong> </mat-hint>
                            <mat-error *ngIf="f.ManagerAmountRecommendation.invalid &&
                                (f.ManagerAmountRecommendation.dirty || f.ManagerAmountRecommendation.touched)">
                                {{'Not a valid number' | translate }}
                            </mat-error>
                        </mat-form-field>    
                    </ng-container>
                    <ng-container *ngIf="units === 'hours'">
                        <mat-form-field appearance="fill"
                            class="mat-form-field--two-wide__left">
                        <mat-label>{{'Manager Amount' | translate }}</mat-label>
                            <input matInput
                                type="number"
                                step="0.01"
                                name="ManagerAmountRecommendationHours"
                                formControlName="ManagerAmountRecommendationHours"
                                (blur)="setManagerRec('ManagerHours',f.ManagerAmountRecommendationHours.value)"/>
                            <span matSuffix class="mat-form-field-suffix--padded">
                                {{'hours' | translate }}
                            </span>
                            <mat-hint align="start"><strong>{{ 'Enter the amount recommended by a manager' | translate }}</strong> </mat-hint>
                            <mat-error *ngIf="f.ManagerAmountRecommendationHours.invalid
                                && (f.ManagerAmountRecommendationHours.dirty
                                || f.ManagerAmountRecommendationHours.touched)">
                                {{'Not a valid number' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </ng-container>
                    <ng-container *ngIf="units === 'inches'  || isFloodEvent">
                        <mat-form-field appearance="fill" class="mat-form-field--two-wide__right">
                            <mat-label>{{'Water Applied' | translate }}</mat-label>
                            <input matInput
                                type="number"
                                step="0.01"
                                name="WaterApplied"
                                formControlName="WaterApplied"
                                (blur)="setApplied('WaterApplied', f.WaterApplied.value)"/>
                            <span matSuffix class="mat-form-field-suffix--padded">
                                {{'inches' | translate }}
                            </span>
                            <span matSuffix *ngIf="f.WaterAppliedHours.value === null && isFuture === false && eventId">
                                <i class="fa fa-warning cm-error"
                                    matTooltip="Please enter applied water amount for more accurate recommendations">
                                </i>
                            </span>
                            <mat-hint align="start"><strong>{{ 'Enter the amount that was actually applied' | translate }}</strong> </mat-hint>
                            <mat-error *ngIf="f.WaterApplied.invalid && (f.WaterApplied.dirty
                                || f.WaterApplied.touched)">
                                {{'Not a valid number' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </ng-container>
                    <ng-container *ngIf="units === 'hours'">
                        <mat-form-field appearance="fill"
                            class="mat-form-field--two-wide__right">
                            <mat-label>{{'Water Applied' | translate }}</mat-label>
                            <input matInput
                                type="number"
                                step="0.01"
                                name="WaterAppliedHours"
                                formControlName="WaterAppliedHours"
                                (blur)="setApplied('AppliedHours', f.WaterAppliedHours.value)"/>
                            <span matSuffix class="mat-form-field-suffix--padded">
                                {{'hours' | translate }}
                            </span>
                            <span matSuffix *ngIf="f.WaterAppliedHours.value === null && isFuture === false && eventId">
                                <i class="fa fa-warning cm-error"
                                    matTooltip="Please enter applied water amount for more accurate recommendations">
                                </i>
                            </span>
                            <mat-hint align="start"><strong>{{ 'Enter the amount that was actually applied' | translate }}</strong> </mat-hint>
                            <mat-error *ngIf="f.WaterAppliedHours.invalid && (f.WaterAppliedHours.dirty
                                || f.WaterAppliedHours.touched)">
                                {{'Not a valid number' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </ng-container>
                    <div *ngIf="flowmeter.hasData"
                        class="bk-clearfix bk-event-flowmeter-data">
                        <div>
                            {{'Flow Meter Information:' | translate}}
                        </div>
                        <div class="bk-event-flowmeter-data--equation">
                            {{flowmeter.waterApplied}} {{'in' | translate }} = {{flowmeter.gallons}} 
                            {{'gals' | translate }} {{ 'over' | translate }} {{flowmeter.calculatedArea}} 
                            {{ 'acres' | translate }}
                        </div>
                    </div>
                </div>

                <span *ngIf='f.Id.value > 0'
                    class='bk-modal__last-updated-meta bk-clearfix'>
                    {{ 'Last updated by' | translate }} {{lastUpdatedString}}
                </span>
            </ng-container>
        </form>
    </div>
    
    <div mat-dialog-actions
        align="end">
        <ng-container *ngIf="!isHistoricalUIVisible">
            <button *ngIf="eventId && f && f.IrrigationMethodId.value !== IRRIGATION_METHODS.RAINFALL"
                [disabled]="isFetchingRecommendation"
                class="bk-button__delete"
                (click)="showDelete($event)">
                {{'Delete' | translate }}
            </button>

            <button mat-button
                *ngIf="f && f.IrrigationMethodId.value !== IRRIGATION_METHODS.RAINFALL"
                [disabled]="isFetchingRecommendation"
                class="bk-button bk-button--medium bk-button--text-only bk-dialog__button--secondary"
                (click)="initiateCloseModal()">
                {{ 'Cancel' | translate }}
            </button>

            <button mat-button
            *ngIf="!eventId && !isRecommendationVisible"
                class="bk-button bk-button--medium bk-dialog__button--primary"
                (click)="calculateRecommendation()"
                [disabled]="isFetchingRecommendation || (form && !form.valid)">
                {{'Calculate Recommendation' | translate }}
            </button>

            <button  mat-button
                *ngIf="!eventId && isRecommendationVisible"
                [disabled]="isFetchingRecommendation || (form && !form.valid)"
                class="bk-button bk-button--medium bk-dialog__button--primary"
                (click)="create()"> <!-- we're actually updating an existing data here -->
                {{(isSaving ? 'Creating' : 'Create') | translate }}
                <img class='bk-button__loader' *ngIf='isSaving' src='/assets/images/loader-20px.gif'/>
            </button>
            <button mat-button
                *ngIf="eventId && f && f.IrrigationMethodId.value !== IRRIGATION_METHODS.RAINFALL"
                [disabled]="isFetchingRecommendation || (form && !form.valid)"
                class="bk-button bk-button--medium bk-dialog__button--primary"
                (click)="update()">

                {{ (isSaving ? 'Saving' : 'Save') | translate }}
                <img class='bk-button__loader' *ngIf='isSaving' src='/assets/images/loader-20px.gif'/>
            </button>
            <button mat-button
                *ngIf="f && f.IrrigationMethodId.value === IRRIGATION_METHODS.RAINFALL"
                class="bk-button bk-button--medium bk-dialog__button--primary"
                (click)="initiateCloseModal()">
                {{ 'Close' | translate }}
            </button>
        </ng-container>

        <ng-container *ngIf="isHistoricalUIVisible">
            <button [disabled]="isFetchingRecommendation"
                class="bk-button bk-button--medium bk-button--text-only"
                (click)="cancelHistoricalIrrigation()">
                {{'Cancel' | translate }}
            </button>
            <button [disabled]="isFetchingRecommendation"
                class="bk-button bk-button--medium"
                (click)="saveHistoricalIrrigation()">
                {{'Next' | translate }}
            </button>
        </ng-container>
    </div>
</div>
