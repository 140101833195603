import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { IMyDpOptions } from 'mydatepicker';
import { MatSnackBar } from '@angular/material/snack-bar';

import { CanopyService } from './canopy.service';
import { ValidateService } from '../../services/validate.service';
import { Canopy } from './canopy';

import { ICanopyTableData, INewCanopyData, ICanopyTableRow } from './canopyCurve.interface';
import { animate, animateChild, query, state, style, transition, trigger } from '@angular/animations';
import { ICanopyData, ISimsGraphDataRecord } from './interfaces';

/**
 * Refactoring Log
 * - (8/16/2019) checked access modifiers of class parameters
 * -- validated access modifiers of class methods and sorted them
 */
@Component({
	moduleId: module.id,
	selector: 'canopy-table',
	templateUrl: 'canopy-table.html',
	styleUrls: [ 'canopy-table.scss'],
	animations: [
		trigger('rowChildrenAnim', [
			state('open', style({
				display: 'block'
			})),
			state('closed', style({
				display: 'none',
			})),
			transition('open => closed', [ animate('0.2s') ]),
			transition('closed => open', [ animate('0.2s') ]),
		]),
		trigger('openClose', [
			state('open', style({
				height: '56px',
			})),
			state('closed', style({
				height: '0px',
			})),
			transition('open => closed', [
				query('@*', animateChild()),
				animate('0.2s 200ms'),
			]),
			transition('closed => open', [
				query('@*', animateChild()),
				animate('0.2s 200ms')
			]),
		])
	]
})

export class CanopyTableComponent implements OnInit {

	@ViewChild('addEntryForm', { static: true }) private _addEntryForm: ElementRef;
	@ViewChild('removeEntryForm', { static: true }) private _removeEntryForm: ElementRef;

	@Input()
	public canopy: Canopy;

	@Input()
	public plantingId: number;

	@Output()
	public onDataUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();

	public addingEntry: boolean;
	public datePickerOptions: IMyDpOptions;
	public entryToRemove: ICanopyTableRow;
	public formValid: Record<string, boolean>;
	public newEntry: INewCanopyData;
	public newEntryDate: { jsdate: Date };
	public removingEntry: boolean;
	public tableData: ICanopyTableData;
	public isOpen = false;

	private _formStarted: Record<string, boolean>;

	constructor(
		private _canopyService: CanopyService,
		private _snackBar: MatSnackBar) { }

	ngOnInit(): void {
		this.newEntry = {
			PlantingId: this.plantingId,
			Date: new Date(),
			CanopyCover: null
		};

		this.entryToRemove = {
			CanopyCoverEventId: null,
			Date: null,
			CropManageCanopy: null,
			MeasuredCanopy: null,
			Source: null
		};

		this.datePickerOptions = {
			showClearDateBtn: false
		};

		this.formValid = {
			canopyCover: true
		};

		this._formStarted = {
			canopyCover: false
		};

		this.tableData = {
			Data: [],
			RootMeanSquared: 0
		};

		this.newEntryDate = {
			jsdate: new Date()
		};

		this.removingEntry = false;
		this.addingEntry = false;

		this._clearNewEntryForm();
	}

	public cancelAddingEntry(): void {
		this._hideAddEntryForm();
		this._clearNewEntryForm();
	}

	public cancelRemovingEntry(): void {
		this.isOpen = false;

		this.entryToRemove = {
			CanopyCoverEventId: null,
			Date: null,
			CropManageCanopy: null,
			MeasuredCanopy: null,
			Source: null
		};
	}

	public onDateChanged(e: { jsdate: Date }): void {
		if (!e || !e.jsdate) {
			return;
		}

		this.newEntry.Date = e.jsdate;
	}

	public isNewEntryInvalid(): boolean {

		for (let key in this._formStarted) {
			if (!this._formStarted[key]) {
				return true;
			}
		}

		for (let key in this.formValid) {
			if (!this.formValid[key]) {
				return true;
			}
		}

		return false;
	}

	public reloadTableCanopyData(records: ISimsGraphDataRecord[]): void {
		for (let row of this.tableData.Data) {
			let match: ISimsGraphDataRecord;

			match = records.find(x => x.Date.getTime() === row.Date.getTime());

			if (match) {
				row.CropManageCanopy = match.Percentage;
			}
		}
	}

	/**
	 * @param maxCanopyFraction
	 * @param maxFraction
	 * @param canopyA
	 * @param canopyB
	 * @param canopyE
	 * @param canopyF
	 * @public Referenced by canopy dialog component so must be public
	 */
	public loadTableData(): void {
		let canopy: ICanopyData;

		if (!this.canopy || !this.canopy.form) {
			return;
		}

		canopy = this.canopy.form.value as ICanopyData;

		this._canopyService.GetTable(this.plantingId, canopy.CanopyGMax,
			canopy.MaxFraction, canopy.CanopyA, canopy.CanopyB, canopy.CanopyE,
			canopy.CanopyF, canopy.CanopyMin)
			.then(data => {
				if (!data) {
					return
				}

				this.tableData = data
			});
	}

	public openRemoveEntrySection(canopyData: ICanopyTableRow): void {
		this.isOpen = !this.isOpen;

		if (this.addingEntry) {
			return;
		}

		this.entryToRemove = canopyData;
	}

	public remove(): void {
		this._canopyService.deleteTableEntry(this.entryToRemove.CanopyCoverEventId)
			.then(() => {
				this._snackBar.open('Data Removed Successfully', 'Close', {
					duration: 2000
				});

				this.loadTableData();
				this.onDataUpdated.emit(true);
				this.cancelRemovingEntry();
			});
	}

	public save(): void {
		this._canopyService.createTableEntry(this.plantingId, this.newEntry)
			.then(() => {
				this._snackBar.open('Data Saved Successfully', 'Close', {
					duration: 2000
				});

				this.loadTableData();

				this.onDataUpdated.emit(true);
				this.cancelAddingEntry();
			})
	}

	public showAddEntryForm(): void {
		this.addingEntry = true;
		$(this._addEntryForm.nativeElement).slideToggle('medium');
	}

	public validateNewEntry(key: string): void {
		this._formStarted[key] = true;

		switch (key) {
			case 'canopyCover':
				this.formValid.canopyCover = ValidateService.isValidNumber(this.newEntry.CanopyCover) &&
					this.newEntry.CanopyCover >= 0 && this.newEntry.CanopyCover <= 100;
				break;
		}
	}

	private _clearNewEntryForm(): void {
		this.newEntry = {
			PlantingId: this.plantingId,
			Date: new Date(),
			CanopyCover: null
		}

		this.newEntryDate = { jsdate: new Date() };

		Object.keys(this._formStarted).forEach(i => {
			this._formStarted[i] = false;
		});

		Object.keys(this.formValid).forEach(i => {
			this.formValid[i] = true;
		});
	}

	private _hideAddEntryForm(): void {
		$(this._addEntryForm.nativeElement).slideUp('medium');
		this.addingEntry = false;
	}
}
