import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import * as _ from 'underscore';

import { NotificationService } from '../../../../services/notification.service';
import { FertilizerModelService } from './service';
import { eFertilizerFormulationTypes } from '../../../../interfaces/constants';
import { FertilizerBaseDialog } from './fertilizer.dialog.base'

@Component({
	moduleId: module.id,
	selector: 'fertilizer-view-dialog',
	templateUrl: 'fertilizer.view.dialog.html',
	styleUrls: ['fertilizer.view.dialog.scss']
})

/**
 * Usage: View an uneditable, OOTB fertilizer associated with a user's ranch
 */
export class FertilizerViewDialog extends FertilizerBaseDialog implements OnInit {

	public formulationTypes = eFertilizerFormulationTypes;

	constructor(
		protected _dialog: MatDialog,
		private _dialogRef: MatDialogRef<FertilizerViewDialog>,
		@Inject(MAT_DIALOG_DATA) protected _data: { id: number, usage: number },
		private _service: FertilizerModelService,
		private _notification: NotificationService
	) {
		super(_dialog, _data);
	}

	ngOnInit(): void {

		this._getViewModel(this.id).then(() => {
			this._calculateTotalPercentage();
		});
	}

	public close(): void {
		this._dialogRef.close();
	}

	private _getViewModel(fertilizerId: number): Promise<void> {
		return this._service.view(fertilizerId)
			.then(data => {
				if (!data) {
					return;
				}

				this.fertilizer = data;
			})
			.catch(() => {
				//
			})
	}
}
