import { Component, ViewChild, OnInit } from '@angular/core';
import { UpdateService } from '../../../../services/update.service';
import { AgmMap, LatLngBounds, LatLng } from '@agm/core';


import { MapControlDirective } from '../../../../directives/map-control.directive';
import { IMarkerCollection, IWeatherStationCurrentModel, IMarker } from '../../interfaces';
import { WeatherStationsService } from '../../../../models/weather-station-stats/service';
import { IMapIconSize, IMapIcon } from './interfaces';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SharedKeepDiscardDialog } from '../../../shared/dialogs/keep-discard-dialog';
import { SharedUpdateService } from '../../../shared/dialogs/update.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { IWeatherStationViewModel } from '../../../weather-stations/interfaces';
import { DIALOG_HIDE_CLASS } from '../../../shared/constants';

@Component({
	moduleId: module.id,
	selector: 'ranch-weather-station-modal',
	templateUrl: 'main.html',
	styleUrls: ['main.scss']
})

/**
 * Refactoring notes:
 *
 * - cleaned up public/private (8/9/2019)
 */
export class RanchWeatherStationModalComponent implements OnInit {
	private _icons: {
		selected: IMapIcon,
		unselected: IMapIcon
	};

	private readonly _iconSizes: {
		regular: IMapIconSize,
		small: IMapIconSize
	}

	@ViewChild(AgmMap, { static: false }) private _map: AgmMap;
	@ViewChild(MapControlDirective, { static: false }) private _mapControl: MapControlDirective;
	private readonly _mininumZoomLevel: number = 7;
	private _originalStations: IMarker[] = new Array();
	private _userMadeChanges: boolean;
	private _zoom = 10;

	public isSaving: boolean;
	public loadingData = true;
	public ranchLocation: IMarker = null;
	public showWeatherStationsList = false;
	public weatherStations: IMarker[] = new Array();
	public zoomOutWarning = false;
	private _subscriptions$: Subject<boolean>;


	constructor(
		private _dialogRef: MatDialogRef<RanchWeatherStationModalComponent>,
		private _dialog: MatDialog,
		private _updateService: UpdateService,
		private _sharedUpdateService: SharedUpdateService,
		private _weatherStationService: WeatherStationsService) {

		this._iconSizes = {
			regular: {
				height: 50,
				width: 80
			},
			small: {
				height: 10,
				width: 10
			}
		};

		this._icons = {
			selected: {
				url: '../assets/images/icons/map_marker-weather_station-selected.png',
				scaledSize: this._iconSizes.regular
			},
			unselected: {
				url: '../assets/images/icons/map_marker-weather_station.png',
				scaledSize: this._iconSizes.regular
			}
		}
	}

	public ngOnInit(): void {
		this._subscriptions$ = new Subject();
		this.isSaving = false;

		this._clear();
		this._getAddWeatherStationData();

		this._sharedUpdateService.keep$
			.pipe(takeUntil(this._subscriptions$)).subscribe(() => {

			this._show();
		});

		this._sharedUpdateService.discard$
			.pipe(takeUntil(this._subscriptions$)).subscribe(() => {

			this._dialogRef.close();
		})

		this._dialogRef.backdropClick()
			.pipe(takeUntil(this._subscriptions$)).subscribe((e: MouseEvent) => {
			this.close();
		});
	}

	ngOnDestroy(): void {
		if (!this._subscriptions$) {
			return;
		}

		this._subscriptions$.next(true);
		this._subscriptions$.complete();
	}

	private _show(): void {
		this._dialogRef.removePanelClass(DIALOG_HIDE_CLASS);
	}

	private _hide(): void {
		this._dialogRef.addPanelClass(DIALOG_HIDE_CLASS);
	}

	public addSelectedStations(stations: IMarker[]): void {
		stations = stations.filter(data => data.Selected);

		if (!stations.length) {
			this._dialogRef.close();
			return;
		}

		if (this.isSaving) {
			return;
		}

		this.isSaving = true;

		this._weatherStationService.addStationsToRanch(stations)
			.then(() => {
				this.isSaving = false;
				this._updateService.setRanchSettingsUpdated('weatherStations');
				this._dialogRef.close();
			});
	}

	public close(): void {
		if (this._userMadeChanges) {
			this._dialog.open(SharedKeepDiscardDialog, {
				width: '500px',
				disableClose: true,
			});

			this._hide();
		} else {
			this._dialogRef.close();
		}
	}

	public getStationId(stationId: string): string {
		if (this._zoom < this._mininumZoomLevel) {
			return null;
		}

		return stationId;
	}

	public getStationMarker(station: IMarker): IMapIcon {
		if (this._zoom < this._mininumZoomLevel) {
			this._icons.selected.url = '../assets/images/icons/map_marker-weather_station-selected-small.png';
			this._icons.unselected.url = '../assets/images/icons/map_marker-weather_station-small.png';
		} else {
			this._icons.selected.url = '../assets/images/icons/map_marker-weather_station-selected.png';
			this._icons.unselected.url = '../assets/images/icons/map_marker-weather_station.png';
		}

		if (station.Selected || station.isCurrent) {
			return this._icons.selected;
		} else {
			return this._icons.unselected;
		}
	}

	public removeWeatherStation(): void {
		if (this.weatherStations[0]) {
			this._weatherStationService.removeFromRanch(this.weatherStations[0].Id)
				.then(res => {
					this._updateService.setRanchSettingsUpdated('weatherStations');
					this._dialogRef.close();
				});
		}
	}

	public resetMapOnboundsChange(bounds: LatLngBounds): void {
		this._mapControl.getZoom().then(res => {
			let zoom = Number(res);

			if (zoom !== this._zoom && this.showWeatherStationsList) {
				this._zoom = zoom;
				this._scaleMarkerIconSizes(this._zoom);
			}

			this._setWeatherStations(bounds);
		});
	}

	public selectStation(selectedStation: IMarker): void {
		if (!selectedStation) {
			return;
		}

		if (selectedStation.isCurrent) {
			return;
		}

		for (let station of this.weatherStations) {
			if (station.Id === selectedStation.Id) {
				station.Selected = !station.Selected;
				this._userMadeChanges = true;
				break;
			}
		}
		for (let station of this._originalStations) {
			if (station.Id === selectedStation.Id) {
				station.Selected = !station.Selected;
				break;
			}
		}
	}

	protected preventClose(e?: MouseEvent): boolean {
		return this.loadingData;
	}

	private _clear(): void {
		this.weatherStations = new Array();
		this.ranchLocation = null;
		this._originalStations = new Array();
		this._userMadeChanges = false;
		this._zoom = 10;
		this.showWeatherStationsList = false;
		this._icons.selected.scaledSize = this._iconSizes.regular;
		this._icons.unselected.scaledSize = this._iconSizes.regular;
		this.zoomOutWarning = false;
		this.loadingData = true;
	}

	private _getAddWeatherStationData(): void {
		this._processAddWeatherStationData().then(() => {
			this.weatherStations = this._sortStationsByDistanceFromRanch(this.weatherStations);
			this._originalStations = this._sortStationsByDistanceFromRanch(this._originalStations);
			this.loadingData = false;
			this._resetMap();
		});
	}

	private _processAddWeatherStationData(): Promise<void> {
		let promises: {
			ranchStations: Promise<IMarkerCollection>,
			listAll: Promise<IWeatherStationViewModel[]>,
			currentModels: Promise<IWeatherStationCurrentModel[]>
		};

		promises = {
			ranchStations: this._weatherStationService.listRanchStations(),
			listAll: this._weatherStationService.listAll(),
			currentModels: this._weatherStationService.getRanchWeatherStations()
				// getRanchWeatherStations() data is only used to tag the big list with a ranch association... its a needless call
		};

		return Promise.all([promises.ranchStations, promises.listAll, promises.currentModels]).
			then((res: [IMarkerCollection, IWeatherStationViewModel[], IWeatherStationCurrentModel[]]) => {

			let allStations: IMarkerCollection; // contains ranch location and a list of all stations
			let associatedStations: IWeatherStationCurrentModel[]; // stations  already associated with the ranch

			allStations = this._weatherStationService.combineStationArrays(res[0], res[1]);
			associatedStations = res[2];

			this.ranchLocation = allStations.RanchLocation;

			for (let station of allStations.Stations) {
				station.isCurrent = false;

				for (let currentStation of associatedStations) {
					if (station.Id === currentStation.Id) {
						station.isCurrent = true;
					}
				}

				if (!station.isCurrent) { // ignore stations already associated with the ranch
					this.weatherStations.push(station);
					this._originalStations.push(station);
				}
			}
		});
	}

	private _recenterMap(lat: number, lng: number, zoom: number): Promise<void> {
		let promises: Promise<void>[] = new Array();
		promises.push(this._mapControl.centerMap(lat, lng));
		promises.push(this._mapControl.setZoom(zoom));

		return Promise.all(promises).then(() => {
			this._setInitialBounds().then(() => {
				this.showWeatherStationsList = true;
			});
		});
	}

	private _resetMap(): void {
		if (!this._map || !this.ranchLocation) {
			return;
		}

		this._map.triggerResize().then(() => {
			this._recenterMap(this.ranchLocation.Latitude, this.ranchLocation.Longitude, this._zoom);
		});
	}

	private _scaleMarkerIconSizes(zoom: number): void {
		let scaledSize: {
			height: number,
			width: number
		} = this._iconSizes.regular;

		if (zoom < 10 && zoom >= this._mininumZoomLevel) {
			scaledSize = {
				height: this._iconSizes.regular.height * ((zoom - 5) / 5),
				width: this._iconSizes.regular.width * ((zoom - 5) / 5)
			}
		} else if (zoom < this._mininumZoomLevel) {
			scaledSize = this._iconSizes.small;
		}

		this._icons.selected.scaledSize = scaledSize;
		this._icons.unselected.scaledSize = scaledSize;
	}

	private _setInitialBounds(): Promise<void> {
		return this._mapControl.getBounds()
			.then(bounds => {
				this.resetMapOnboundsChange(bounds);
			});
	}

	private _setWeatherStations(bounds: LatLngBounds): void {
		let filteredStations: IMarker[];
		let stationsWithinBounds: IMarker[];
		let ne: LatLng;
		let sw: LatLng;

		this.zoomOutWarning = false;

		filteredStations = new Array();
		stationsWithinBounds = new Array();

		if (!bounds) {
			return;
		}

		ne = bounds.getNorthEast();
		sw = bounds.getSouthWest();

		if (!ne || !sw) {
			return;
		}

		for (let station of this._originalStations) {

			if (station.Latitude >= sw.lat() && station.Latitude <= ne.lat() &&
				station.Longitude >= sw.lng() && station.Longitude <= ne.lng()) {

				stationsWithinBounds.push(station);
			}
		}

		if (!stationsWithinBounds || stationsWithinBounds.length === 0) {
			this.zoomOutWarning = true;

			filteredStations = this._originalStations
				.filter(data => data.Selected);
		} else {
			filteredStations = this._originalStations
				.filter(data => data.Selected ||
					(data.Latitude >= sw.lat() && data.Latitude <= ne.lat() && // NE latitude is bigger than SW
						data.Longitude >= sw.lng() && data.Longitude <= ne.lng())); // NE long is bigger than SW (because they're negative numbers)
		}

		this.weatherStations = new Array();

		for (let station of filteredStations) {
			let copy: IMarker;

			copy = jQuery.extend(true, {}, station);
			this.weatherStations.push(copy);
		}
	}

	private _sortStationsByDistanceFromRanch(stations: IMarker[]): IMarker[] {
		if (!stations) {
			return new Array();
		}

		stations.sort(function (a, b) {
			return a.Distance - b.Distance;
		});

		return stations;
	}
}
