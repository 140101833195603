<admin-nav></admin-nav>

<div class="bk-admin__page">
	
	<div class="bk-form__header">
		<h5 class="bk-text--gray">
			{{ 'Edit' | translate}} {{ 'Crop Type' | translate}}
		</h5>
	</div>

	<form *ngIf="form" [formGroup]="form">
		<div class="bk-form__wrapper" >

			<label class="bk-modal__label">
				{{'Name' | translate }}
			</label>
			<input class="bk-modal__field bk-form__input-text--wide"
				type="text"
				name="Name"
				formControlName="Name"/>

			<label class="bk-modal__label">
				{{'Commodity Type' | translate }}
			</label>
			<select class="bk-modal__field"
				name="CommodityTypeId"
				formControlName="CommodityTypeId">

				<option [ngValue]="0"
					selected>
					{{'Select One' | translate }}
				</option>
				<option *ngFor="let commodityType of commodityTypes"
					[ngValue]="commodityType.Id">
					{{commodityType.Name}}
				</option>
			</select>

			<ng-container *ngIf="!f.IsYearSpecific.value || f.CommodityTypeId.value === COMMODITY_TYPES.ALFALFA">
				<label class="bk-modal__label">
					{{'Maximum Crop Age' | translate }} <mat-icon class="bk-info-icon" matTooltip="{{ 'Max Age Help Text' | translate }}">
						info_outline
					</mat-icon>
				</label>
				<select class="bk-modal__field"
					name="MaxAge"
					formControlName="MaxAge">
	
					<option 
						*ngFor="let age of maxCropAges"
						[ngValue]="age.key">
						{{age.value}}
					</option>
				</select>
			</ng-container>

			<label class="bk-modal__label">
				{{'Crop Sensitivity' | translate }} (Optional)
			</label>
			<input class="bk-modal__field"
				type="number"
				name="CropSensitivity"
				formControlName="CropSensitivity"/>

			<fieldset *ngIf="f.ShouldShowTreeCoefficients.value"
				class="bk-form__fieldset">
				<legend class="bk-form__legend">
					{{ 'Tree Coefficients' | translate }}
				</legend>

				<ul class="bk-form__side-by-side">
					<li>
						<label class="bk-modal__label">
							{{'Intercept' | translate }}
						</label>
						<input class="bk-modal__field bk-form__input-text--float"
							type="number"
							name="TreeInterceptCoefficient"
							formControlName="TreeInterceptCoefficient"/>
					</li>
					<li>
						<label class="bk-modal__label">
							{{'Spring Leaf N' | translate }}
						</label>
						<input class="bk-modal__field bk-form__input-text--float"
							type="number"
							name="TreeLeafNCoefficient"
							formControlName="TreeLeafNCoefficient"/>
					</li>
					<li>
						<label class="bk-modal__label">
							{{'ilr4' | translate }}
						</label>
						<input class="bk-modal__field bk-form__input-text--float"
							type="number"
							name="TreeIlr4Coefficient"
							formControlName="TreeIlr4Coefficient"/>
					</li>
					<li>
						<label class="bk-modal__label">
							{{'ilr8' | translate }}
						</label>
						<input class="bk-modal__field bk-form__input-text--float"
							type="number"
							name="TreeIlr8Coefficient"
							formControlName="TreeIlr8Coefficient" />
					</li>
					<li>
						<label class="bk-modal__label">
							{{'ilr4 X ilr8' | translate }}
						</label>
						<input class="bk-modal__field bk-form__input-text--float"
							type="number"
							name="TreeIlrProductCoefficient"
							formControlName="TreeIlrProductCoefficient" />
					</li>
				</ul>
			</fieldset>

			<ng-container *ngIf="!f.IsTree.value && !f.IsNDependent.value">

				<ng-container *ngIf="f.IsPreviousCrop.value === false">
					<label class="bk-modal__label">
						{{'Previous' | translate }} {{'Crop Type' | translate }}
					</label>
					<input class="bk-modal__field"
						type="text"
						name="PreviousCropName"
						formControlName="PreviousCropName"/>
				</ng-container>
			</ng-container>

			<div class="bk-form__checkbox">
				<input class="bk-form__checkbox__input"
					type="checkbox"
					name="IsNDependent"
					formControlName="IsNDependent"/>
				<div class="bk-form__checkbox__label">
					{{ 'Is N Dependent' | translate }} 
					<mat-icon class="bk-info-icon"
						matTooltip="{{ 'N Dependent Help Text' | translate }}">
						info_outline
					</mat-icon>
				</div>
			</div>

			<div class="bk-form__checkbox">
				<input class="bk-form__checkbox__input"
					type="checkbox"
					name="IsMacroTunnelEnabled"
					formControlName="IsMacroTunnelEnabled"/>
				<div class="bk-form__checkbox__label">
					{{ 'Enable Macro Tunnel' | translate }} 
					<mat-icon class="bk-info-icon"
						matTooltip="{{ 'Enable Macro Tunnel Help Text' | translate }}">
						info_outline
					</mat-icon>
				</div>
			</div>

			<ng-container *ngIf='f.IsNDependent.value'>

				<h4>
					{{ 'Fertilization' | translate }}
				</h4>
				<!-- fertilization section -->
				<ul class="bk-form__side-by-side">
					<li>
						<label class="bk-modal__label">
							{{'Fert A' | translate }}
						</label>
						<input class="bk-modal__field bk-form__input-text--float"
							type="number"
							name="FertA"
							formControlName="FertA"/>
					</li>
					<li>
						<label class="bk-modal__label">
							{{'Fert B' | translate }}
						</label>
						<input class="bk-modal__field bk-form__input-text--float"
							type="number"
							name="FertB"
							formControlName="FertB"/>
					</li>
					<li>
						<label class="bk-modal__label">
							{{'Fert Yo' | translate }}
						</label>
						<input class="bk-modal__field bk-form__input-text--float"
							type="number"
							name="FertYo"
							formControlName="FertYo" />
					</li>
				</ul>

				<fieldset class="bk-form__fieldset">
					<ng-container *ngIf="!f.IsTree.value">
						<legend class="bk-form__legend">
							{{ 'N Uptake' | translate }}
						</legend>

						<label class="bk-modal__label">
							{{'Max Uptake' | translate }}
						</label>
						<input class="bk-modal__field bk-form__input-text--float"
							type="number"
							name="MaxUptake"
							formControlName="MaxUptake" />
			
						<ng-container *ngIf="!f.IgnoresNFactors.value">
							<label class="bk-modal__label">
								{{'N Uptake Threshold' | translate }}
							</label>
							<input class="bk-modal__field bk-form__input-text--float"
								type="number"
								name="NUptakeThreshold"
								formControlName="NUptakeThreshold"/>
						</ng-container>

						<label class="bk-modal__label">
							{{'Uptake Constant' | translate }}
						</label>
						<input class="bk-modal__field bk-form__input-text--float"
							type="number"
							name="UptakeConstant"
							formControlName="UptakeConstant" />
					</ng-container>
		
					<label class="bk-modal__label">
						{{'Uptake Factor' | translate }}
					</label>
					<input class="bk-modal__field bk-form__input-text--float"
						type="number"
						name="UptakeFactor"
						formControlName="UptakeFactor" />
				</fieldset>

				<fieldset class="bk-form__fieldset" *ngIf="!f.IsTree.value">
					<legend class="bk-form__legend">
						{{ 'Soil N Threshold' | translate }}
					</legend>

					<ul class="bk-form__side-by-side">
						<li>
							<label class="bk-modal__label">
								{{'Soil N Threshold' | translate }}
							</label>
							<input class="bk-modal__field bk-form__input-text--float"
								type="number"
								name="SoilNThreshold"
								formControlName="SoilNThreshold" />
						</li>
						<li>
							<label class="bk-modal__label">
								{{'Soil N Threshold Mid' | translate }}
							</label>
							<input class="bk-modal__field bk-form__input-text--float"
								type="number"
								name="SoilNThresholdMid"
								formControlName="SoilNThresholdMid" />
						</li>
						<li>
							<label class="bk-modal__label">
								{{'Soil N Threshold Late' | translate }}
							</label>
							<input class="bk-modal__field bk-form__input-text--float"
								type="number"
								name="SoilNThresholdLate"
								formControlName="SoilNThresholdLate"/>
						</li>
					</ul>
					<ul class="bk-form__side-by-side">
						<li>
							<label class="bk-modal__label">
								{{'Soil N Threshold Fraction' | translate }}
							</label>
							<input class="bk-modal__field bk-form__input-text--float"
								type="number"
								name="SoilNThresholdFraction"
								formControlName="SoilNThresholdFraction"/>
						</li>
						<li>
							<label class="bk-modal__label">
								{{'Soil N Threshold Fraction Mid' | translate }}
							</label>
							<input class="bk-modal__field bk-form__input-text--float"
								type="number"
								name="SoilNThresholdFractionMid"
								formControlName="SoilNThresholdFractionMid" />
						</li>
					</ul>
				</fieldset>

				<fieldset class="bk-form__fieldset"
					*ngIf="!f.IgnoresNFactors.value">
					<legend class="bk-form__legend">
						{{ 'Soil N Ratio' | translate}}
					</legend>

					<ul class="bk-form__side-by-side">
						<li>
							<label class="bk-modal__label">
								A
							</label>
							<input class="bk-modal__field bk-form__input-text--float"
								type="number"
								name="SoilNRatioA"
								formControlName="SoilNRatioA"  />
						</li>
						<li>
							<label class="bk-modal__label">
								B
							</label>
							<input class="bk-modal__field bk-form__input-text--float"
								type="number"
								name="SoilNRatioB"
								formControlName="SoilNRatioB" />
						</li>
						<li>
							<label class="bk-modal__label">
								C
							</label>
							<input class="bk-modal__field bk-form__input-text--float"
								type="number"
								name="SoilNRatioC"
								formControlName="SoilNRatioC" />
						</li>
					</ul>
				</fieldset>

				<fieldset class="bk-form__fieldset"
					*ngIf="!f.IsTree.value && !f.IgnoresNFactors.value">
					<legend class="bk-form__legend">
						{{ 'N Fertilizer Factors' | translate }}
					</legend>

					<ul class="bk-form__side-by-side">
						<li>
							<label class="bk-modal__label">
								A
							</label>
							<input class="bk-modal__field bk-form__input-text--float"
								type="number"
								name="NFertilizerFactorA"
								formControlName="NFertilizerFactorA" />
						</li>
						<li>
							<label class="bk-modal__label">
								B
							</label>
							<input class="bk-modal__field bk-form__input-text--float"
								type="number"
								name="NFertilizerFactorB"
								formControlName="NFertilizerFactorB"  />
						</li>
						<li>
							<label class="bk-modal__label">
								C
							</label>
							<input class="bk-modal__field bk-form__input-text--float"
								type="number"
								name="NFertilizerFactorC"
								formControlName="NFertilizerFactorC" />
						</li>
						<li>
							<label class="bk-modal__label">
								D
							</label>
							<input class="bk-modal__field bk-form__input-text--float"
								type="number"
								name="NFertilizerFactorD"
								formControlName="NFertilizerFactorD" />
						</li>
					</ul>
				</fieldset>

				<!-- perennial N Parameters -->

				<ng-container *ngIf="!f.IsTree.value">
					<label class="bk-modal__label">
						{{'Crop Mineralization Rate' | translate }}
					</label>
					<input class="bk-modal__field bk-form__input-text--float"
						type="number"
						name="CropMineralizationRate"
						formControlName="CropMineralizationRate"  />

					<label class="bk-modal__label">
						{{'Previous Crop K' | translate }}
					</label>
					<input class="bk-modal__field bk-form__input-text--float"
						type="number"
						name="PrevCropK"
						formControlName="PrevCropK" />
				</ng-container>

				<label class="bk-modal__label">
					{{'Max N Recommendation' | translate }} (lbs N/acre)
				</label>
				
				<input class="bk-modal__field bk-form__input-text--float"
					type="number"
					name="MaxNRec"
					[minNumber]="0.01"
					formControlName="MaxNRec" />

				<ng-container *ngIf="!f.IsTree.value && (f.CalculatorId.value === CALCULATOR.STRAWBERRY
					|| f.CalculatorId.value === CALCULATOR.TOMATO)">
					
					<label class="bk-modal__label">
						{{'Strawberry Fertilization Interval' | translate }}
					</label>
					<input class="bk-modal__field bk-form__input-text--float"
						type="number"
						name="StrawberryFertilizationInterval"
						formControlName="StrawberryFertilizationInterval" />
				</ng-container>
			</ng-container>

			<ng-container *ngIf="f.IsTree.value">
				<ng-container *ngIf="f.SpringLeafNutrientsRequired.value">
					<label class="bk-modal__label">
						{{'Inadequate Summer Leaf N' | translate }} (%)
					</label>
					<input class="bk-modal__field bk-form__input-text--float"
						type="number"
						name="InadequateSummerLeafN"
						formControlName="InadequateSummerLeafN" />

					<label class="bk-modal__label">
						{{'Excessive Summer Leaf N' | translate }} (%)
					</label>
					<input class="bk-modal__field bk-form__input-text--float"
						type="number"
						name="ExcessiveSummerLeafN"
						formControlName="ExcessiveSummerLeafN" />
				</ng-container>

				<label class="bk-modal__label">
					{{'Leaf tissue Nfertilizer adjustment' | translate }} (lbs N/acre)
				</label>
				<input class="bk-modal__field bk-form__input-text--float"
					type="number"
					name="NFertilizerAdjustment"
					formControlName="NFertilizerAdjustment" />

				<label class="bk-modal__label">
					{{'Fertilizer N Use Efficiency' | translate }} (%)
				</label>
				<input class="bk-modal__field bk-form__input-text--float"
					type="number"
					name="FertilizerUseEfficiency"
					formControlName="FertilizerUseEfficiency" />

				<table class="bk-form__table">
					<thead>
						<th>
							&nbsp;
						</th>
						<th>
							{{ 'Low Vigor' | translate }}
						</th>
						<th>
							{{ 'High Vigor' | translate }}
						</th>
					</thead>
					<tbody>
						<tr *ngFor="let demand of f.VegetativeNDemands.controls; let i = index">
							<td>
								<label *ngIf="demand.get('YearMin').value === demand.get('YearMax').value">
									{{ demand.get('YearMax').value + 1 }} {{ 'Year' | translate }}
								</label>
								<label *ngIf="demand.get('YearMax').value > 100">
									{{ demand.get('YearMin').value }}+ {{ 'Years' | translate}}
								</label>
								<label *ngIf="demand.get('YearMin').value !== demand.get('YearMax').value
									&& demand.get('YearMax').value !== 1000000 && demand.get('YearMin').value === 0">
									{{ 'First' | translate }} {{ 'Year' | translate}}
								</label>
								<label *ngIf="demand.get('YearMin').value !== demand.get('YearMax').value
									&& demand.get('YearMax').value !== 1000000 && demand.get('YearMin').value > 0">

									{{ demand.get('YearMin').value }} - {{ demand.get('YearMax').value }} {{ 'Years' | translate}}
								</label>
							</td>
							<td>
								<input class="bk-form__input-text--float"
									type="number"
									name="vegetativeNDemandLow{{i}}"
									[formControl]="f.VegetativeNDemands.controls[i].get('Low')" />
							</td>
							<td>
								<input class="bk-form__input-text--float"
									type="number"
									name="vegetativeNDemandHigh{{i}}"
									[formControl]="f.VegetativeNDemands.controls[i].get('High')" />
							</td>
						</tr>
					</tbody>
				</table>

				<table class="bk-form__table"
					*ngIf="f.SpringLeafNutrientsRequired.value === true">
					<thead>
						<th>
							&nbsp;
						</th>
						<th>
							{{ 'Season Average' | translate }}
						</th>
						<th>
							{{ 'Threshold' | translate }}
						</th>
					</thead>
					<tbody>
						<ng-container *ngFor="let threshold of fnc.TreeNutrientThresholds.controls; let i = index">
							<tr *ngIf="(f.ShouldShowTreeCoefficients.value && threshold.get('NutrientSymbol').value !== 'B')
								|| (!f.ShouldShowTreeCoefficients.value && threshold.get('NutrientSymbol').value !== 'P'
								&& threshold.get('NutrientSymbol').value !== 'S')">
								
								<td>
									<label>
										{{ threshold.get('NutrientSymbol').value }} ({{ threshold.get('NutrientUnit').value }})
									</label>
								</td>
								<td>
									<input class="bk-form__input-text--float"
										type="number"
										name="treeNutrientThresholdSeasonAverage{{i}}"
										[formControl]="fnc.TreeNutrientThresholds.controls[i].get('SeasonAverage')" />
								</td>
								<td>
									<input class="bk-form__input-text--float"
										type="number"
										name="treeNutrientThresholdThreshold{{i}}"
										[formControl]="fnc.TreeNutrientThresholds.controls[i].get('Threshold')" />
								</td>
							</tr>
						</ng-container>
					</tbody>
				</table>

				<table class="bk-form__table"
					*ngIf="f.SpringLeafNutrientsRequired.value === true && !f.ShouldShowTreeCoefficients.value"><!-- hide these if not almonds -->
					<thead>
						<th>
							&nbsp;
						</th>
						<th>
							{{ 'Factor' | translate }}
						</th>
						<th>
							{{ 'Coefficient' | translate }} A
						</th>
						<th>
							{{ 'Coefficient' | translate }} B
						</th>
					</thead>
					<tbody>
						<ng-container *ngFor="let threshold of fnc.TreeNutrientThresholds.controls; let i = index">
							<tr *ngIf="threshold.get('NutrientSymbol').value !== 'P' &&
							threshold.get('NutrientSymbol').value !== 'S'">
								<td>
									<label>
										{{ threshold.get('NutrientSymbol').value }} ({{ threshold.get('NutrientUnit').value }})
									</label>
								</td>
								<td>
									<input class="bk-form__input-text--float"
										type="number"
										name="treeNutrientThresholdTissueFactor{{i}}"
										[formControl]="fnc.TreeNutrientThresholds.controls[i].get('TissueFactor')" />
								</td>
								<td>
									<input class="bk-form__input-text--float"
										type="number"
										name="treeNutrientThresholdTissueCoefficientA{{i}}"
										[formControl]="fnc.TreeNutrientThresholds.controls[i].get('TissueCoefficientA')" />
								</td>
								<td>
									<ng-container *ngIf="threshold.get('NutrientSymbol').value === 'N'">
										<input class="bk-form__input-text--float"
											type="number"
											name="treeNutrientThresholdTissueCoefficientB{{i}}"
											[formControl]="fnc.TreeNutrientThresholds.controls[i].get('TissueCoefficientB')" />
									</ng-container> 
								</td>
							</tr>
						</ng-container>
					</tbody>
				</table>
			</ng-container>

			<!-- irrigation -->

			<h4>Irrigation Factors</h4>

			<table class="bk-form__table">
				<thead>
					<th>Irrigation Method</th>
					<th>Factor</th>
				</thead>
				<tbody>
					<tr>
						<td>
							<label>Sprinkler</label>
						</td>
						<td>
							<input class="bk-form__input-text--float"
								type="number"
								name="SprinklerIrrigationFactor"
								formControlName="SprinklerIrrigationFactor" />
						</td>
					</tr>
					<tr>
						<td>
							<label>Drip</label>
						</td>
						<td>
							<input class="bk-form__input-text--float"
								type="number"
								name="DripIrrigationFactor"
								formControlName="DripIrrigationFactor" />
						</td>
					</tr>
					<tr>
						<td>
							<label>Flood</label>
						</td>
						<td>
							<input class="bk-form__input-text--float"
								type="number"
								name="FloodIrrigationFactor"
								formControlName="FloodIrrigationFactor" />
						</td>
					</tr>
				</tbody>
			</table>

			<fieldset class="bk-form__fieldset">
				<legend class="bk-form__legend">
					{{ 'Target Tensions' | translate }}
				</legend>

				<ul class="bk-form__side-by-side">
					<li>
						<label class="bk-modal__label">
							{{'1 ft' | translate }}
						</label>
						<input class="bk-modal__field bk-form__input-text--float"
							type="number"
							name="TargetTension1"
							formControlName="TargetTension1" />
					</li>
					<li>
						<label class="bk-modal__label">
							{{'2 ft' | translate }}
						</label>
						<input class="bk-modal__field bk-form__input-text--float"
							type="number"
							name="TargetTension2"
							formControlName="TargetTension2" />
					</li>
					<li>
						<label class="bk-modal__label bk-form__input-text--float">
							{{'3 ft' | translate }}
						</label>
						<input class="bk-modal__field"
							type="number"
							name="TargetTension3"
							formControlName="TargetTension3" />
					</li>
					<li>
						<label class="bk-modal__label bk-form__input-text--float">
							{{'4 ft' | translate }}
						</label>
						<input class="bk-modal__field"
							type="number"
							name="TargetTension4"
							formControlName="TargetTension4"/>
					</li>
				</ul>
			</fieldset>
			
			<fieldset class="bk-form__fieldset">
				<legend class="bk-form__legend">
					{{ 'Canopy' | translate }}
				</legend>

				<ul class="bk-form__side-by-side">
					<li *ngIf="f.IsYearSpecific.value || f.CommodityTypeId.value === COMMODITY_TYPES.ALFALFA">
						<label class="bk-modal__label">
							{{'Canopy' | translate }} A
						</label>
						<input class="bk-modal__field bk-form__input-text--float"
							type="number"
							name="CanopyA"
							formControlName="CanopyA" />
					</li>
					<li *ngIf="f.IsYearSpecific.value || f.CommodityTypeId.value === COMMODITY_TYPES.ALFALFA">
						<label class="bk-modal__label">
							{{'Canopy' | translate }} B
						</label>
						<input class="bk-modal__field bk-form__input-text--float"
							type="number"
							name="CanopyB"
							formControlName="CanopyB" />
					</li>
					<li>
						<label class="bk-modal__label">
							{{'Canopy' | translate }} C
						</label>
						<input class="bk-modal__field bk-form__input-text--float"
							type="number"
							name="CanopyC"
							formControlName="CanopyC" />
					</li>
					<li>
						<label class="bk-modal__label">
							{{'Canopy' | translate }} D
						</label>
						<input class="bk-modal__field bk-form__input-text--float"
							type="number"
							name="CanopyD"
							formControlName="CanopyD" />
					</li>
					<li *ngIf="f.CalculatorId.value === CALCULATOR.TOMATO">
						<label class="bk-modal__label">
							{{'Canopy' | translate }} E
						</label>
						<input class="bk-modal__field bk-form__input-text--float"
							type="number"
							name="CanopyE"
							formControlName="CanopyE"/>
					</li>
					<li *ngIf="f.CalculatorId.value === CALCULATOR.TOMATO">
						<label class="bk-modal__label">
							{{'Canopy' | translate }} F
						</label>
						<input class="bk-modal__field bk-form__input-text--float"
							type="number"
							name="CanopyF"
							formControlName="CanopyF" />
					</li>
				</ul>

				<ng-container *ngIf="form.get('MaxAge').value > 0 && !f.IsYearSpecific.value &&
					f.CommodityTypeId.value !== COMMODITY_TYPES.ALFALFA">
					<label class="bk-modal__label">
						{{'Perennial Canopy Parameters' | translate }}
					</label>
					
					<button class="bk-button bk-create-button"
						(click)="createPerennialCanopy()">
						<span class="bk-data-table__plus-icon">+</span> 
						{{ 'Create' | translate}}
					</button>
				
					<table *ngIf="perennialCanopies && perennialCanopies.length > 0"
						class="bk-form__table">
						<thead>
							<th>
								Age of Crop
							</th>
							<th>
								{{ 'Canopy' | translate }} A
							</th>
							<th>
								{{ 'Canopy' | translate }} B
							</th>
							<th *ngIf="f.CommodityTypeId.value !== COMMODITY_TYPES.CANEBERRY">
								{{ 'Canopy' | translate }} E
							</th>
							<th *ngIf="f.CommodityTypeId.value !== COMMODITY_TYPES.CANEBERRY">
								{{ 'Canopy' | translate }} F
							</th>
							<th>
								{{ 'Max Canopy' | translate }}
							</th>
							<th>
								{{ 'Canopy Max Fraction' | translate }}
							</th>
							<th>
								{{ 'Action' | translate }}
							</th>
						</thead>
						<tbody>
							<ng-container *ngFor="let canopy of perennialCanopies; let i = index">
								<tr>
									<td>
										{{ canopy.Age + 1}}
									</td>
									<td>
										{{ canopy.CanopyA }}
									</td>
									<td>
										{{ canopy.CanopyB }}
									</td>
									<td *ngIf="f.CommodityTypeId.value !== COMMODITY_TYPES.CANEBERRY">
										{{ canopy.CanopyE }}
									</td>
									<td *ngIf="f.CommodityTypeId.value !== COMMODITY_TYPES.CANEBERRY">
										{{ canopy.CanopyF }}
									</td>
									<td>
										{{ canopy.CanopyGMax }}
									</td>
									<td>
										{{ canopy.CanopyMaxFraction }}
									</td>
									<td>
										<button type="button"
											class="bk-button bk-admin__button"
											(click)="editPerennialCanopy(canopy)">
											{{ 'Edit' | translate }}
										</button>

										<button type="button"
											class="bk-button bk-admin__button"
											(click)="deletePerennialCanopy(canopy.Id)">
											{{ 'Delete' | translate }}
										</button>
									</td>
								</tr>
							</ng-container>
						</tbody>
					</table>
				</ng-container>

				<ng-container *ngIf="f.IsYearSpecific.value || f.CommodityTypeId.value === COMMODITY_TYPES.ALFALFA">
					<!-- alfalfa uses canopyMin and canopyMax -->
					<label class="bk-modal__label">
						{{'Canopy GMax' | translate }}
					</label>
					<input class="bk-modal__field bk-form__input-text--float"
						type="number"
						name="CanopyGMax"
						formControlName="CanopyGMax" />
				</ng-container>

				<ng-container *ngIf="f.CommodityTypeId.value === COMMODITY_TYPES.ALFALFA">
					<!-- alfalfa uses canopyMin -->
					<label class="bk-modal__label">
						{{'Canopy Min' | translate }}
					</label>
					<input class="bk-modal__field bk-form__input-text--float"
						type="number"
						name="CanopyMin"
						formControlName="CanopyMin" />
				</ng-container>
		
				<label class="bk-modal__label">
					{{'Canopy Intercept' | translate }}
				</label>
				<input class="bk-modal__field bk-form__input-text--float"
					type="number"
					name="CanopyIntercept"
					formControlName="CanopyIntercept" />
		
				<!-- alfalfa doesn't use CanopyMaxFraction in its calculations -->
				<ng-container *ngIf="f.IsYearSpecific.value && f.CommodityTypeId.value !== COMMODITY_TYPES.ALFALFA">
					<label class="bk-modal__label">
						{{'Canopy Max Fraction' | translate }}
					</label>
					<input class="bk-modal__field bk-form__input-text--float"
						type="number"
						name="CanopyMaxFraction"
						formControlName="CanopyMaxFraction" />
				</ng-container>
			</fieldset>

			<!-- ALFALFA section -->

			<ng-container *ngIf="f.CommodityTypeId.value === COMMODITY_TYPES.ALFALFA">
				<label class="bk-modal__label">
					{{'Base KC' | translate }}
				</label>
				<input class="bk-modal__field bk-form__input-text--float"
					type="number"
					name="BasalKC"
					formControlName="BasalKC" />

				<table *ngIf="f.SeasonalKCs && f.SeasonalKCs.controls.length > 0" 
					class="bk-form__table" cellspacing="5" cellpadding="5">
					<thead>
						<th>&nbsp;</th>
						<th>
							{{ 'Ni to Max Canopy' | translate }}
						</th>
					</thead>
					<tbody>
						<tr *ngFor="let month of months; let i = index">
							<td>
								<label>
									{{ month }}
								</label>
							</td>
							<td>
								<input class="bk-form__input-text--float"
									type="number"
									[formControl]="f.SeasonalKCs.controls[i].get('NiToMaxCanopy')" />
							</td>
						</tr>
					</tbody>
				</table>
			</ng-container>

			<!-- Almonds section -->

			<ng-container *ngIf="f.IsTree.value">

				<div class="bk-form__checkbox">
					<input class="bk-form__checkbox__input"
						type="checkbox"
						name="PreHarvestSenesce"
						formControlName="PreHarvestSenesce"/>
					<div class="bk-form__checkbox__label">
						{{ 'Pre-Harvest Senesce' | translate }} 
						<mat-icon class="bk-info-icon"
							matTooltip="{{ 'Pre-Harvest Senesce Help Text' | translate }}">
							info_outline
						</mat-icon>
					</div>
				</div>

				<ng-container *ngIf="f.PreHarvestSenesce.value">
					<label class="bk-modal__label">
						{{'Leaf Senesce' | translate }} (%)
					</label>
					<input class="bk-modal__field bk-form__input-text--float"
						type="number"
						name="LeafSenesce"
						formControlName="LeafSenesce"/>
				</ng-container>

				<ul class="bk-form__side-by-side">
					<li>
						<label class="bk-modal__label">
							{{'Kccov_quad' | translate }}
						</label>
						<input class="bk-modal__field bk-form__input-text--float"
							type="number"
							name="Kccov_quad"
							formControlName="Kccov_quad"/>
					</li>
					<li>
						<label class="bk-modal__label">
							{{'Kccov_int' | translate }}
						</label>
						<input class="bk-modal__field bk-form__input-text--float"
							type="number"
							name="Kccov_int"
							formControlName="Kccov_int"/>
					</li>
					<li>
						<label class="bk-modal__label">
							{{'Kccov_slope' | translate }}
						</label>
						<input class="bk-modal__field bk-form__input-text--float"
							type="number"
							name="Kccov_slope"
							formControlName="Kccov_slope"/>
					</li>
				</ul>

				<label class="bk-modal__label">
					{{'Cov Density Factor' | translate }}
				</label>
				<input class="bk-modal__field bk-form__input-text--float"
					type="number"
					name="CovDensityFactor"
					formControlName="CovDensityFactor"/>

				<fieldset class="bk-form__fieldset">
					<legend class="bk-form__legend">
						{{ 'Default Wet Area' | translate }}
					</legend>
					<ul class="bk-form__side-by-side">
						<li>
							<label class="bk-modal__label">
								{{ 'Drip' | translate}}
							</label>
							<input class="bk-modal__field bk-form__input-text--float"
								type="number"
								name="WetAreaDrip"
								formControlName="WetAreaDrip"/>
						</li>
						<li>
							<label class="bk-modal__label">
								{{ 'Micro Sprinkler' | translate}}
							</label>
							<input class="bk-modal__field bk-form__input-text--float"
								type="number"
								name="WetAreaMicroSprinkler"
								formControlName="WetAreaMicroSprinkler"/>
						</li>
					</ul>
				</fieldset>

				<table class="bk-form__table" cellspacing="5" cellpadding="5">
					<thead>
						<th>&nbsp;</th>
						<th>
							{{ 'Density' | translate }}
						</th>
						<th>
							{{ 'Cover' | translate }}
						</th>
					</thead>
					<tbody>
						<tr *ngFor="let month of months; let i = index">
							<td>
								<label>
									{{ month }}
								</label>
							</td>
							<td>
								<select name="Density{{i}}"
									class=""
									[formControl]="f.SeasonalKCs.controls[i].get('Density')">
					
									<option *ngFor="let densityOption of densityOptions"
										[ngValue]="densityOption.key">
										{{densityOption.value}}
									</option>
								</select>
							</td>
							<td>
								<input class="bk-form__input-text--float"
									type="number"
									name="Cover{{i}}"
									[formControl]="f.SeasonalKCs.controls[i].get('Cover')"/>
							</td>
						</tr>
					</tbody>
				</table>

			</ng-container>

			<!-- STRESSSABLE -->

			<div class="bk-form__checkbox">
				<input class="bk-form__checkbox__input"
					type="checkbox"
					name="IsStressable"
					formControlName="IsStressable"/>
				<div class="bk-form__checkbox__label">
					<div>{{ 'Is Stressable' | translate }}</div>
				</div>
			</div>

			<ng-container *ngIf='f.IsStressable.value'>

				<ng-container *ngIf="!f.IsTree.value">
					<label class="bk-modal__label">
						{{'Early Fruit' | translate }}
					</label>
					<input class="bk-modal__field bk-form__input-text--float"
						type="number"
						name="EarlyFruit"
						formControlName="EarlyFruit"/>
				</ng-container>

				<ul class="bk-form__side-by-side">
					<li>
						<label class="bk-modal__label">
							{{'Water Stress Start' | translate }}
						</label>
						<input class="bk-modal__field bk-form__input-text--float"
							type="number"
							name="WaterStressStart"
							formControlName="WaterStressStart"/>
					</li>
					<li>
						<label class="bk-modal__label">
							{{'Water Stress End' | translate }}
						</label>
						<input class="bk-modal__field bk-form__input-text--float"
							type="number"
							name="WaterStressEnd"
							formControlName="WaterStressEnd"/>
					</li>
				</ul>
				
				<ul class="bk-form__side-by-side">
					<li>
						<label class="bk-modal__label">
							{{'Stress Start' | translate }}
						</label>
						<input class="bk-modal__field bk-form__input-text--float"
							type="number"
							name="StressStart"
							formControlName="StressStart"/>
					</li>
					<li>
						<label class="bk-modal__label">
							{{'Stress End' | translate }}
						</label>
						<input class="bk-modal__field bk-form__input-text--float"
							type="number"
							name="StressEnd"
							formControlName="StressEnd"/>
					</li>
				</ul>
				
			</ng-container>

			<!-- misc fields -->
			<h4>
				{{ 'Miscellaneous Parameters' | translate }}
			</h4>

			<fieldset class="bk-form__fieldset">
				<legend class="bk-form__legend">
					{{ 'Days' | translate }}
				</legend>

				<ul class="bk-form__side-by-side">
					<li>
						<label class="bk-modal__label">
							{{'Germination Days' | translate }}
						</label>
						<input class="bk-modal__field bk-form__input-text--float"
							type="number"
							name="GermDays"
							formControlName="GermDays"/>
					</li>
					<li>
						<label class="bk-modal__label">
							{{'Max Days' | translate }}
						</label>
						<input class="bk-modal__field bk-form__input-text--float"
							type="number"
							name="MaxDays"
							formControlName="MaxDays"/>
					</li>
				</ul>

				<ng-container *ngIf="f.IsNDependent.value">
					<label class="bk-modal__label">
						{{'Harvest Interval Fraction' | translate }}
					</label>
					<input class="bk-modal__field bk-form__input-text--float"
						type="number"
						name="HarvestIntervalFraction"
						formControlName="HarvestIntervalFraction" />
					<p>Harvest Interval Fraction is a factor in calculating Soil Mineralization which is used
						to calculate fertilization recommendations.
					</p>
				</ng-container>
		
				<label class="bk-modal__label">
					{{'Days to End Date' | translate }}
				</label>
				<input class="bk-modal__field bk-form__input-text--float"
					type="number"
					name="DaysToEndDate"
					formControlName="DaysToEndDate"/>
				
			</fieldset>

			<fieldset class="bk-form__fieldset">
				<legend class="bk-form__legend">
					{{ 'Root Depth' | translate }}
				</legend>

				<ng-container *ngIf="!f.IsYearSpecific.value">

					<table class="bk-form__table">
						<thead>
							<th>
								&nbsp;
							</th>
							<th>
								{{ 'Min' | translate }}
							</th>
							<th>
								{{ 'Max' | translate }}
							</th>
						</thead>
						<tbody>
							<tr *ngFor="let depth of f.PerennialRootDepths.controls; let i = index">
								<td>
									<label *ngIf="depth.get('YearMax').value !== 1000000">
										{{ depth.get('YearMax').value }} {{ 'Year' | translate }}
									</label>
									<label *ngIf="depth.get('YearMax').value === 1000000">
										{{ depth.get('YearMin').value + 1 }} + {{ 'Years' | translate}}
									</label>
								</td>
								<td>
									<input class="bk-form__input-text--float"
										type="number"
										name="perennialRootDepthMin{{i}}"
										[formControl]="f.PerennialRootDepths.controls[i].get('Min')"/>
								</td>
								<td>
									<input class="bk-form__input-text--float"
										type="number"
										name="perennialRootDepthMax{{i}}"
										[formControl]="f.PerennialRootDepths.controls[i].get('Max')" />
								</td>
							</tr>
						</tbody>
					</table>
				
				</ng-container>

				<ul *ngIf="f.IsYearSpecific.value"
					class="bk-form__side-by-side">
					
					<li>
						<label class="bk-modal__label">
							{{'Minimum Root Depth' | translate }}
						</label>
						<input class="bk-modal__field bk-form__input-text--float"
							type="number"
							name="MinRootDepth"
							formControlName="MinRootDepth"/>
					</li>
					<li>
						<label class="bk-modal__label">
							{{'Maximum Root Depth' | translate }}
						</label>
						<input class="bk-modal__field bk-form__input-text--float"
							type="number"
							name="MaxRootDepth"
							formControlName="MaxRootDepth"/>
					</li>
				</ul>
				
				<ng-container *ngIf="f.IsNDependent.value && !f.IsTree.value">
					<label class="bk-modal__label">
						{{'Root Depth Threshold' | translate }}
					</label>
					<input class="bk-modal__field bk-form__input-text--float"
						type="number"
						name="RootDepthThreshold"
						formControlName="RootDepthThreshold"/>
				</ng-container>
			</fieldset>

			<ng-container *ngIf="!f.IsTree.value">
				<label class="bk-modal__label"> <!--- May be used by 3rd party apps to choose crop type -->
					{{'Rows' | translate }}
				</label>
				<input class="bk-modal__field bk-form__input-text--float"
					type="number"
					name="Rows"
					formControlName="Rows"/>
				
				<label class="bk-modal__label"> <!--- BedWidth is not used in any calculation - but maybe used by 3rd party apps -->
					{{'Bed Width' | translate }}
				</label>
				<input class="bk-modal__field bk-form__input-text--float"
					type="number"
					name="BedWidth"
					formControlName="BedWidth"/>
			</ng-container>
		</div>

		<!-- FOOTER -->

		<div class="bk-form__footer modal-footer bk-modal__footer">
			<button class="bk-button bk-button--medium bk-button--text-only"
				(click)="goBack()">
				{{ 'Cancel' | translate }}
			</button>

			<button
				class="bk-button"
				[disabled]='!form.valid'
				(click)="update()">
				{{ 'Save' | translate }}
			</button>
		</div>
	</form>
</div>