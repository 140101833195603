import { Component, Inject, OnInit, ViewChild, OnDestroy, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Canopy } from './canopy';
import { CanopyTableComponent } from './canopy-table';

import { CanopyService } from './canopy.service';
import { KeyboardUtility } from '../../classes/keyboardUtility';

import { ICanopyData, ISimsGraphDataModel, ISimsGraphDataRecord } from './interfaces';
import { Subject } from 'rxjs';
import { IHighChartCustomSeries } from '../available-water-chart/interfaces';
import highChartExportOptions from '../shared/highchartsExportOptions';
import * as Highcharts from 'highcharts';
import { PlantingService } from '../planting-settings/service';
import { UpdateService } from '../../services/update.service';
import { RanchService } from '../ranch-settings/service';
import { NotificationService } from '../../services/notification.service';
import { eCommodityTypes, eNotificationTypes } from '../../interfaces/constants';
import { FormBuilder } from '@angular/forms';
import { eCommodityTypeCalculators } from '../../models/commodity-type/interfaces';
import { DateUtility } from '../../classes/dateUtility';

/**
 * Refactoring Log:
 * (8/16/2019) cleaned up private/public methods naming and ordering
 */
@Component({
	moduleId: module.id,
	selector: 'canopy-dialog',
	templateUrl: 'canopy-dialog.html',
	styleUrls: ['canopy-dialog.scss']
})

export class CanopyDialog implements OnInit, OnDestroy {
	@ViewChildren('highchartsContainer') private _highchartsContainers: QueryList<ElementRef>;
	private _highchartsContainer: ElementRef;
	public canopy: Canopy;
	private _canopyLatestInput: Canopy; // copy of canopy?
	private _timeoutId: number;
	private _latestInputInQueue: boolean;
	public hasCoordinates = true;
	public plantingId: number;
	public plantingName: string;
	public isLoaded = false;
	private _isProcessingCanopyData = false;
	public isSaving: boolean;
	private _chartData: Highcharts.IndividualSeriesOptions[];
	public defaultSetting: ICanopyData;
	public COMMODITY_TYPES = eCommodityTypes;
	public CALCULATORS = eCommodityTypeCalculators;
	private _highChartObject: Highcharts.ChartObject;

	@ViewChild(CanopyTableComponent, { static: false }) public canopyTable: CanopyTableComponent; // child component so must be public
	public commodityTypeCalculator: string;
	public commodityTypeId: number;

	private _subscriptions$: Subject<boolean> = new Subject();

	constructor(
		private _dialogRef: MatDialogRef<CanopyDialog>,

		@Inject(MAT_DIALOG_DATA) private _data: {
			plantingId: number,
			plantingName: string,
			commodityTypeId: number,
			commodityTypeCalculator: string
		},

		private _canopyService: CanopyService,
		private _dialog: MatDialog,
		private _fb: FormBuilder,
		private _notificationService: NotificationService,
		private _plantingService: PlantingService,
		private _ranchService: RanchService,
		private _updateService: UpdateService) {

		this.plantingId = this._data.plantingId;
		this.plantingName = this._data.plantingName;

		this.commodityTypeId = this._data.commodityTypeId;
		this.commodityTypeCalculator = this._data.commodityTypeCalculator;
	}

	ngOnInit(): void {

		this.canopy = new Canopy(this.commodityTypeId, this.commodityTypeCalculator,
			this._fb);

		this._canopyLatestInput = new Canopy(this.commodityTypeId, this.commodityTypeCalculator,
			this._fb); // user input cache for animated graph output

		this._isProcessingCanopyData = false;
		this._latestInputInQueue = false;

		this.isSaving = false;

		this._canopyService.simsData(this.plantingId)
		.then((data) => {
			if (!data) {
				return;
			}

			this.defaultSetting = {
				PlantingId: null,
				ranchGuid: null,
				CanopyGMax: data.CanopyParamsDefault.CanopyGMax,
				MaxFraction: data.CanopyParamsDefault.MaxFraction,
				CanopyA: data.CanopyParamsDefault.CanopyA,
				CanopyB: data.CanopyParamsDefault.CanopyB,
				CanopyE: data.CanopyParamsDefault.CanopyE,
				CanopyF: data.CanopyParamsDefault.CanopyF,
				CanopyMin: data.CanopyParamsDefault.CanopyMin,
				HasCoordinates: null
			};

			this.canopy.setData(data);

			if (this.canopy.isInvalid()) {
				return;
			}

			this.canopyTable.loadTableData();
			this._loadGraph(this.canopy);
			this.hasCoordinates = data.HasCoordinates;
		});
	}

	ngOnDestroy(): void {
		if (!this._subscriptions$) {
			return;
		}

		this._subscriptions$.next(true);
		this._subscriptions$.complete();
	}

	public close(): void {
		this._dialogRef.close();
	}

	/**
	 * This triggers when an entry is either added or removed from
	 * the canopy table
	 */
	public dataUpdated(): void {
		if (this.canopy.isInvalid()) {
			return;
		}

		this._loadGraph(this.canopy);
	}

	/**
	 * Method called by this component's template to only allow numbers
	 * @param e
	 */
	public preventNonNumbers(e: KeyboardEvent): void {
		KeyboardUtility.preventNonNumbers(e, true);
	}

	/**
	 *
	 * @param canopy if not specified, use this.canopy. This parameter is added to cache recent user inputs
	 * @returns void
	 */
	public reloadData(canopy: Canopy = null): void {
		const DELAY = 300; // ms debounce delay

		if (canopy && canopy.isInvalid()) {
			console.log('canopy is invalid');
		}

		if (!canopy) {
			canopy = this.canopy;
		}

		if (canopy.isInvalid()) {
			return;
		}

		if (this._isProcessingCanopyData) {
			let canopyData: ICanopyData;

			canopyData = canopy.form.value;

			this._canopyLatestInput.setData({
				PlantingId: this.canopy.PlantingId,
				RanchGuid: null,
				HasCoordinates: null,
				CanopyParams: {
					CanopyA: canopyData.CanopyA,
					CanopyB: canopyData.CanopyB,
					CanopyE: canopyData.CanopyE,
					CanopyF: canopyData.CanopyF,
					CanopyGMax: canopyData.CanopyGMax,
					CanopyMin: canopyData.CanopyMin,
					MaxFraction: canopyData.MaxFraction
				},
				CanopyParamsDefault: null
			});

			this._latestInputInQueue = true;

			return;
		}

		this._isProcessingCanopyData = true;

		if (this._timeoutId) {
			clearTimeout(this._timeoutId);
		}

		this._timeoutId = window.setTimeout(() => {
			this._reloadGraph(canopy);
			this._isProcessingCanopyData = false;

			if (this._latestInputInQueue) {
				this.reloadData(this._canopyLatestInput);
				this._latestInputInQueue = false;
			}
		}, DELAY);
	}

	public isRequiredField(field: string) {
		let control: AbstractControl;

		control = this.canopy.form.get(field);
		if (!control.validator) {
			return false;
		}

		const validator = control.validator({} as AbstractControl);
		return (validator && validator.required);
	}

	public reset(): void {
		this.canopy.resetForm(this.defaultSetting);
		this.reloadData();
	}

	public save(): void {
		let data: ICanopyData;

		data = this.canopy.getModelForPost();

		if (this.isSaving) {
			return;
		}

		this.isSaving = true;

		this._canopyService.update(data, this.plantingId)
		.then(() => {
			this.isSaving = false;
			this._dialogRef.close();
			this._recalculatePlanting();
		});
	}

	private _recalculatePlanting(): void {
		this._plantingService.recalculatePlanting(this.plantingId, true).then((res) => {
			if (!res || !res.Events) {
				this._notificationService.generateNotifcation({
					type: eNotificationTypes.ERROR,
					message: 'The planting recalculation failed'
				});

				return;
			} else {
				this._notificationService.generateNotifcation({
					type: eNotificationTypes.UPDATE,
					message: 'The planting was successfully recalculated'
				});
			}

			this._ranchService.plantings.replacePlantingEvents(res.Events, this.plantingId);
			this._updateService.setPlantingsUpdated(this._updateService.currentRanchId, new Date()); // update views if needed
		});
	}

	private _convertToGraphData(records: ISimsGraphDataModel): void {

		let temp: {
			cropManage: Highcharts.IndividualSeriesOptions,
			SIMS: Highcharts.IndividualSeriesOptions,
			user: Highcharts.IndividualSeriesOptions
		};

		this._chartData = [];

		if (!records) {
			return;
		}

		temp = {
			cropManage: {
				name: 'CropManage Canopy',
				visible: true,
				data: this._formatCanopyData(records.CropManageData),
				type: 'line'
			},
			SIMS: {
				name: 'SIMS Canopy',
				visible: true,
				data: this._formatCanopyData(records.SIMSCanopyData),
				type: 'scatter'
			},
			user: {
				name: 'User Data',
				visible: true,
				data: this._formatCanopyData(records.UserCanopyData),
				type: 'scatter'
			}
		}

		this._chartData.push(temp.cropManage);
		this._chartData.push(temp.SIMS);
		this._chartData.push(temp.user);
	}

	private _drawGraph(chart: ElementRef, data: IHighChartCustomSeries[]): void {

		let options: Highcharts.Options;

		if (!chart) {
			return;
		}

		options = {
			chart: {
			// type: 'line',
			},
			title: {
				text: null,
			},
			legend: {
				title: {
					text: null,
				}
			},
			tooltip: {
				backgroundColor: '#FFF',
				borderColor: '#FFF',
				valueDecimals: 2,
				valueSuffix: ' %',
				pointFormat: '{point.y}',
				headerFormat: '<span style="font-size: 10px">{point.x:%b %d, %Y}</span><br>'
			},
			xAxis: {
				type: 'datetime',
				gridLineWidth: 1
			},
			yAxis: [
				{
					title: {
						text: '%'
					}
				}
			],
			series: data,
			exporting: highChartExportOptions
		}

		this._highChartObject = Highcharts.chart(chart.nativeElement, options);
	}

	private _formatCanopyData(records: ISimsGraphDataRecord[]): [number, number] [] {
		let results: [number, number][];

		results = records.map(o => { return [DateUtility.DateToUTC(o.Date), o.Percentage];
		});

		return results;
	}

	private _reloadGraph(canopy: Canopy) {
		this._canopyService.canopyData(canopy).then((response) => {
			let series = this._formatCanopyData(response);

			this._highChartObject.series[0].update({
				data: series
				}, false);

			this._highChartObject.redraw();
			this.canopyTable.reloadTableCanopyData(response);
		});
	}

	private async _loadGraph(canopy: Canopy): Promise<void> {

		const response = await this._canopyService.simsGraphData(canopy);

		this._convertToGraphData(response);
		// we're having to do this because of the ng ifs that are cluttering
		// this template
		this._highchartsContainer = this._highchartsContainers.first;
		this._drawGraph(this._highchartsContainer, this._chartData);

		this.isLoaded = true;
	}
}
