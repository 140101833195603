import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ETWeatherEventService } from './service';
import { ETEventViewModel } from './interface';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PlantingRecalculatorService } from '../planting-recalculate-modal/plantingRecalculatorService';

@Component({
	moduleId: module.id,
	selector: 'et-chart',
	templateUrl: 'et-chart.html'
})

export class ETChartComponent implements OnInit, OnDestroy {

	public planting: {
		Id: number,
		Name: string
	};

	public etEvents: ETEventViewModel[];
	public tableHeaders: string[];
	public isLoaded = false;
	private _subscriptions$: Subject<boolean>;

	constructor(
		public dialogRef: MatDialogRef<ETChartComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: { lotPlantingId: number, name: string },
		private _service: ETWeatherEventService,
		private _plantingRecalculateService: PlantingRecalculatorService
	) {
	}

	ngOnInit(): void {
		this._subscriptions$ = new Subject();

		if (!this._data) {
			throw new Error('ETChartComponent.ngOnInit(): this._data is empty');
		}

		this.planting = {
			Id: this._data.lotPlantingId,
			Name: this._data.name
		};

		// custom click outside behavior
		this.dialogRef.disableClose = true;

		this.dialogRef.backdropClick().pipe(takeUntil(this._subscriptions$)).subscribe(() => {
			this.dialogRef.close();
		});

		this._list();
	}

	ngOnDestroy(): void {
		if (!this._subscriptions$) {
			return;
		}

		this._subscriptions$.next(true);
		this._subscriptions$.complete();
	}

	public save(etEvent: ETEventViewModel): void {

		if (etEvent.ET !== null && etEvent.ET !== undefined && etEvent.ET >= 0) {
			this._service.save(etEvent).then((res) => {
				this._plantingRecalculateService.processEventStream(res, this.planting.Id);
			});
		}
	}

	public cancel(): void {
		this.dialogRef.close();
	}

	private _list(): void {

		this._service.list(this.planting.Id).then((response) => {
			if (!response) {
				return;
			}

			this.etEvents = response.ETEvents;
			this.tableHeaders = response.Headers;
			this.isLoaded = true;
		});
	}
}
