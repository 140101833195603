<div class="bk-dialog">

	<button type="button"
		class="bk-button bk-dialog__close"
		(click)="close()">

		&times;
	</button>

	<h4 mat-dialog-title
		*ngIf='fertilizer'>
		{{ fertilizer.Name }} {{ 'Details' | translate }}
	</h4>

	<div mat-dialog-content
		*ngIf="fertilizer">
		
		<div class="cm-dialog__form-section">
			<div class="cm-dialog__label">
				{{'Fertilizer Name' | translate }}
			</div>
			<div class="cm-dialog__text-below-label--large">
				{{fertilizer.Name}}
			</div>
		</div>

		<div class="cm-dialog__form-section"
			*ngIf='fertilizer.FormulationType'>

			<div class="cm-dialog__label">
				{{'Formulation' | translate }}
			</div>
			<div class="cm-dialog__text-below-label--large">
				{{fertilizer.FormulationType.Name | translate }}
			</div>
		</div>
	
		<div class="cm-dialog__form-section"
			*ngIf='fertilizer.FormulationTypeId === formulationTypes.WET'>

			<div class="cm-dialog__label">
				{{'Fertilizer Density' | translate }}
			</div>

			<div class="cm-dialog__text-below-label--large"
				[ngClass]="{'bk-invalid': fertilizer.PoundsPerGallon === 0}">
				{{fertilizer.PoundsPerGallon}} {{'lbs' | translate}}/{{'gal' | translate}}
			</div>

			<div *ngIf="fertilizer.PoundsPerGallon === 0"
				class="bk-invalid">
				{{'Wet fertilizer lbs/gallon must be greater than zero' | translate }}
			</div>
		</div>
		
		<div class="cm-nutrients__section">
			<h4 class="bk-modal__section-title">
				{{'Nutrients' | translate }}
			</h4>
		
			<div class="cm-nutrients-list">
				<div class="cm-dialog__form-section cm-nutrients-list__col"
					*ngFor="let nutrient of fertilizer.Nutrients">

					<div class="cm-dialog__label">
						{{nutrient.Name | translate}}
					</div>
					<div class="cm-dialog__text-below-label">
						{{nutrient.Percentage}} %
					</div>
				</div>
			</div>
		
			<div [class.bk-text--error]="totalInvalid"
				class="bk-clearfix">
				<span class="cm-nutrients__percentage-label">
					{{'Total Nutrients' | translate }}:
				</span>
				<span class="cm-nutrients__percentage">
					{{nutrientTotalPercentage.total}}%
				</span>
			</div>
			<div *ngIf="nutrientTotalPercentage.validationError === validationErrors.TOTAL_TOO_BIG"
				class="bk-invalid-text--nutrient bk-invalid">
				{{'Total cannot exceed 100%' | translate }}
			</div>
			<div *ngIf="nutrientTotalPercentage.validationError === validationErrors.TOTAL_NOT_GREATER_THAN_ZERO"
				class="bk-invalid-text--nutrient bk-invalid">
				{{'Total must be greater than 0%' | translate }}
			</div>
		</div>
	</div>

	<div mat-dialog-actions align="end">

		<button *ngIf='!usage'
			class="bk-button bk-button--medium bk-dialog__button--secondary
			bk-button--text-only bk-button--float-left"
			(click)="delete()">
			{{'Remove Fertilizer' | translate }}
		</button>

		<button matt-button
			class="bk-button bk-button--medium bk-dialog__button--primary"
			(click)="close()">
			{{'Done' | translate }}
		</button>
	</div>
</div>