<div class="bk-dialog bk-dialog__event">

    <button type="button"
        class="bk-dialog__close"
        (click)="cancel()">

        &times;
    </button>

    <h4 mat-dialog-title>
        {{ 'Flowmeter Settings' | translate }}
    </h4>

    <div at-dialog-content>
		<form *ngIf="form"
			[formGroup]="form">

			<label class="bk-modal__label">
				{{'File Name' | translate }} ({{'Optional' | translate}})
			</label>
			<input class="bk-modal__field bk-input--full"
				type="text"
				formControlName="FlowMeterFileName"/>
			<div *ngIf="f.FlowMeterFileName.invalid && (f.FlowMeterFileName.dirty
				|| f.FlowMeterFileName.touched)"
				class="bk-invalid-text">
				<span *ngIf="f.FlowMeterFileName.errors.required">
					{{ 'Flowmeter filename is required' | translate}}.
				</span>
				<span *ngIf="f.FlowMeterFileName.errors.fileDoesNotExist">
					{{'Flowmeter filename not found on remote server' | translate }}.
				</span>
			</div>

			<div class="bk-modal__field-help">
				{{'Flow Meter File Text' | translate}}
			</div>

			<div>
				<div *ngIf="requiredMethods.sprinkler" 
					class="bk-modal__right-two-col bk-modal__right-two-col--left">

					<label class="bk-modal__label">
						{{'Sprinkler Flowmeter Area' | translate }} 
						<info-icon [title]="'Sprinkler Flowmeter Area Help Text'"></info-icon>
					</label>
					<input class="bk-modal__field--with-unit bk-input--short bk-modal__field"
						type="number"
						step="0.01"
						formControlName="FlowMeterArea"/>
					<div class="bk-modal__field-unit">
						{{'acres' | translate}}
					</div>
					<div *ngIf="f.FlowMeterArea.invalid && (f.FlowMeterArea.dirty || f.FlowMeterArea.touched)"
						class="bk-invalid-text">
						{{'Sprinkler Flowmeter Area must be between 0.01 and 999.99.' | translate }}
					</div>

					<label class="bk-modal__label">
						{{'Applied Water Threshold for Sprinkler Flowmeter' | translate }} 
						<info-icon [title]="'Applied Water Threshold for Sprinkler Flowmeter Help Text'"></info-icon>
					</label>
					<input class="bk-modal__field--with-unit bk-input--short bk-modal__field"
						type="number"
						step="0.01"
						formControlName="FlowMeterThresholdSprinkler"/>
					<div class="bk-modal__field-unit">
						{{'inches' | translate}}
					</div>
					<div *ngIf="f.FlowMeterThresholdSprinkler.invalid && (f.FlowMeterThresholdSprinkler.dirty
						|| f.FlowMeterThresholdSprinkler.touched)"
						class="bk-invalid-text">
						{{'Number must be between 0.01 and 9.99.' | translate }}
					</div>
				</div>

				<div *ngIf="requiredMethods.drip"
					class="bk-modal__right-two-col">
					<label class="bk-modal__label">
						{{'Drip Flowmeter Area' | translate }} 
						<info-icon [title]="'Drip Flowmeter Area Help Text'"></info-icon>
					</label>
					<input class="bk-modal__field--with-unit bk-input--short bk-modal__field"
						type="number" step="0.01"
						formControlName="DripFlowMeterArea"/>
					<div class="bk-modal__field-unit">
						{{'acres' | translate}}
					</div>
					<div *ngIf="f.DripFlowMeterArea.invalid && (f.DripFlowMeterArea.dirty
						|| f.DripFlowMeterArea.touched)"
						class="bk-invalid-text">
						{{'Drip Flowmeter Area must be between 0.01 and 999.99.' | translate }}
					</div>

					<label class="bk-modal__label">
						{{'Applied Water Threshold for Drip Flowmeter' | translate }} 
						<info-icon [title]="'Applied Water Threshold for Drip Flowmeter Help Text'"></info-icon>
					</label>
					<input class="bk-modal__field--with-unit bk-input--short bk-modal__field"
						type="number" step="0.01"
						formControlName="FlowMeterThresholdDrip"/>
					<div class="bk-modal__field-unit">
						{{'inches' | translate}}
					</div>
					<div *ngIf="f.FlowMeterThresholdDrip.invalid && (f.FlowMeterThresholdDrip.dirty
						|| f.FlowMeterThresholdDrip.touched)"
						class="bk-invalid-text">
						{{'Drip Flowmeter Threshold must be between 0.01 and 999.99.' | translate }}
					</div>
				</div>
				
				<div *ngIf='requiredMethods.microSprinkler'
					class="bk-modal__right-two-col bk-modal__right-two-col--left">
					<label class="bk-modal__label">
						{{'Micro Sprinkler Flowmeter Area' | translate }} 
						<info-icon [title]="'Micro Sprinkler Flowmeter Area Help Text'"></info-icon>
					</label>
					<input class="bk-modal__field--with-unit bk-input--short bk-modal__field"
						type="number" step="0.01"
						formControlName="MicroSprinklerFlowMeterArea"/>
					<div class="bk-modal__field-unit">
						{{'acres' | translate}}
					</div>
					<div *ngIf="f.MicroSprinklerFlowMeterArea.invalid && (f.MicroSprinklerFlowMeterArea.dirty
						|| f.MicroSprinklerFlowMeterArea.touched)"
						class="bk-invalid-text">
						{{'Micro Sprinkler Flowmeter Area must be between 0.01 and 999.99.' | translate }}
					</div>

					<label class="bk-modal__label">
						{{'Applied Water Threshold for Micro Sprinkler Flowmeter' | translate }} 
						<info-icon [title]="'Micro Applied Water Threshold for Sprinkler Flowmeter Help Text'"></info-icon>
					</label>
					<input class="bk-modal__field--with-unit bk-input--short bk-modal__field"
						type="number"
						step="0.01"
						formControlName="FlowMeterThresholdMicroSprinkler"/>
					<div class="bk-modal__field-unit">
						{{'inches' | translate}}
					</div>
					<div *ngIf="f.FlowMeterThresholdMicroSprinkler.invalid &&
						(f.FlowMeterThresholdMicroSprinkler.dirty
						|| f.FlowMeterThresholdMicroSprinkler.touched)"
						class="bk-invalid-text">
						{{'Number must be between 0.01 and 9.99.' | translate }}
					</div>
				</div>
			</div>

			<label class="bk-modal__label">
				{{'Initial Irrigation Method' | translate }}
			</label>
			<p>
				{{'Initial Irrigation Method Explanation' | translate}}
			</p>
			<ng-container *ngIf="irrigationMethodOptions.length > 1">
				<mat-form-field  appearance="outline"
					class="cm-outline-input mat-form-field--one-line mat-form-field--short">
					<mat-select
						required
						formControlName="FlowmeterIrrigationMethodId">

						<mat-option *ngFor="let option of irrigationMethodOptions"
							[value]="option.Id">
							{{ option.Text | translate }}
						</mat-option>

					</mat-select>

				</mat-form-field>
			</ng-container>
		</form>
    </div>

    <div mat-dialog-actions align="end" class="bk-clearfix">

        <button mat-button
			class="bk-button bk-button--medium bk-button--text-only
				bk-dialog__button--secondary"
            (click)="cancel()">
            {{ 'Cancel' | translate }}
        </button>

		<button mat-button
			[disabled]="!form || form.invalid"
            class="bk-button bk-button--medium bk-dialog__button--primary"
            (click)="save()">
            {{ (isSaving ? 'Saving' : 'Save') | translate }}
            <img class='bk-button__loader' *ngIf='isSaving' src='/assets/images/loader-20px.gif'/>
        </button>
    </div>
</div>