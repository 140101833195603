import { Injectable } from '@angular/core';

import { ModelService } from '../../services/model.service';

import { ISeasonalKC } from '../seasonal-kc/interfaces';
import { ICropType, ICropTypeViewModel, ICropTypeListItem, ICropTypeService } from './interfaces';
import { CropType } from './cropType';
import { of } from 'rxjs';
import { CMError } from '../../interfaces/interfaces';
import { HttpParams } from '@angular/common/http';
import { ISuccessResponse } from '../../components/ranch-settings/interfaces';
import { HttpServicePostOptions } from '../../services/http.service';

@Injectable()
export class CropTypeService extends ModelService implements ICropTypeService {

	private urls = {
		get: (cropTypeId: number) => `/v2/crop-types/${cropTypeId}.json`,
		listByRanch: (guid: string, commodityTypeId: number) => `/v2/ranches/${guid}/commodity-types/${commodityTypeId}/crop-types.json`,
		admin: {
			list: `/v2/crop-types.json`,
			getCreateModel: `/v2/crop-types/form.json`,
			getCloneModel: (cropTypeId: number) => `/v2/crop-types/${cropTypeId}/clone.json`,
			save: `/v2/crop-types.json`,
			delete: (cropTypeId: number) => `/v2/crop-types/${cropTypeId}.json`
		}
	};

	/**
	 * Get crop type list for admin section
	 * @param id
	 */
	public getCropTypesAdmin(includePrevious: boolean, commodityTypeId?: number): Promise<ICropTypeListItem[]> {
		let params: HttpParams;

		params = new HttpParams().set('includePrevious', includePrevious ? 'true' : 'false');

		if (commodityTypeId) {
			params = params.set('commodityTypeId', commodityTypeId.toString());
		}

		return this.get(this.urls.admin.list,
			(response: ICropTypeListItem[]) => {
				return response;
			},
			params);
	}

	public getCreateModel(): Promise<ICropTypeViewModel> {

		return this.get(this.urls.admin.getCreateModel,
			(response: ICropTypeViewModel) => {

			return response;
		});
	}

	/**
	 * Get Edit model for admin section
	 */
	public getEditModel(id: number): Promise<ICropTypeViewModel> {

		if (!id) {
			return of(null).toPromise();
		}

		return this.get(this.urls.get(id),
			(response: ICropTypeViewModel): ICropTypeViewModel => {

			return response;
		});
	}

	public getCloneModel(id: number): Promise<ICropTypeViewModel> {

		if (!id) {
			return of(null).toPromise();
		}

		return this.get(this.urls.admin.getCloneModel(id),
			(response: ICropTypeViewModel): ICropTypeViewModel => {

			return response;
		});
	}

	/** Save crop type data */
	public update(model: ICropTypeViewModel): Promise<ICropTypeViewModel | CMError> {
		if (!model.Id) {
			throw new Error('crop Id is missing');
		}

		return this.put(this.urls.admin.save,
			(response: ICropTypeViewModel | CMError ): ICropTypeViewModel | CMError  => {
				return response;
			}, model);
	}

	public create(model: ICropTypeViewModel): Promise<ICropTypeViewModel | CMError> {
		if (model.Id) {
			throw new Error('crop Id should not be defined');
		}

		return this.post(this.urls.admin.save,
			(response: ICropTypeViewModel | CMError ): ICropTypeViewModel | CMError  => {
				return response;
			}, model);
	}



	public delete(cropTypeId: number): Promise<ISuccessResponse | CMError> {
		let options: HttpServicePostOptions;

		options = {
			url: this.urls.admin.delete(cropTypeId),
			body: {},
			isWebAPI: true,
			shouldBypassServerErrorAlert: true
		}

		return this.httpService.delete(options);
	}

	public getCropModel(id: number): Promise<ICropTypeViewModel> {

		return this.get(this.urls.get(id), (data: ICropTypeViewModel): ICropTypeViewModel => {
			if (!data.SeasonalKCs || !data.SeasonalKCs.length) {
				return data;
			}

			// remove "invalid" months (less than 1 or greater than 12)
			data.SeasonalKCs = (data.SeasonalKCs as ISeasonalKC[]).filter(x => x.Month > 0 && x.Month < 13);

			return data;
		});
	}

	/**
	 * Returns a list of crop types associated with a commodity type for a particular ranch
	 * @param ranchGuid
	 * @param commodityTypeId
	 * @returns
	 */
	public getByRanch(ranchGuid: string, commodityTypeId: number): Promise<ICropType[]> {

		let params: HttpParams;

		if (!commodityTypeId) {
			return of(null).toPromise();
		}

		params = new HttpParams().set('shouldFilterUnassociated', 'true');

		return this.get(this.urls.listByRanch(ranchGuid, commodityTypeId),
			(cropTypes: ICropType[]) => {
				return cropTypes;
			}, params);
	}

	public getAllByRanch(ranchGuid: string, commodityTypeId: number): Promise<ICropType[]> {

		let params: HttpParams;

		params = new HttpParams().set('shouldFilterUnassociated', 'false');

		return this.get(this.urls.listByRanch(ranchGuid, commodityTypeId),
			(cropTypes: ICropType[]) => {
				CropType.autoSelectCropTypes(cropTypes);
				return cropTypes;
			}, params);
	}
}
