import { Component, ViewChild, OnInit, OnDestroy, Inject, ChangeDetectorRef } from '@angular/core';
import { RanchService } from '../ranch-settings/service';
import { UpdateService } from '../../services/update.service';
import { ValidateService } from '../../services/validate.service';
import { DateUtility } from '../../classes/dateUtility';
import { IrrigationEvent, IUnitRatios } from './irrigationEvent';
import { IrrigationService } from './service';

import { IIrrigationRecommendationSummaryModel, IIrrigationEventEditResponse,
	IIrrigationIntervalRecommendation, IIrrigationEventGetRecency, IRRIGATION_INTERVAL_MAX, IIrrigationEvent, IAverageDeficit, IETEvent }
	from './interfaces';

import { IPlanting } from '../planting-settings/interfaces';
import { IEventGroup, IRawEvent, IEventPostResponseJSON } from '../../models/event/interfaces';
import { IIrrigationMethod } from '../../models/irrigation-method/interfaces';
import { eIrrigationMethods, eCommodityTypes, EventsType, eNotificationTypes } from '../../interfaces/constants';
import { EventGroup } from '../../models/event/event';
import { HistoricalUIComponent } from './historical-ui/historical-ui';
import { Subject, of, from, timer } from 'rxjs';
import {  MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CropType } from '../../models/crop-type/cropType';
import { switchMap, takeUntil } from 'rxjs/operators';
import { IrrigationDeleteConfirmationDialog } from './delete-confirmation';
import { IrrigationUpdateService } from './update.service';
import { SharedKeepDiscardDialog } from '../shared/dialogs/keep-discard-dialog';
import { SharedUpdateService } from '../shared/dialogs/update.service';
import { IrrigationEventRecalculateWarningDialog } from './recalculate-warning';
import { AbstractControl, FormGroup, FormBuilder, Validators, AsyncValidatorFn } from '@angular/forms';
import * as moment from 'moment';
import { FormValidation } from '../../classes/formValidation';
import { NotificationService } from '../../services/notification.service';
import { IrrigationEventUpdateParams } from './irrigationEventUpdateParams';
import { animate, style, transition, trigger } from '@angular/animations';
import { PlantingRecalculatorService } from '../planting-recalculate-modal/plantingRecalculatorService';
import { TranslateService } from '../localization/service';
import { DIALOG_HIDE_CLASS } from '../shared/constants';

export type IrrigationEventFields = 'Id' | 'EventDate' | 'IrrigationMethodId' |
	'ManagerAmountRecommendation' | 'ManagerAmountRecommendationHours' |
	'WaterApplied' | 'WaterAppliedHours' | 'Rainfall' | 'CustomDeficit' |
	'IsCustomDeficitEnabled';

export enum eIrrigationUnits {
	INCHES = 'inches',
	HOURS = 'hours'
}

export interface IFlowmeterSetting {
	hasData: boolean,
	gallons: number,
	calculatedArea: number,
	waterApplied: number,
	areas: {
		drip: number,
		microSprinkler: number,
		default: number
	}
}

@Component({
	moduleId: module.id,
	selector: 'irrigation-event',
	templateUrl: 'main.html',
	styleUrls: [ 'main.scss'],
	animations: [
		trigger('slideInOut', [ // animation for showing/hiding Cover UI
			transition(':enter', [
				style({ 'max-height': '0px', opacity: 0 }),
				animate('800ms ease-in-out', style({ 'max-height': '*', opacity: 1 }))
			]),
			transition(':leave', [
				animate('800ms ease-in-out', style({ 'max-height': '0px', opacity: 0 }))
			])
		])
	]
})

export class IrrigationEventComponent implements OnInit, OnDestroy {

	public COMMODITY_TYPES = eCommodityTypes;

	public datePickerOpen = false;
	private _effectedEvents: IEventGroup[] = new Array();

	public commodityCalculator: number;
	public averageDeficit: IAverageDeficit;
	public historicalEventSaved$: Subject<void> = new Subject<void>();
	public IRRIGATION_METHODS = eIrrigationMethods;
	public irrigationRecommendationDetails: IIrrigationRecommendationSummaryModel;
	public isFetchingRecommendation = false; // used to control view when recommendation is being calculated
	public isFloodEvent: boolean;
	public isFuture = false;
	public isHistoricalIrrigationDatePickerOpen: boolean;
	public isHistoricalUIVisible: boolean;
	public isOnOrBeforeWetDate: boolean;
	public isSaving = false; // flag to display in the UI when the event is being saved
	public isRecommendationVisible = false;
	public isWarningSectionVisible: boolean;
	public irrigationMethods: IIrrigationMethod[];

	public flowmeter: IFlowmeterSetting;
	public CIMISPrecipitation: number;
	public lastUpdatedString: string;

	public loadingData = true; // prevent displaying the popup from
	// showing until all content has loaded - otherwise the popup oddly
	// morphs in place as more data is loaded

	public managerInterval: number; // this was previously referenced by the template but is currently
		// not used
	public maxIrrigationInterval = IRRIGATION_INTERVAL_MAX;
	public warningTextRecommendation = ''; // may display next to recommendation #

	public planting: IPlanting;

	public plantingId: number;
	public eventId: number;
	public recentCutEventExists: boolean;
	public recentIrrigationEventExists: boolean;
	public isPerennial: boolean;
	public isTree: boolean;
	public recommendationSummaryVisible = false;
	public units = eIrrigationUnits.HOURS;
	public lastIrrigationEventDate: Date;
	public maxDate: Date; // maximum valid date for EventDate
	public irrigationEvent: IrrigationEvent; // used for displaying warning text

	public recommendation: {
		inches: number,
		hours: number
	};

	/// private properties

	private _amountFieldNames = {
		hours: {
			recommended: 'RecommendedHours',
			manager: 'ManagerHours',
			applied: 'AppliedHours'
		},
		inches: {
			manager: 'ManagerAmountRecommendation',
			applied: 'WaterApplied',
			recommended: 'RecommendedIrrigationAmount'
		}
	}

	private _applicationRate: number;
	private _subscriptions$: Subject<boolean>;
	private _dateDefault: Date; // default date set when an event is created from the calendar

	@ViewChild(HistoricalUIComponent, { static: false }) private _historicalUIComponent: HistoricalUIComponent;

	public form: FormGroup;

	public get f(): { [key in IrrigationEventFields]: AbstractControl } {
		return this.form ? this.form.controls as { [key in IrrigationEventFields]: AbstractControl } : null;
	}

	//////////////////////////

	constructor(
		private _fb: FormBuilder,
		@Inject(MAT_DIALOG_DATA) public data: { plantingId: number,
			id?: number, dateDefault?: Date },
		private _cdr: ChangeDetectorRef,
		private _dialog: MatDialog,
		private dialogRef: MatDialogRef<IrrigationEventComponent>,
		private _irrigationService: IrrigationService,
		private _irrigationUpdateService: IrrigationUpdateService,
		private _notificationService: NotificationService,
		private _plantingRecalculateService: PlantingRecalculatorService,
		private _ranchService: RanchService,
		private _sharedUpdateService: SharedUpdateService,
		private _updateService: UpdateService,
		private _translateService: TranslateService,
		private _validateService: ValidateService
		) {
			this.plantingId = data.plantingId;
			this.eventId = data.id;
			this._dateDefault = data.dateDefault;
	}

	public ngOnInit(): void {
		this._cdr.detectChanges(); // force ngIf to evaluate early so spinner is
			// visible during loading

		this.irrigationEvent = new IrrigationEvent(this._translateService);
		this._subscriptions$ = new Subject();
		this.CIMISPrecipitation = 0;

		this.averageDeficit = {
			Days: null,
			Deficit: null
		};

		this.flowmeter = {
			hasData: false,
			gallons: null,
			calculatedArea: null,
			waterApplied: null,
			areas: {
				default: null,
				drip: null,
				microSprinkler: null
			}
		};

		this.recommendation = {
			inches: null,
			hours: null
		}

		this._initialize();
		this.planting = this._ranchService.plantings.getPlantingById(this.plantingId);

		if (this.planting) {
			this.isPerennial = CropType.isPerennialPlanting(this.planting.CommodityTypeId);
			this.isTree = CropType.isTree(this.planting.CommodityTypeId);
		}

		if (this.eventId) {
			this.isRecommendationVisible = true;

			this._getEditModel(this.eventId);
		} else {
			this.isRecommendationVisible = false;
			this._getCreateModel();
		}

		// ping this when historical event is saved
		this.historicalEventSaved$
			.pipe(takeUntil(this._subscriptions$)).subscribe(() => {

			this._getRecommendation(); // refresh recommendation
			this.cancelHistoricalIrrigation();
			this.recentIrrigationEventExists = true;

			this.isWarningSectionVisible = this._isWarningVisible(this.recentIrrigationEventExists,
				this.recentCutEventExists);
		});

		this._irrigationUpdateService.confirmationClosed$.pipe(takeUntil(this._subscriptions$)).subscribe(() => {
			this._show();
		});

		this._irrigationUpdateService.deleted$
			.pipe(takeUntil(this._subscriptions$)).subscribe((response) => {

			this._delete(response);
		});

		this._irrigationUpdateService.recalculateWarningClosed$
			.pipe(takeUntil(this._subscriptions$)).subscribe((event) => {

			this._confirmIrrigationWarning(event);
		});

		this._sharedUpdateService.keep$
			.pipe(takeUntil(this._subscriptions$)).subscribe((response) => {

			this._show();
		});

		this._sharedUpdateService.discard$
			.pipe(takeUntil(this._subscriptions$)).subscribe((response) => {

			this.close();
		});
	}

	ngOnDestroy(): void {
		if (!this._subscriptions$) {
			return;
		}

		this._subscriptions$.next(true);
		this._subscriptions$.complete();
	}

	public calculateRecommendation(): boolean {

		if (!this.form.valid) {
			return false;
		}

		this.isFetchingRecommendation = true;

		this._getRecommendation((res: IIrrigationRecommendationSummaryModel) => {
			this.isFetchingRecommendation = false;
			this.isRecommendationVisible = true;

			if (!res) {
				return;
			}

			this.recentCutEventExists = res.HasRecentCutEvents;
			this.recentIrrigationEventExists = res.HasRecentIrrigationEvent;

			this.isWarningSectionVisible = this._isWarningVisible(this.recentIrrigationEventExists,
				this.recentCutEventExists);

			this.warningTextRecommendation = this.irrigationEvent.getWarningTextRecommendation(
				this.isFloodEvent, this.units, this.recentCutEventExists,
				this.planting.CommodityTypeId,
				res.DaysSinceLastIrrigation,
				res.ValidRainEventsCount, this._countZeroETRecords(res.ETEvents), this.isFuture);
		});
	}

	public cancelClose(e: MouseEvent): void {
		e.stopPropagation();
		e.preventDefault();
	}

	public cancelHistoricalIrrigation(): void {
		this.isHistoricalUIVisible = false;
		this.f.EventDate.enable({emitEvent: false});
		this.f.IrrigationMethodId.enable({emitEvent: false});
		this.isHistoricalIrrigationDatePickerOpen = null;
	}

	/**
     * Controller for "Discard Changes" button
     */
	public close(): void {
		this.dialogRef.close();
		this._updateService.setEventDialogClosed(); // tell event table to re-display
	}

	public create(): void {
		let param: IrrigationEventUpdateParams;

		param = new IrrigationEventUpdateParams(this.form.value as IIrrigationEventEditResponse);

		if (this.isSaving) { // prevent multiple submissions
			return;
		}

		this.isSaving = true;

		this._irrigationService.create(this.plantingId, param)
			.then(res => {

				let eventGroup: EventGroup;

				this.isSaving = false;

				eventGroup = EventGroup.convertFromJSON(res, this.planting.WetDate,
					this.planting.Id, this._ranchService);

				if (!eventGroup) {
					return;
				}

				this.f.Id.setValue(res.Events[0].Irrigation.Id);

				this.recentCutEventExists = res.Events[0].Irrigation.HasRecentCutEvent;
				this.recentIrrigationEventExists = res.Events[0].Irrigation.HasRecentIrrigationEvent;

				this.isWarningSectionVisible = this._isWarningVisible(this.recentIrrigationEventExists,
					this.recentCutEventExists);

				this._confirmIrrigationWarning();

				this._plantingRecalculateService.recalculate(this.plantingId,
					true);
			});
	}

	/*put everyting related to the closing the modal in here*/
	public initiateCloseModal(): void {
		if (this.form.touched) {
			this._showKeepDiscard();
			return;
		}

		this.close();
	}

	public isGerminationEvent(): boolean {
		return this.f.IrrigationMethodId.value === eIrrigationMethods.GERMINATION_SPRINKLER;
	}

	/**
     * Deal with popup content area height when historical UI calendar is open.
     *
     * If we don't do this, when calendar is open, the bottom of the calendar is truncated behind
     * popup footer
     *
     * @param event
     */
	public onDatePickerToggle(event: number): void {
		if (event === 1) {
			this.datePickerOpen = true;
		} else {
			this.datePickerOpen = false;
		}
	}

	public onHistoricalCalendarToggle($event: boolean) {
		this.isHistoricalIrrigationDatePickerOpen = $event;
	}

	public saveHistoricalIrrigation() {
		this._historicalUIComponent.save();
	}

	public setApplied(field: string, value: number): void {

		switch (field) {
			case this._amountFieldNames.hours.applied:
				this._updateInches(field, value);
				break;
			case this._amountFieldNames.inches.applied:
				this._updateHours(field, value);
				break;
		}
	}

	/**
     *
     * @param field the field the value belongs to, not the target field we want to update
     * @param value
     */
	public setManagerRec(field: string, value: number): void {

		switch (field) {
			case this._amountFieldNames.hours.manager:
				this.f.ManagerAmountRecommendationHours.setValue(value);
				this._updateInches(field, value);
				break;
			case this._amountFieldNames.inches.manager:
				this.f.ManagerAmountRecommendation.setValue(value);
				this._updateHours(field, value);
				break;
		}
	}

	/**
	 * Display delete confirmation dialog
	 * @param $event
	 */
	public showDelete($event: MouseEvent): void {
		this._dialog.open(IrrigationDeleteConfirmationDialog, {
			width: '500px',
			disableClose: true,
			data: {
				id: this.f.Id.value,
			}
		});

		this._hide();

		$event.preventDefault();
		$event.stopPropagation();
	}

	private _hide(): void {
		this.dialogRef.addPanelClass(DIALOG_HIDE_CLASS);
	}

	private _show(): void {
		this.dialogRef.removePanelClass(DIALOG_HIDE_CLASS);
	}

	public showHistoricalUI() {
		this.isHistoricalUIVisible = true;
		this.f.EventDate.disable({emitEvent: false});
		this.f.IrrigationMethodId.disable({emitEvent: false});
	}

	public showRecommendationSummary(): void {

		if (!this.recommendationSummaryVisible) {
			this.recommendationSummaryVisible = true;
		} else {
			this.recommendationSummaryVisible = false;
		}
	}

	public toggleUnits(units: string): void {
		switch (units) {
			case eIrrigationUnits.HOURS:
				this.units = eIrrigationUnits.HOURS;
				break;
			case eIrrigationUnits.INCHES:
				this.units = eIrrigationUnits.INCHES;
				break;
		}
	}

	public update(): void {

		let event: IIrrigationEventEditResponse;

		if (!this.form.valid) {
			return;
		}

		event = this.form.value;
		event.RecommendationSummary = this.irrigationRecommendationDetails;

		if (event.RecommendationSummary) {
			event.RecommendationSummary.RecommendedIrrigationAmount = this.recommendation.inches;
		}

		if (this.isSaving) {
			// prevent this button from getting clicked twice
			return;
		}

		this.isSaving = true;

		this._irrigationService.update(event)
			.then((res) => {

				this.isSaving = false;

				if (!res) {
					return;
				}

				this._checkFollowingEventsEffected(res.SucceedingEventGroupsRaw, this.planting.WetDate);
				this._plantingRecalculateService.recalculate(this.plantingId, true);
				this.close();
			});
	}

	private _checkFollowingEventsEffected(succeedingEventGroups: IRawEvent[], wetDate: Date): void {
		if (succeedingEventGroups === null || succeedingEventGroups.length === 0) {
			return;
		}

		this._effectedEvents = EventGroup.convertAll(succeedingEventGroups, wetDate);
		this._showIrrigationWarning(this._effectedEvents);
	}

	private _checkForFlowmeterData(): void {

		if (!this.flowmeter.hasData) {
			return;
		}

		if (ValidateService.numberIsEmpty(this.flowmeter.gallons)) {

			return;
		}

		this.flowmeter.calculatedArea = IrrigationEvent.
			getFlowMeterArea(this.f.IrrigationMethodId.value, this.flowmeter);

		this.flowmeter.waterApplied =
			this._validateService.convertToFloat(((this.flowmeter.gallons * 0.0000368265993)
				/ this.flowmeter.calculatedArea), 2);

		if (ValidateService.numberIsEmpty(this.f.WaterApplied.value)) {
			this.f.WaterApplied.setValue(this.flowmeter.waterApplied);
			this._updateHours(this._amountFieldNames.inches.applied, this.f.WaterApplied.value);
		}
	}

	private _initialize(): void {
		this.isFuture = false;
		this.recommendationSummaryVisible = false;
		this.irrigationRecommendationDetails = null;
		this.isFetchingRecommendation = false;

		this._effectedEvents = new Array();
		this.units = eIrrigationUnits.HOURS;
		this.loadingData = true;
		this.isFloodEvent = null;
		this.isHistoricalUIVisible = null;
		this.isHistoricalIrrigationDatePickerOpen = null;

		this.isWarningSectionVisible = false;
		this.recentIrrigationEventExists = false;
		this.recentCutEventExists = false;
		this.isOnOrBeforeWetDate = false;
	}

	private _confirmIrrigationWarning(e?: MouseEvent): void {

		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}

		if (this.isHistoricalUIVisible) {
			this.isFetchingRecommendation = false;
			return;
		}

		this.close();
	}

	private _delete(response: IEventPostResponseJSON): void {

		if (!response) {
			return;
		}

		EventGroup.updateEvents(response.EventGroupsSucceeding,
			this.plantingId,
			this.planting.WetDate, this._ranchService);

		this._checkFollowingEventsEffected(response.EventGroupsSucceeding,
			this.planting.WetDate); // if any events are affected, display warning

		this._ranchService.plantings.deleteEventInPlantings(this.f.EventDate.value,
			this.plantingId, EventsType.WATER, null, response.ActiveEvents); // this line hides the warning before it shows

		this._updateService.setPlantingsUpdated(this._updateService.currentRanchId, new Date());

		this._plantingRecalculateService.recalculate(this.plantingId, true);
		this.close();
	}

	private _getCreateModel(): void {

		if (!this.plantingId) {
			return;
		}

		this._irrigationService.getCreateModel(this.plantingId)
			.then((res) => {
				let d: Date;

				if (!res) {
					return;
				}

				if (this._dateDefault) {
					res.EventDate = this._dateDefault;
				} else {
					res.EventDate = null;
				}

				this._initializeForm(res);
				this.irrigationMethods = res.IrrigationMethods;
				this.loadingData = false;
				this._bindValueChanges();

				if (this._dateDefault) {
					this._onDateChanged();
				}
			});
	}

	/**
	 *
	 * @param id
	 * @param isEditMode if true, assume popup is being opened for the first time, in edit mode
	 * if false, assume this is called after event is initially created
	 */
	private _getEditModel(id: number): void {
		this._irrigationService.getEditModel(id)
			.then((res) => {
				this._processEditGetResponse(res);

				this.loadingData = false;
			});
	}

	private _getPreviousIrrigationDate(daysSinceLastIrrigation: number, eventDate: Date): Date {
		let result: Date;

		if (!eventDate) {
			return null;
		}

		result = DateUtility.subtractDays(eventDate, daysSinceLastIrrigation);

		return result;
	}

	/**
	 * Called once during load
	 */
	private _getHoursValues(): void {

		if (!this.f.WaterAppliedHours.value) {
			this._updateHours(this._amountFieldNames.inches.applied, this.f.WaterApplied.value);
		}

		if (!this.f.ManagerAmountRecommendationHours.value) {
			this._updateHours(this._amountFieldNames.inches.manager,
				this.f.ManagerAmountRecommendation.value);
		}

		this._updateHours(this._amountFieldNames.inches.recommended,
			this.recommendation.inches);
	}

	private _getRecommendation(callback?: Function): Promise<void> {
		let intervalRecommendation: IIrrigationIntervalRecommendation;
		let minimumDelay = 1000; // 1 second minimum delay, to prevent flash
		let delayExpired = false;
		let dataReceived = false;

		if (!this.f) {
			return of(null).toPromise();
		} else if (this.f.EventDate.invalid) {
			return of(null).toPromise();
		}

		this.isFetchingRecommendation = true;
		this.isRecommendationVisible = false;

		setTimeout(() => {
			delayExpired = true;

			if (dataReceived) {
				this.isFetchingRecommendation = false;
				this.isRecommendationVisible = true;
			}
		}, minimumDelay);

		return this._irrigationService.getRecommendationSummary(Number(this.plantingId), this.f.EventDate.value,
			this.f.IrrigationMethodId.value, this.f.Id.value, this.f.CustomDeficit.value)
			.then(res => {
				dataReceived = true;

				if (!res) {
					return;
				}

				if (delayExpired) {
					this.isFetchingRecommendation = false;
					this.isRecommendationVisible = true;
				}

				if (!res || res.RecommendedIrrigationAmount === -1) {
					this.irrigationRecommendationDetails = null;
					this.recommendationSummaryVisible = false;
				} else {
					this.irrigationRecommendationDetails = res;
					this._setCropManageRecommendation(res.RecommendedIrrigationAmount, res.applicationRate);

					this.f.Rainfall.setValue(this._validateService.convertToFloat(res.TotalPrecipitation, 2));
					this.lastIrrigationEventDate = res.LastIrrigationEventDate;
				}

				this._applicationRate = res.applicationRate;

				intervalRecommendation = IrrigationEvent.getIntervalRecommendation(this.lastIrrigationEventDate,
					res.RecommendedIrrigationInterval);

				if (callback) {
					callback(res);
				}
			});
	}

	private _initializeForm(model: IIrrigationEventEditResponse): void {
		this.form = this._fb.group({
			Id: [model.Id],

			EventDate: [model.EventDate, {
				validators: [ Validators.required,
					FormValidation.maxDateValidator(this.planting ? this.planting.HarvestDate : null)],
				asyncValidators: [this._duplicateDateValidator()],
				updateOn: 'blur'
			}],
			IrrigationMethodId: [model.IrrigationMethodId, [Validators.required, Validators.min(1)]],
			ManagerAmountRecommendation: [model.ManagerAmountRecommendation, Validators.min(0)],
			ManagerAmountRecommendationHours: [model.ManagerAmountRecommendationHours, Validators.min(0)],
			WaterApplied: [model.WaterApplied, Validators.min(0)],
			WaterAppliedHours: [model.WaterAppliedHours, Validators.min(0)],
			Rainfall: [model.Rainfall, Validators.min(0)],
			IsCustomDeficitEnabled: [model.IsCustomDeficitEnabled ?
				model.IsCustomDeficitEnabled : false],
			CustomDeficit: [model.CustomDeficit, {
				validators: [ Validators.min(0), Validators.max(100)],
				updateOn: 'blur'
			}],
		});

		if (this.planting) {
			this.maxDate = moment(this.planting.HarvestDate).subtract(1, 'days').toDate();
		}
	}

	private _duplicateDateValidator(): AsyncValidatorFn {
		return (control: AbstractControl): Observable<{[key: string]: any} | null> => {
			return timer(300).pipe( // add a debounce to prevent too many requests
				switchMap(() => {
					return from(this._irrigationService.countDuplicatesOnDate(this.planting.Id, control.value).then((count) => {
						let valid: boolean;

						if (this.eventId) {
							valid = count > 1 ? false : true;
						} else {
							valid = count > 0 ? false : true;
						}

						if (!valid) {
							control.markAsTouched();
						}

						return valid ? null : {'hasDuplicates': {value: control.value}};
					}));
				})
			);
		};
	}

	private _bindValueChanges() {
		this.f.EventDate.valueChanges.pipe(takeUntil(this._subscriptions$)).subscribe(() => {
			this._onDateChanged();
		});

		this.f.IrrigationMethodId.valueChanges.pipe(takeUntil(this._subscriptions$)).subscribe(() => {
			this._selectIrrigationMethod();
		});

		this.f.CustomDeficit.valueChanges.pipe(takeUntil(this._subscriptions$)).subscribe(() => {
			if (this.isRecommendationVisible) {
				this._getRecommendation();
			}
		});

		this.f.IsCustomDeficitEnabled.valueChanges.pipe(takeUntil(this._subscriptions$)).subscribe(() => {
			if (this.f.IsCustomDeficitEnabled.value === false) {
				this.f.CustomDeficit.setValue(null);
			}
		});
	}

	/**
     * Warning section will display if either 1) recent irrigation event doesn't
     * exist or 2) recent cut event doesn't exist.
     *
     * @param recentIrrigationEventExists
     */
	private _isWarningVisible(recentIrrigationEventExists: boolean, recentCutEventExists: boolean): boolean {
		let isBeforeWetDate: boolean;

		if (!this.planting) {
			return;
		}

		if (this.f.IrrigationMethodId.value === eIrrigationMethods.RAINFALL) {
			// we don't show warning for rainfall events
			return;
		}

		isBeforeWetDate = DateUtility.isOnOrBefore(this.f.EventDate.value, this.planting.WetDate);
		// this.event.EventDate
		if (isBeforeWetDate) { // we don't care about recommendations before wet date
			return false;
		}

		if (!this.isPerennial) {
			// we only currently deal with historical events for almonds and alfalfa
			return false;
		}

		if (!recentIrrigationEventExists) {
			return true;
		} else if (!recentCutEventExists && this.planting.CommodityTypeId === eCommodityTypes.ALFALFA) {
			return true;
		} else {
			return false;
		}
	}

	private _onDateChanged(): void {

		this.isFuture = DateUtility.isFuture(this.f.EventDate.value as Date);

		if ((this.isTree || this.planting.CommodityTypeId === this.COMMODITY_TYPES.PROCESSING_TOMATO) && this.f.EventDate.value) {
			this._irrigationService.getAverageDeficit(this.plantingId, this.f.EventDate.value, this.f.Id.value).then((resp) => {
				if (!resp) {
					this._notificationService.generateNotifcation({
						type: eNotificationTypes.ERROR,
						message: `Failed to fetch deficit information due to bad event date. Please try again.`
					});

					return;
				}

				this.averageDeficit = resp;
				this.lastIrrigationEventDate = this._getPreviousIrrigationDate(this.averageDeficit.Days, this.f.EventDate.value);
			});
		}

		if (!this.isRecommendationVisible) {
			this.isOnOrBeforeWetDate = DateUtility.isOnOrBefore(this.f.EventDate.value, this.planting.WetDate);
			return;
		}

		this._irrigationService.getRecency(this.f.Id.value, this.f.EventDate.value,
			this.planting.Id)
			.then((response: IIrrigationEventGetRecency) => {

				if (response) {
					this.recentCutEventExists = response.RecentCutEventExists;
					this.recentIrrigationEventExists = response.RecentIrrigationEventExists;
				}

				// cache values to display appropriate messaging in the view
				this.isWarningSectionVisible = this._isWarningVisible(this.recentIrrigationEventExists,
					this.recentCutEventExists);

				this._getRecommendation();
				this.isOnOrBeforeWetDate = DateUtility.isOnOrBefore(this.f.EventDate.value,
					this.planting.WetDate);
			});
	}

	private _processEditGetResponse(res: IIrrigationEventEditResponse): void {
		let intervalRecommendation: IIrrigationIntervalRecommendation;
		let ratios: IUnitRatios; // application ratio object

		if (!res) {
			return;
		}

		this._initializeForm(res);
		this.isFuture = DateUtility.isFuture(this.f.EventDate.value as Date);

		if (res.IrrigationMethodId === eIrrigationMethods.RAINFALL) {
			this.f.EventDate.disable();
		}

		this.form.markAsDirty();
		this._bindValueChanges();

		intervalRecommendation = IrrigationEvent.getIntervalRecommendation(res.LastIrrigationEventDate,
			res.RecommendedInterval);

		this.flowmeter.hasData = res.HasFlowMeterData;
		this.flowmeter.gallons = res.FlowMeterGallons;
		this.flowmeter.areas.default = res.FlowMeterArea;
		this.flowmeter.areas.drip = res.DripFlowMeterArea;
		this.flowmeter.areas.microSprinkler = res.MicroSprinklerFlowMeterArea;
		this.lastUpdatedString = res.LastUpdated;
		this.isFloodEvent = IrrigationEvent.isFloodEvent(this.f.IrrigationMethodId.value);

		if (this.isFloodEvent) {
			this.units = eIrrigationUnits.INCHES;
		}

		this.irrigationMethods = res.IrrigationMethods;
		this.CIMISPrecipitation = Number(res.CIMISPrecipitation);

		ratios = IrrigationEvent.getUnitConversionRatio(this.f.IrrigationMethodId.value,
			res.SprinklerApplicationRate, res.DripApplicationRate, res.MicroSprinklerApplicationRate);

		this._applicationRate = ratios.ratio;
		this.recommendation.inches = res.RecommendedIrrigationAmount;
		this._getHoursValues();
		this._checkForFlowmeterData();

		this.recentIrrigationEventExists = res.RecentIrrigationEventExists;
		this.recentCutEventExists = res.RecentCutEventExists;

		this.isWarningSectionVisible = this._isWarningVisible(this.recentIrrigationEventExists,
			this.recentCutEventExists);

		if (!res.RecommendationSummary) {
			this.irrigationRecommendationDetails = null;
		} else {
			this.irrigationRecommendationDetails = res.RecommendationSummary;

			this.warningTextRecommendation = this.irrigationEvent.getWarningTextRecommendation(
				this.isFloodEvent, this.units, this.recentCutEventExists,
				this.planting.CommodityTypeId,
				this.irrigationRecommendationDetails.DaysSinceLastIrrigation,
				this.irrigationRecommendationDetails.ValidRainEventsCount,
				this._countZeroETRecords(res.RecommendationSummary.ETEvents), this.isFuture);
		}

		if (this.planting) {
			this.isOnOrBeforeWetDate = DateUtility.isOnOrBefore(this.f.EventDate.value,
				this.planting.WetDate);
		}

		this.averageDeficit.Deficit = res.AverageDeficit;
		this.averageDeficit.Days = res.DaysSinceLastIrrigation;
		this.lastIrrigationEventDate = this._getPreviousIrrigationDate(this.averageDeficit.Days, this.f.EventDate.value);
	}

	private _countZeroETRecords(etEvents: IETEvent[]): number {

		if (!etEvents || etEvents.length === 0) {
			return 0;
		} else {
			return etEvents.filter(x => x.ET === 0).length;
		}
	}

	private _selectIrrigationMethod(): void {
		this._checkForFlowmeterData(); // update flowmeter area based on irrigation method

		this.isFloodEvent = IrrigationEvent.isFloodEvent(this.f.IrrigationMethodId.value);

		if (this.isFloodEvent) {
			this.units = eIrrigationUnits.INCHES;
		}

		if (!this.planting) {
			return;
		}

		if (this.isRecommendationVisible && this.f.IrrigationMethodId.valid) {
			this._getRecommendation().then(() => {

				this.setManagerRec(this._amountFieldNames.inches.manager,
					this.f.ManagerAmountRecommendation.value);

				this.setApplied(this._amountFieldNames.inches.applied,
					this.f.WaterApplied.value);
			});
		}
	}

	private _showIrrigationWarning(events: IEventGroup[]): void {
		this._dialog.open(IrrigationEventRecalculateWarningDialog, {
			width: '500px',
			disableClose: true,
			data: {
				effectedEvents: events,
			}
		});

		this._hide();
	}

	private _showKeepDiscard(): void {
		this._dialog.open(SharedKeepDiscardDialog, {
			width: '500px',
			disableClose: true,
		});

		this._hide();
	}

	private _setCropManageRecommendation(inches: number, applicationRate: number): void {
		this.recommendation.inches =
			this._validateService.convertToFloat(inches, 2);

		this.recommendation.hours = this._validateService.convertToFloat(
			this.recommendation.inches * (1 / applicationRate), 1);
	}

	private _updateHours(field: string, inches: number): void {
		let hours: number = null;

		inches = ValidateService.numberIsEmpty(inches) ? null : Number(inches);

		if (!this._applicationRate) {
			this._notificationService.generateNotifcation({
				type: eNotificationTypes.ERROR,
				message: `Application rate for this irrigation method is invalid. Please update it in
					planting settings.`

			});

			return;
		}

		if (inches !== null) {
			hours = ValidateService.roundToDecimalPlaces(inches / this._applicationRate, 1);
		}

		switch (field) {
			case this._amountFieldNames.inches.recommended:
				this.recommendation.hours = hours;
				break;

			case this._amountFieldNames.inches.manager:
				this.f.ManagerAmountRecommendationHours.setValue(hours);
				break;

			case this._amountFieldNames.inches.applied:
				this.f.WaterAppliedHours.setValue(hours);
				break;
		}
	}

	private _updateInches(field: string, hours: number): void {
		let inches: number = null;

		hours = ValidateService.numberIsEmpty(hours) ? null : Number(hours);

		if (!this._applicationRate) {

			this._notificationService.generateNotifcation({
				type: eNotificationTypes.ERROR,
				message: `Application rate for this irrigation method is invalid. Please update it in
					planting settings.`

			});

			return;
		}

		if (hours !== null) {
			inches = ValidateService.roundTo2DecimalPlaces(hours * this._applicationRate, 3);
		}

		switch (field) {
			case this._amountFieldNames.hours.recommended:
				this.recommendation.inches = inches;
				break;

			case this._amountFieldNames.hours.manager:
				this.f.ManagerAmountRecommendation.setValue(inches);
				break;

			case this._amountFieldNames.hours.applied:
				this.f.WaterApplied.setValue(inches);
				break;
		}
	}
}
