import { Component, OnInit, ViewChild } from '@angular/core';
import { navigationViews, UpdateService } from '../../services/update.service';
import { CookieService } from '../../services/cookie.service';
import { UserInformationComponent } from './user-information';
import { ChangePasswordComponent } from './change-password';
import * as jQuery from 'jquery';
import { TokenService } from '../../services/token.service';
import { Router } from '@angular/router';

@Component({
	moduleId: module.id,
	selector: 'profile',
	templateUrl: 'main.html'
})

export class ProfileComponent implements OnInit {
	@ViewChild(UserInformationComponent, { static: true }) private userInformationComponent: UserInformationComponent;
	@ViewChild(ChangePasswordComponent, { static: true }) private changePasswordComponent: ChangePasswordComponent;

	constructor(
		private _router: Router,
		private _tokenService: TokenService,
		private updateService: UpdateService,
		private cookieService: CookieService) {

		if (this._tokenService.isAuthenticated() === false) {
			this._router.navigate(['login']);
		}
	}

	ngOnInit(): void {
		this.updateService.setCurrentRanchId(null);
		this.updateService.setRanchPlantingsPermissions(null);
		this.cookieService.deleteCurrentRanchCookie();
		this.updateService.setCurrentPage(navigationViews.PROFILE);
		this.updateService.clearRanchSettings();

		let __this = this;
		jQuery(document).bind('keypress', function (e) {
			if (e.which === 13) {
				if (jQuery('#changePassword').is(':visible')) {
					__this.changePasswordComponent.submit();
				}
				if (jQuery('#userInformation').is(':visible')) {
					__this.userInformationComponent.submit();
				}
			}
		});
	}

	ngOnDestroy(): void {
		jQuery(document).unbind('keypress');
	}
}
