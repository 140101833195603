<style>
    agm-map {
        height: 300px;
    }
</style>
<div class="bk-dialog bk-dialog__event">

    <!-- header -->
    <button type="button"
        class="bk-button bk-dialog__close"
        (click)="close()">

        <span>&times;</span>
    </button>

    <h4 *ngIf="!inEditMode"
        mat-dialog-title>

        {{'Create Ranch' | translate }}
    </h4>
    <h4 *ngIf="inEditMode"
        mat-dialog-title>

        {{'Edit' | translate }} {{'Ranch Information' | translate }}
    </h4>

    <!-- body -->

    <div mat-dialog-content>
        <form [formGroup]="form">
            <div class="bk-ranch-dialog-form">
                <div class="left-content-container bk-modal__left">

                    <div *ngIf="form">
                        <div *ngIf="inEditMode"
                            class="bk-form-group"
                            style="margin-left: 10px;">
                            <label class="bk-modal__label" style="font-size: 11px; margin-bottom:5px;">
                                {{'Ranch Owner' | translate }}
                            </label>
                            <div>
                                {{RanchOwnerFullName}}
                            </div>
                        </div>

                        <mat-form-field appearance="fill"
                            class='mat-form-field--one-line mat-form-field--short'>
                            <mat-label for="ranch-name">
                                {{'Ranch Name' | translate }}
                            </mat-label>
                            <input matInput 
                                id="ranch-name"
                                formControlName="Name"
                                required
                                type="text">
                            <mat-error *ngIf="f.Name.invalid && (f.Name.dirty || f.Name.touched)">
                                {{'Ranch name cannot be empty' | translate }}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="fill"
                            class='mat-form-field--one-line mat-form-field--short'>
                            <mat-label>
                                {{'Coordinates' | translate }}
                            </mat-label>
                            <input matInput
                                id="coordinates"
                                type="text"
                                formControlName="Coordinates"
                                required>

                            <mat-error *ngIf="f.Coordinates.invalid && (f.Coordinates.dirty || f.Coordinates.touched)">
                                <span *ngIf="f.Coordinates.errors.coordinatesTooLong">
                                    {{'coordinates should contain 6 or less decimals' | translate }}
                                </span>
                                <span *ngIf="f.Coordinates.errors.coordinatesInvalid">
                                    {{'Invalid coordinates' | translate }}
                                </span>
                            </mat-error>
                            
                        </mat-form-field>
                        <div *ngIf="coordinateExplanation"
                            class="bk-clearfix bk-location-warning">
                            <i class="fa fa-warning"></i>
                            {{ coordinateExplanation }}
                        </div>

                        <mat-form-field appearance="fill"
                            class='mat-form-field--one-line mat-form-field--short'>
                            <mat-label>
                                {{'Acres' | translate }}
                            </mat-label>
                            <input matInput 
                                formControlName="Acres"
                                step="0.01"
                                required
                                type="number">
                            <span matSuffix class="mat-form-field-suffix--padded">
                                {{'acres' | translate }}
                            </span>
                            <mat-error *ngIf="f.Acres.invalid && (f.Acres.dirty || f.Acres.touched)">
                                <ng-container *ngIf="f.Acres.errors.required">
                                    {{ 'Field is required' | translate }}
                                </ng-container>
                                <ng-container *ngIf="f.Acres.errors.min">
                                    {{ 'Acres must be greater than zero' | translate }}
                                </ng-container>
                                <ng-container *ngIf="f.Acres.errors.max">
                                    {{'Acres must be less than 10,000' | translate }}
                                </ng-container>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="bk-modal__right"
                    [class.bk-modal__shown]="!loadingData">

                    <div *ngIf="!inEditMode"
                        class="bk-modal__right-instructions">
                        {{'Ranch map instructions' | translate }}
                    </div>
                    <div class="bk-modal__right-instructions">
                        <div class="bk-marker bk-marker--ranch">
                            <svg-ranch-marker></svg-ranch-marker>
                        </div>
                        {{'Ranch' | translate }}
                    </div>
                    <div class="bk-modal__map-container map-container">
                        <agm-map (mapClick)="changeCoordinatesMap($event)"
                            [mapTypeId]="'hybrid'"
                            [fullscreenControl]="true">
                            <agm-marker *ngFor="let marker of markers"
                                [iconUrl]="'../assets/images/icons/map_marker-ranch-v2.png'"
                                [latitude]="marker.lat"
                                [longitude]="marker.lng">
                            </agm-marker>
                            <map-control></map-control>
                        </agm-map>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <!-- footer -->

    <div mat-dialog-actions align="end">
        <button *ngIf="inEditMode && permissions && permissions.UserIsRanchOwner"
            class="bk-button bk-button--medium bk-button--text-only bk-button--float-left"  
            (click)="onDeleteConfirmationOpen()">
            {{'Delete' | translate }}
        </button>

        <button class="bk-button bk-button--medium bk-button--text-only"
            (click)="onClose()">
            {{'Cancel' | translate }}
        </button>

        <button *ngIf="!inEditMode"
            [disabled]="!form.valid"
            class="bk-button bk-button--medium" 
            (click)="save()">
            {{(isSaving ? 'Creating' : 'Create') | translate }} {{'Ranch' | translate }}
            <img class='bk-button__loader' *ngIf='isSaving' src='/assets/images/loader-20px.gif'/>
        </button>

        <button *ngIf="inEditMode"
            [disabled]="!form.valid"
            class="bk-button bk-button--medium"
            (click)="save()">
            {{ (isSaving ? 'Saving' : 'Save') | translate }}
            <img class='bk-button__loader' *ngIf='isSaving' src='/assets/images/loader-20px.gif'/>
        </button>
    </div>
</div>