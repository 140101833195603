export const ReleaseVersion = '2.4.9';
export const SessionStorageWebAPIKey = 'webAPIToken';
export const RecaptchaKey = '6Le6HMYUAAAAAHdN0Amxkku6cUQrEC196AQn2wms';
export const CASP_HOMEPAGE_URL = 'http://www.almondstewardship.org/';
export const DONATE_URL = 'https://donate.ucanr.edu/?fund_id=1400&program=UC+Cooperative+Extension+Offices&county=Monterey';
export const CASPNotNowCookieName = 'CASPNotNow';
export const GlobalNotificationExists = false;

export const VersionUpgradeCookieName = 'showVersionUpdate';
export const enum loginTypes {
	WORKSHOP = 1
}

export const PREVIOUS_CROP_NO_RESIDUE_ID = 14; // Id associated with the "no residue" option
export const DEFAULT_MINERALIZATION_RATE = 2;
