import { Component, Inject, OnInit, ViewChildren, QueryList, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { SoilMoistureService } from './service';
import { ObjectUtility } from '../../classes/objectUtility';
import { UpdateService } from '../../services/update.service';
import { SoilMoisture } from './soilMoisture';
import { SoilSeriesCharts } from './soilSeriesCharts';

import {
	ISoilMoistureDialogParameters, ISoilMoistureHeader, ISoilMoistureDropdownItem,
	ISoilMoistureFormProperties, ISoilMoistureSeries,
	ISoilMoistureGraphSeries, ISoilMoistureTab, ISoilMoistureDepthOption
} from './soilMoisture.interface';

import { IHighchartsData } from '../../interfaces/views/highCharts';
import { CMError } from '../../interfaces/interfaces';

import { eSoilMoistureViews, eSoilMoistureImportErrors } from '../../interfaces/constants';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	moduleId: module.id,
	selector: 'soil-moisture-dialog',
	templateUrl: 'soil-moisture-dialog.html'
})

export class SoilMoistureDialog implements OnInit, AfterViewInit, OnDestroy {
	@ViewChildren('chartContainer') chartList: QueryList<ElementRef>;

	private readonly graphViewWidth: string = '1000px';
	private readonly standardViewWidth: string = '690px';

	public eSoilMoistureViews = eSoilMoistureViews;
	public headers: ISoilMoistureHeader[] = new Array();
	public soilMoisture = new SoilMoisture();
	public depthDropdownItems: ISoilMoistureDropdownItem[] = this.soilMoisture.soilMoistureDepthDropdownItems;
	public typeDropdownItems: ISoilMoistureDropdownItem[] = this.soilMoisture.SoilMoistureTypeDropdownItems;
	public tabs: ISoilMoistureTab[] = SoilMoisture.SoilMoistureTabs;
	public depthOptions: ISoilMoistureDepthOption[] = new Array();

	public noHeadersVisible = true;
	public fileNameIsUnchanged = true;
	private currentlyLinkedDataFile: string;

	public headersValid = {
		sensorName: true as boolean,
		sensorDepth: true as boolean,
		sensorType: true as boolean
	}

	public view: eSoilMoistureViews;
	public plantingId: number;
	public plantingName: string;
	public fileName: string;
	public hasSoilMoistureFileName: boolean;
	public isSaving: boolean;
	private _evaluatingFile = false;
	public originalFormProperties: ISoilMoistureFormProperties;
	public soilMoistureLinkError: eSoilMoistureImportErrors;
	public eSoilMoistureImportErrors = eSoilMoistureImportErrors;
	public areNewHeaders = false;

	private soilSeriesChart: SoilSeriesCharts;
	public soilSeries: ISoilMoistureGraphSeries[];
	public selectedTab: string;
	public chartContainers: ElementRef[];
	public soilTimeRange: number;
	private _subscriptions$: Subject<boolean>;

	constructor(public dialogRef: MatDialogRef<SoilMoistureDialog>,
		@Inject(MAT_DIALOG_DATA) public data: ISoilMoistureDialogParameters,
		private soilMoistureService: SoilMoistureService,
		private updateService: UpdateService) {

		this._subscriptions$ = new Subject();

		if (!data) {
			return;
		}

		this.view = data.view;
		this.updateDialogWidth();
		this.plantingId = data.plantingId;
		this.plantingName = data.plantingName;
		this.fileName = data.fileName;
		this.hasSoilMoistureFileName = data.hasSoilMoistureFileName;

		this.dialogRef.disableClose = true;

		this.dialogRef.backdropClick().pipe(takeUntil(this._subscriptions$)).subscribe(clicked => {
			this.close(true);
		})

		this.updateService.closeModalSubscription.pipe(takeUntil(this._subscriptions$)).subscribe(close => {
			this.dialogRef.close();
		})
	}

	/**
	 * displays the graph is view is set to 'graph' and filename exists. If
	 * filename doesn't exist, change view to 'settings' and show settings.
	 */
	ngOnInit(): void {
		this.soilSeriesChart = new SoilSeriesCharts();
		this.isSaving = false;

		if (this.view === eSoilMoistureViews.SETTINGS) {
			this.showSettings();
		}

		if (this.view === eSoilMoistureViews.GRAPH) {
			if (this.fileName || this.hasSoilMoistureFileName) {
				this._showGraph();
				return;
			}

			this.view = eSoilMoistureViews.SETTINGS;
			this.updateDialogWidth();
			this.showSettings();
		}

		this.dialogRef.afterOpen().pipe(takeUntil(this._subscriptions$)).subscribe(event => {
			this.updateDialogWidth();
		});
	}

	ngOnDestroy(): void {
		if (!this._subscriptions$) {
			return;
		}

		this._subscriptions$.next(true);
		this._subscriptions$.complete();
	}

	ngAfterViewInit(): void {
		// the graph isn't rendered until the chartList.changes() fires
		// the event fires when soilSeries is populated and selectedDepth is not null
		this.chartList.changes.pipe(takeUntil(this._subscriptions$)).subscribe(() => {
			this.chartContainers = this.chartList.toArray();
			this.setDataForGraphs();
		});

		this.updateDialogWidth();
	}

	public close(outsideClick?: boolean): void {
		if (this.view === eSoilMoistureViews.SETTINGS) {
			if (this.formPropertiesHaveChanged()) {
				this.view = eSoilMoistureViews.CONFIRMATION;
				this.updateDialogWidth();
				return;
			}

			this.dialogRef.close();
		} else if (this.view === eSoilMoistureViews.GRAPH) {
			this.dialogRef.close();
		} else if (this.view === eSoilMoistureViews.CONFIRMATION) {
			if (outsideClick) {
				return;
			}

			this.dialogRef.close();
		}
	}

	public cancelClose(): void {
		this.view = eSoilMoistureViews.SETTINGS;
		this.updateDialogWidth();
	}

	public showSettings(): void {
		if (this.fileName) {
			this.getHeaders(this.plantingId)
				.then(event => { });
		} else if (this.hasSoilMoistureFileName) {
			this.soilMoistureService.getFile(this.plantingId)
				.then(response => {
					if (!response) {
						this.noHeadersVisible = true;
						return;
					}

					if (response.FileName) {
						this.fileName = response.FileName
						this.getHeaders(this.plantingId)
							.then(event => { });
					}
				});
		} else {
			this.noHeadersVisible = true;
			this.originalFormProperties = {
				fileName: this.fileName,
				headers: this.headers
			};
		}
	}

	private _showGraph(): void {
		this.soilSeries = null;
		this.selectedTab = null;
		this.chartContainers = null;

		for (let tab of this.tabs) {
			tab.disabled = true;
		}

		this.soilMoistureService.getData(this.plantingId).then(response => {
			if (!response) {
				this.switchView(eSoilMoistureViews.SETTINGS);
				return;
			}

			this.soilSeries = response;

			if (this.soilSeries.length > 0) {
				this.selectTab(this.soilSeries[0].tabName);
			}

			for (let series of this.soilSeries) {
				for (let tab of this.tabs) {
					if (tab.name === series.tabName) {
						tab.disabled = false;
					}
				}
			}

			this._pruneTabs();

			this.setDataForGraphs();
		})
	}

	/**
	 * Depending on data, hide irrelevant tabs.
	 */
	private _pruneTabs(): void {
		this.tabs = this.tabs.filter(obj => this.soilSeries.some(item => item.tabName === obj.name));
	}

	/**
	 * trim out of range values
	 * @param records
	 */
	private _removeOutOfRangeRecords(records: ISoilMoistureGraphSeries[]): ISoilMoistureGraphSeries[] {
		if (!records) {
			return [];
		}

		for (let record of records) {
			let seriesListLength: number;

			if (!record.seriesList || record.seriesList.length === 0) {
				continue;
			}

			seriesListLength = record.seriesList.length;

			for (let j = seriesListLength - 1; j >= 0; j--) {
				let seriesLength: number;
				let series: ISoilMoistureSeries;

				series = record.seriesList[j];

				if (!series.Dates || series.Dates.length === 0) {
					continue;
				}

				if (!series.Values || series.Values.length === 0) {
					continue;
				}

				seriesLength = series.Dates.length;

				for (let i = seriesLength - 1; i >= 0; i--) {
					let val: number;
					let valid = true;

					val = Number(series.Values[i]);

					switch (record.tabName) {
						case 'Volumetric Moisture':

							if (val < 0 || val > 100) {
								valid = false;
							}

							break;
						case 'Tension':
							if (val < 0 || val > 200) {
								valid = false;
							}

							break;
						case 'Salinity/Electrical Conductivity':
							if (val < 0 || val > 20) {
								valid = false;
							}
					}

					if (!valid) {
						series.Dates.splice(i, 1);
						series.Values.splice(i, 1);

						if (series.Dates.length === 0) {
							record.seriesList.splice(j, 1);
							break;
						}
					}
				}
			}
		}

		return records;
	}

	public selectTab(tabName: string): void {
		let seriesList: ISoilMoistureSeries[];
		let depthOption: ISoilMoistureDepthOption;

		if (!tabName) {
			return;
		}

		this.depthOptions = new Array();
		this.selectedTab = tabName;

		if (this.soilSeries && this.soilSeries.length > 0) {
			let series: ISoilMoistureGraphSeries;

			series = this.soilSeries.filter(x => x.tabName === this.selectedTab)[0];

			if (series) {
				seriesList = series.seriesList;
			}
		} else {
			seriesList = null;
		}

		if (!seriesList) {
			return;
		}

		// populate a list of depth options, which is a series of checkboxes in the UI
		for (let series of seriesList) {
			depthOption = this.depthOptions.filter(x => x.name === series.Header.Depth)[0];

			if (!depthOption) {
				this.depthOptions.push({
					name: series.Header ? series.Header.Depth : null,
					isDisplayed: true
				});
			}
		}

		this.setDataForGraphs();
	}

	public link(): void {
		let updateHeaders = true;

		this.soilMoistureLinkError = null;
		this.fileNameIsUnchanged = true;
		this.currentlyLinkedDataFile = this.fileName;

		// update headers if filename has changed
		updateHeaders = this.originalFormProperties
			&& this.originalFormProperties.fileName === this.fileName ? false : true;

		this._evaluatingFile = true;

		this.soilMoistureService.linkFile(this.plantingId, this.fileName, updateHeaders)
			.then(response => {
				let headersResponse: ISoilMoistureHeader[];
				let error: CMError;

				if (!response) {
					return;
				}

				error = response as CMError;

				if (error.code) {
					this.soilMoistureLinkError = error.code;
					this.noHeadersVisible = true;
					this.headers = new Array();
					this._evaluatingFile = false;
					this.validateHeaders();
					return;
				}

				headersResponse = response as ISoilMoistureHeader[];

				if (!headersResponse.length) {
					this.noHeadersVisible = true;
					this.headers = new Array();
					this.validateHeaders();
					this.soilMoistureLinkError = null;
					this._evaluatingFile = false;
					return;
				}

				this.headers = headersResponse;

				let filteredHeaders = this.headers.filter(x => x.isDisplayed);
				this.noHeadersVisible = filteredHeaders.length === 0;

				this.areNewHeaders = SoilSeriesCharts.areNewHeaders(this.headers);

				this.validateHeaders();
				this._evaluatingFile = false;
			});
	}

	public isNameValid(name: string): boolean {
		if (name) {
			return true;
		} else {
			return this.areNewHeaders && name === null ? true : false;
		}
	}

	public isDepthValid(depth: string): boolean {
		for (let item of this.depthDropdownItems) {
			if (item.value === depth) {
				return true;
			}
		}

		return this.areNewHeaders && depth === null ? true : false;
	}

	public isTypeValid(type: string): boolean {
		for (let item of this.typeDropdownItems) {
			if (item.value === type) {
				return true;
			}
		}

		return this.areNewHeaders && type === null ? true : false;
	}

	public validateHeaders(): void {

		this.headersValid.sensorDepth = true;
		this.headersValid.sensorName = true;
		this.headersValid.sensorType = true;

		for (let header of this.headers) {
			if (!header.isDisplayed) {
				continue;
			}

			if (this.headersValid.sensorName && !this.isNameValid(header.SensorName)) {
				this.headersValid.sensorName = false;
			}

			if (this.headersValid.sensorDepth && !this.isDepthValid(header.Depth)) {
				this.headersValid.sensorDepth = false;
			}

			if (this.headersValid.sensorType && !this.isTypeValid(header.DataType)) {
				this.headersValid.sensorType = false;
			}
		}
	}

	public isFormValid(): boolean {
		if (this.soilMoistureLinkError) {
			return false;
		}

		if (!this.fileName) {
			return false;
		}

		if (this._evaluatingFile === true) {
			return false;
		}

		if (!this.headersValid.sensorDepth || !this.headersValid.sensorName ||
			!this.headersValid.sensorType) {

			return false;
		}

		if (this.areNewHeaders) {
			for (let header of this.headers) {
				if (header.SensorName === null || header.Depth === null || header.DataType === null) {
					return false;
				}
			}
		}

		if (this.fileNameIsUnchanged
			&& this.originalFormProperties
			&& this.originalFormProperties.fileName !== this.currentlyLinkedDataFile) {
			return true;
		}

		if (this.fileNameIsUnchanged && !this.formPropertiesHaveChanged()) {
			return false;
		}

		if (this.currentlyLinkedDataFile !== this.fileName) {
			return false;
		}

		return true;
	}

	public save(): void {
		if (this.isSaving) {
			return;
		}

		this.isSaving = true;

		this.soilMoistureService.saveHeaders(this.plantingId, this.fileName, this.headers)
			.then(() => {
				this.isSaving = false;

				if (this.originalFormProperties && this.originalFormProperties.fileName !== this.fileName) {
					this.updateService.setSoilMoistureFileUpdated(this.plantingId, this.fileName);
				}

				this.switchView(eSoilMoistureViews.GRAPH);
			})
	}

	public switchView(view: eSoilMoistureViews): void {
		this.view = view;
		this.updateDialogWidth();

		if (view === eSoilMoistureViews.SETTINGS) {
			this.showSettings();
		}

		if (view === eSoilMoistureViews.GRAPH) {
			this._showGraph();
		}
	}

	public onSoilMoistureFileNameChange(): void {
		this.soilMoistureLinkError = null

		if (this.currentlyLinkedDataFile !== this.fileName) {
			this.fileNameIsUnchanged = false;
		} else {
			this.fileNameIsUnchanged = true;
		}
	}

	private getHeaders(plantingId: number): Promise<void> {
		return this.soilMoistureService.getHeaders(plantingId)
			.then(response => {
				if (!response || !(response instanceof Array)) {
					this.noHeadersVisible = true;
					return;
				}

				this.headers = response;

				let filteredHeaders = this.headers.filter(x => x.isDisplayed);
				this.noHeadersVisible = filteredHeaders.length === 0;

				this.originalFormProperties = ObjectUtility.copy({
					fileName: this.fileName,
					headers: this.headers
				});

				this.currentlyLinkedDataFile = this.fileName;

				this.areNewHeaders = SoilSeriesCharts.areNewHeaders(this.headers);

				this.validateHeaders();
			});
	}

	private formPropertiesHaveChanged(): boolean {
		let formProperties: ISoilMoistureFormProperties = {
			fileName: this.fileName,
			headers: this.headers
		};

		let result: boolean;

		if (!this.originalFormProperties) {
			return false;
		}

		result = ObjectUtility.AreEqual(this.originalFormProperties, formProperties, null) ? false : true;

		return result;
	}

	public filterDepths(): void {
		this.setDataForGraphs();
	}

	public updateGraphRange(): void {
		this.setDataForGraphs();
	}

	public updateTimeRange(timeRange: number): void {
		this.soilTimeRange = timeRange;
		this.setDataForGraphs();
	}

	private setDataForGraphs(): void {
		let chartData: IHighchartsData[];
		let line: IHighchartsData;
		let index = 0;
		let chartParams: { data: IHighchartsData[], unit: string };
		let selectedGraphSeries: ISoilMoistureGraphSeries;
		let dataType: string;
		let seriesList: ISoilMoistureSeries[];

		if (this.soilTimeRange === undefined) {
			this.soilTimeRange = 30;
		}

		if (!this.soilSeries || !this.chartContainers || !this.chartContainers.length) {
			return;
		}

		selectedGraphSeries = this.soilSeries.filter(x => x.tabName === this.selectedTab)[0];

		if (!selectedGraphSeries) {
			return;
		}

		selectedGraphSeries.seriesList = this._sortSeries(selectedGraphSeries.seriesList);
		seriesList = new Array();

		for (let series of selectedGraphSeries.seriesList) {
			// if a data series' depth matches depth options, add the series to the chart
			if (!series.Header) {
				continue;
			}

			if (this.depthOptions.filter(x => x.isDisplayed && x.name === series.Header.Depth).length) {
				seriesList.push(series);
			}
		}

		dataType = seriesList && seriesList.length ? seriesList[0].Header.DataType : null;
		chartData = new Array();

		if (seriesList) {

			for (let series of seriesList) {
				line = this.soilSeriesChart
					.convertData(series as ISoilMoistureSeries, this.soilTimeRange);

				chartData.push(line);
			}
		}

		// set unit to the first data type in the list
		chartParams = { data: chartData, unit: dataType };

		this.soilSeriesChart.generateHighchart(this.chartContainers[index].nativeElement, chartParams);
	}

	/**
	 * Sort series by depth, and then by label
	 * @param series
	 * @returns
	 */
	private _sortSeries(series: ISoilMoistureSeries[]): ISoilMoistureSeries[] {

		if (!series || series.length === 0) {
			return new Array();
		}

		series.sort((a, b): number => {
			let result: number;

			if (a.Header.Depth === b.Header.Depth) {
				result = a.Header.SensorName > b.Header.SensorName ? 1 : (a.Header.SensorName < b.Header.SensorName ? -1 : 0);
			} else {
				result = a.Header.Depth > b.Header.Depth ? 1 : (a.Header.Depth < b.Header.Depth ? -1 : 0);
			}

			return result;
		});

		return series;
	}

	private updateDialogWidth(): void {
		if (this.view === eSoilMoistureViews.GRAPH) {
			this.dialogRef.updateSize(this.graphViewWidth);
		} else {
			this.dialogRef.updateSize(this.standardViewWidth);
		}
	}
}
