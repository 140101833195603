import { Component, Inject, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AgmMap } from '@agm/core';

import { WeatherStationsService } from '../../models/weather-station-stats/service';
import { MapControlDirective } from '../../directives/map-control.directive';
import { ValidateService } from '../../services/validate.service';
import { UpdateService } from '../../services/update.service';
import { ObjectUtility } from '../../classes/objectUtility';

import { IWeatherStationAdvanced, IWeatherStation, WeatherAPI } from './interfaces';
import { IWeatherStationRegion } from '../../models/region/interfaces';
import { CMCoordinates, iMapMarker, iMapClickEvent } from '../../interfaces/views/map';
import { eDialogFormViews } from '../../interfaces/constants';
import { httpStatusCodes, SuccessResponse } from '../../interfaces/interfaces';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GeoLocation } from '../../classes/geolocation.class';
import { WeatherAPIService } from './weather-api.service';

@Component({
	moduleId: module.id,
	selector: 'weather-station-dialog',
	templateUrl: 'weather-station-dialog.html'
})

export class WeatherStationDialog implements OnInit, OnDestroy {

	public areCoordinatesValid = false;
	public canBeDeleted = false;
	public isCIMISCodeValid = false;

	// default to being centered on western united states
	public mapParams = {
		lat: 37.195 as number,
		lng: -116.486 as number,
		zoom: 5 as number
	}

	public markers: iMapMarker[] = new Array();
	public regions: IWeatherStationRegion[] = new Array();

	// fields to validate
	public readonly requiredFields: string[] = ['Name', 'External_Id', 'County', 'NearestCity', 'RegionId', 'WeatherAPI_Id'];
	public showErrors = false;

	public title = 'Edit Weather Station';
	public view: eDialogFormViews = eDialogFormViews.FORM;
	public weatherAPIs: WeatherAPI[];
	public weatherStation: IWeatherStation;
	public weatherStationViewsEnum = eDialogFormViews;

	@ViewChild(AgmMap, { static: false }) private map: AgmMap;
	@ViewChild(MapControlDirective, { static: false }) private mapControl: MapControlDirective;
	private _originalWeatherStation: IWeatherStation;
	private _subscriptions$: Subject<boolean>;

	constructor(
		public dialogRef: MatDialogRef<WeatherStationDialog>,
		@Inject(MAT_DIALOG_DATA) private _data: { weatherStation: IWeatherStationAdvanced, canBeDeleted: boolean },
		private _weatherAPIService: WeatherAPIService,
		private _weatherStationsService: WeatherStationsService,
		private _updateService: UpdateService) {

		let coordinatesString: string;

		this.weatherStation = this._convertWeatherStation(_data ? _data.weatherStation : null);

		if (_data) {
			this.canBeDeleted = _data.canBeDeleted;
		}

		this._originalWeatherStation = ObjectUtility.copy(this.weatherStation);
		this.regions = this._updateService.weatherStationRegions;

		this._weatherAPIService.list().then((d) => {
			this.weatherAPIs = d;
		});

		if (!_data) {
			this.title = 'Add Weather Station';

			if (this._updateService.currentLocation.lat && this._updateService.currentLocation.lng) {
				coordinatesString = this._updateService.currentLocation.lat + ',' + this._updateService.currentLocation.lng;
				this.centerMap(coordinatesString);
			} else {
				GeoLocation.getCoordinates().then(position => {
					if (position.coords.latitude && position.coords.longitude) {
						this._updateService.setCurrentLocation(Number(position.coords.latitude), Number(position.coords.longitude));
						coordinatesString = position.coords.latitude + ',' + position.coords.longitude;
						this.centerMap(coordinatesString);
					}
				}).catch((reason: any) => {
					// we need to catch the error triggered by
					// a user not sharing his/her location
				});
			}

			return;
		}

		this.centerMap(this.weatherStation.Coordinates);
		this.placeMarker(this.weatherStation.Coordinates);

		this.areCoordinatesValid = true;
		this.isCIMISCodeValid = true;
	}

	ngOnInit(): void {
		this._subscriptions$ = new Subject();

		this.dialogRef.backdropClick().pipe(takeUntil(this._subscriptions$)).subscribe((e: MouseEvent) => {
			this.close(e);
		});

		this._updateService.closeModalSubscription.pipe(takeUntil(this._subscriptions$)).subscribe(event => this.dialogRef.close());

		this._updateService.weatherStationRegionsList$
			.pipe(takeUntil(this._subscriptions$)).subscribe(weatherStationRegions => this.regions = weatherStationRegions);
	}

	ngOnDestroy(): void {
		if (!this._subscriptions$) {
			return;
		}

		this._subscriptions$.next(true);
		this._subscriptions$.complete();
	}

	public close(e?: MouseEvent): void {

		if (!this.weatherStation || !this._originalWeatherStation) {
			return;
		}

		if (this.view === eDialogFormViews.DELETECONFIRMATION) {
			return;
		}

		if (this.view === eDialogFormViews.DISCARDCHANGES) {
			this.dialogRef.close();
			return;
		}

		if (ObjectUtility.isEqual(this.weatherStation, this._originalWeatherStation)) {
			this.dialogRef.close();
			return;
		}

		this.switchView(eDialogFormViews.DISCARDCHANGES);
	}

	public onMapClick(event: iMapClickEvent): void {
		let coordinatesString: string;

		if (!event || !event.coords || !event.coords.lat || !event.coords.lng) {
			return;
		}

		coordinatesString = event.coords.lat + ',' + event.coords.lng;

		this.weatherStation.Coordinates = coordinatesString;
		this.placeMarker(coordinatesString);

		this.areCoordinatesValid = true;
	}

	public onCoordinatesTextChange(coordinatesString: string): void {
		let coordinates: CMCoordinates;

		// using this as validation (if null, invalid string)
		coordinates = ValidateService.getCoordinatesFromString(coordinatesString);

		if (!coordinates) {
			this.areCoordinatesValid = false;
			return
		}

		this.placeMarker(coordinatesString);
		this.areCoordinatesValid = true;
	}

	public isStationValid(): boolean {
		if (!this.weatherStation.Name || !this.weatherStation.External_Id ||
			!this.weatherStation.County || !this.weatherStation.NearestCity ||
			!this.weatherStation.RegionId || !this.weatherStation.WeatherAPI_Id) {

			return false;
		}

		return this.areCoordinatesValid && this.isCIMISCodeValid;
	}

	public create(): void {
		this.showErrors = true;

		if (!this.isStationValid()) {
			return;
		}

		this._weatherStationsService.create(this.weatherStation)
			.then(weatherStation => {
				if (weatherStation) {
					weatherStation.RegionName = this.regions.filter(x => x.Id === weatherStation.RegionId)[0].Name;
					this._updateService.createWeatherStation(weatherStation);
				}

				this.dialogRef.close()
			});
	}

	public save(): void {
		this.showErrors = true;

		if (!this.isStationValid()) {
			return;
		}

		this._weatherStationsService.update(this.weatherStation)
			.then(response => {
				let successResponse: SuccessResponse;

				successResponse = response as SuccessResponse;

				if (successResponse && successResponse.Message) {
					this.weatherStation.RegionName = this.regions.filter(x => x.Id === this.weatherStation.RegionId)[0].Name;
					this._updateService.updateWeatherStation(this.weatherStation);
				}

				this.dialogRef.close()
			});
	}

	public remove(): void {
		this.showErrors = true;

		if (!this.isStationValid()) {
			return;
		}

		this._weatherStationsService.delete(this.weatherStation.Id)
			.then(response => {
				if (response && response.code === httpStatusCodes.success) {
					this._updateService.deleteWeatherStation(this.weatherStation);
				}

				this.dialogRef.close();
			});
	}

	public validateCIMISCode(cimisCode: number): void {
		this.isCIMISCodeValid = ValidateService.isValidNumber(cimisCode) && cimisCode <= 1000;
	}

	public switchView(view: eDialogFormViews): void {
		this.view = view;

		if (this.view === eDialogFormViews.DELETECONFIRMATION) {
			this.title = 'Delete Weather Station';
		} else {
			this.title = this.weatherStation.Id ? 'Edit Weather Station' : 'Add Weather Station';
		}

		if (view === eDialogFormViews.DELETECONFIRMATION || view === eDialogFormViews.DISCARDCHANGES) {
			this.dialogRef.updateSize('460px');
		} else {
			this.dialogRef.updateSize('980px');
		}
	}

	private _convertWeatherStation(weatherStation?: IWeatherStationAdvanced): IWeatherStation {
		let result: IWeatherStation;

		if (weatherStation) {
			result = {
				Id: weatherStation.Id,
				Name: weatherStation.Name,
				External_Id: weatherStation.ExternalId,
				NearestCity: weatherStation.NearestCity,
				County: weatherStation.County,
				Coordinates: weatherStation.Coordinates,
				UcIpmName: weatherStation.UcIpmName,
				RegionId: weatherStation.RegionId,
				WeatherAPI_Id: weatherStation.WeatherAPI_Id,
				API_Id: weatherStation.API_Id,
			}
		} else {
			result = {
				Id: null,
				Name: null,
				External_Id: null,
				NearestCity: null,
				County: null,
				Coordinates: null,
				UcIpmName: null,
				RegionId: null,
				WeatherAPI_Id: null,
				API_Id: null
			}
		}

		return result;
	}

	private centerMap(coordinatesString: string): void {
		let coordinates: CMCoordinates;
		let zoom = 10;

		coordinates = ValidateService.getCoordinatesFromString(coordinatesString);

		if (!coordinates) {
			return;
		}

		this.mapParams = {
			lat: coordinates.lat,
			lng: coordinates.lng,
			zoom: zoom
		}

		if (!this.map || !this.mapControl) {
			return;
		}

		this.map.triggerResize()
			.then(event => {
				this.mapControl.centerMap(this.mapParams.lat, this.mapParams.lng);
				this.mapControl.setZoom(this.mapParams.zoom);
			});
	}

	private placeMarker(coordinatesString: string): void {
		let coordinates: CMCoordinates;
		let marker: iMapMarker;

		coordinates = ValidateService.getCoordinatesFromString(coordinatesString);

		if (!coordinates) {
			return;
		}

		this.markers = new Array();

		marker = {
			lat: coordinates.lat,
			lng: coordinates.lng
		}

		this.markers.push(marker);
	}
}
