import { AbstractControl, FormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { FormValidation } from '../../classes/formValidation';
import { eCalculators, eCommodityTypes } from '../../interfaces/constants';
import { eCommodityTypeCalculators, ICommodityType } from '../../models/commodity-type/interfaces';
import { CropType } from '../../models/crop-type/cropType';
import { ICropTypeViewModel, IPerennialCanopy, IPerennialRootDepth,
	ITreeNutrientThreshold, IVegetativeNDemand } from '../../models/crop-type/interfaces';
import { ISeasonalKC } from '../../models/seasonal-kc/interfaces';
import { Planting } from '../planting-settings/planting';
import { PlantingSettingsValidator } from '../planting-settings/plantingSettingValidator';
import { eNutrientSymbols } from '../tissue-sample-event/interfaces';

// YieldTarget is used in planting settings only
// PreviousCropTypeId is used by CropSettingsComponent only
// PerennialCanopy and PerennialRootDepth are custom fields for CropSettingsComponent
export type CropTypeFormFields = 'Id' | 'BasalKC' | 'BedWidth' | 'CanopyA' | 'CanopyB' |
	'CanopyC' |
	'CanopyD' | 'CanopyE' | 'CanopyF' |
	'CanopyGMax' | 'CanopyIntercept' | 'CanopyMaxFraction' |
	'CanopyMin' |
	'CommodityTypeId' |
	'CovDensityFactor' |
	'CropMineralizationRate' | 'CropSensitivity' | 'DripIrrigationFactor' |
	'EarlyFruit' |
	'ExcessiveSummerLeafN' | 'FertA' |
	'FertB' |
	'FertilizerUseEfficiency' | 'FertYo' | 'FloodIrrigationFactor' |
	'GermDays' |
	'HarvestIntervalFraction' |
	'IgnoresNFactors' | 'InadequateSummerLeafN' | 'IsNDependent' | 'IsMacroTunnelEnabled'
	| 'IsPerennial' | 'IsPreviousCrop' | 'IsStressable' |'IsTree' |
	'IsYearSpecific' | 'Kccov_int' | 'Kccov_quad' | 'Kccov_slope' |
	'LeafSenesce' |
	'MaxAge' | 'MaxDays' | 'MaxNRec' | 'MaxRootDepth' | 'MaxUptake' |
	'MinRootDepth' | 'Name' |
	'NFertilizerAdjustment' | 'NFertilizerFactorA' | 'NFertilizerFactorB' |
	'NFertilizerFactorC' | 'NFertilizerFactorD' | 'NUptakeThreshold' |
	'PerennialCanopies' |
	'PerennialRootDepths' | 'PrevCropK' | 'PreHarvestSenesce' |
	'RootDepthThreshold' | 'Rows' |
	'SeasonalKCs' | 'ShouldShowTreeCoefficients' | 'SoilNThreshold' |
	'SoilNThresholdFraction' | 'SoilNThresholdFractionMid' |
	'SoilNThresholdLate' |
	'SoilNThresholdMid' |
	'SoilNRatioA' | 'SoilNRatioB' | 'SoilNRatioC' |
	'SpringLeafNutrientsRequired' | 'SprinklerIrrigationFactor' |
	'StrawberryFertilizationInterval' |
	'StressStart' | 'StressEnd' | 'TargetTension1' | 'TargetTension2' |
	'TargetTension3' | 'TargetTension4' |
	'TreeIlr4Coefficient' |
	'TreeIlr8Coefficient' | 'TreeIlrProductCoefficient' | 'TreeInterceptCoefficient' |
	'TreeLeafNCoefficient' | 'UptakeConstant' |
	'UptakeFactor' | 'VegetativeNDemands' | 'WaterStressStart' | 'WaterStressEnd' |
	'WetAreaDrip' | 'WetAreaMicroSprinkler' |
	// planting settings / crop settings only
	'DaysToEndDate' | 'DefaultCropTypeId' |
	'IsCoverCropEnabled' | 'IsStressEnabled' |
	'LeafSenesceDate' | 'PrevCropDays' | 'PreviousCropTypeId' |
	'StressStartDate' | 'StressEndDate' |
	// not submitted
	'CalculatorId' | 'RootDepthIndex' | 'PerennialCanopyIndex' | 'VegetativeIndex';

export class CropTypeFormBuilder {
	public form: FormGroup;

	public get f(): { [key in CropTypeFormFields]: AbstractControl } {
		return this.form.controls as { [key in CropTypeFormFields]: AbstractControl };
	}

	protected _commodityTypes: ICommodityType[];
	protected _fb: FormBuilder;
	protected _subscriptions$: Subject<boolean>;
	private _commodityTypeCalculator: string;

	constructor(fb: FormBuilder, commodityTypes: ICommodityType[], $subscription: Subject<boolean>) {
		this._fb = fb;
		this._commodityTypes = commodityTypes;
		this._subscriptions$ = $subscription;
	}

	/**
	 * Determine crop type properties based on commodity type. The crop type
	 * properties are used to fine tune validation of the form.
	 * @param commodityTypeId
	 * @returns
	 */
	public determineCropProperties(commodityTypeId: number): void {
		let commodityType: ICommodityType;

		if (!this._commodityTypes || this._commodityTypes.length === 0) {
			throw new Error('commodity types array is empty');
		}

		commodityType = this._commodityTypes.filter(x => x.Id === commodityTypeId)[0];

		if (!commodityType) {
			return;  // no commodity type selected
		}

		this._commodityTypeCalculator = commodityType.CommodityTypeCalculator;

		this.f.CalculatorId.setValue(Planting.getCalculator(this._commodityTypeCalculator));
		this.f.ShouldShowTreeCoefficients.setValue(CropType.areTreeCoefficientsRequired(this._commodityTypeCalculator));
		this.f.IsTree.setValue(CropType.isTreeByInterface(this._commodityTypeCalculator));
		this.f.IgnoresNFactors.setValue(CropType.ignoresNFactors(this._commodityTypeCalculator));
		this.f.IsPerennial.setValue(CropType.isPerennialPlanting(commodityType.Id));
		this.f.SpringLeafNutrientsRequired.setValue(CropType.areSpringLeafNutrientsRequired(this._commodityTypeCalculator));

		if (this.f.IsPerennial.value === false || this._commodityTypeCalculator === eCommodityTypeCalculators.caneberry) {
			this.f.IsYearSpecific.setValue(true);
		} else {
			this.f.IsYearSpecific.setValue(false);
		}

		this.f.MaxAge.setValue(this.f.MaxAge.value); // refresh value
	}

	/**
	 * Public to external components calling this class
	 * @param ct
	 * @returns formGroup
	 */
	public initializeForm(ct: ICropTypeViewModel): FormGroup {
		if (!ct) {
			throw new Error('crop type is empty');
		}

		this.form = this._fb.group({
			BasalKC: [ct.BasalKC, [Validators.required]],
			BedWidth: [ct.BedWidth],
			CanopyA: [ct.CanopyA],
			CanopyB: [ct.CanopyB],
			CanopyC: [ct.CanopyC],
			CanopyD: [ct.CanopyD],
			CanopyIntercept: [ct.CanopyIntercept],
			CanopyGMax: [ct.CanopyGMax, FormValidation.greaterThanValidator(0)],
			CanopyMaxFraction: [ct.CanopyMaxFraction],
			CanopyMin: [ct.CanopyMin],
			CommodityTypeId: [ct.CommodityTypeId, [Validators.required, Validators.min(1)]],

			CropSensitivity: [ct.CropSensitivity, [
				FormValidation.greaterThanValidator(0),
				Validators.max(3)
			]],

			DaysToEndDate: [ct.DaysToEndDate > 0 ? ct.DaysToEndDate : null],

			DripIrrigationFactor: [ct.DripIrrigationFactor, [Validators.required,
				Validators.max(1), Validators.min(0)]],

			EarlyFruit: [ct.EarlyFruit, [Validators.required]],
			FertilizerUseEfficiency: [ct.FertilizerUseEfficiency, { validators: [Validators.required], updateOn: 'blur' }],

			FloodIrrigationFactor: [ct.FloodIrrigationFactor, [Validators.required,
				Validators.max(1), Validators.min(0)]],

			GermDays: [ct.GermDays, [Validators.required] ],

			Id: [ct.Id],
			InadequateSummerLeafN: [ct.InadequateSummerLeafN, { Validators: [Validators.required], updateOn: 'blur'}],
			IsMacroTunnelEnabled: [ct.IsMacroTunnelEnabled],
			IsNDependent: [ct.IsNDependent],
			IsPreviousCrop: [ct.IsPreviousCrop],
			IsStressable: [ct.IsStressable],
			Kccov_int: [ct.Kccov_int, [Validators.required]],
			Kccov_quad: [ct.Kccov_quad, [Validators.required]],
			Kccov_slope: [ct.Kccov_slope, [Validators.required]],
			LeafSenesce: [ct.LeafSenesce, [Validators.required,
				FormValidation.greaterThanValidator(0),
				FormValidation.lessThanValidator(1)]],
			MaxAge: [ct.MaxAge], // required if perennial
			MaxDays: [ct.MaxDays > 0 ? ct.MaxDays : null],
			MaxRootDepth: [ct.MaxRootDepth, [Validators.required]],
			MaxUptake: [ct.MaxUptake, [FormValidation.greaterThanValidator(0), Validators.required]],
			MinRootDepth: [ct.MinRootDepth, [Validators.required]],
			Name: [ct.Name, Validators.required],

			NCalculator: this._fb.group({

				CompostN: [ct.CompostN, { validators: [Validators.min(0)], updateOn: 'blur' }],
				CoverCropN: [ct.CoverCropN, { validators: [Validators.min(0)], updateOn: 'blur' }],
				ManureN: [ct.ManureN, { validators: [Validators.min(0)], updateOn: 'blur' }],
				YieldTarget: [null, { validators: [Validators.min(0)], updateOn: 'blur' }],
				YieldTargetDisplayed: [null],
				TreeNutrientThresholds: this._fb.array([]),
			}),

			NFertilizerAdjustment: [ct.NFertilizerAdjustment, { Validators: [Validators.required], updateOn: 'blur'}],
			PerennialCanopies: this._fb.array([]),
			PerennialRootDepths: this._fb.array([]),
			PreviousCropName: [ct.PreviousCropName],
			PreHarvestSenesce: [ct.PreHarvestSenesce],
			Rows: [ct.Rows],
			SeasonalKCs: this._fb.array([]),

			SprinklerIrrigationFactor: [ct.SprinklerIrrigationFactor, [Validators.required,
				Validators.max(1), Validators.min(0)]],

			StrawberryFertilizationInterval: [ct.StrawberryFertilizationInterval > 0 ?
				ct.StrawberryFertilizationInterval : null, [Validators.required,
				FormValidation.greaterThanValidator(0)]],

			StressEnd: [ct.StressEnd],
			StressStart: [ct.StressStart],
			TargetTension1: [ct.TargetTension1],
			TargetTension2: [ct.TargetTension2],
			TargetTension3: [ct.TargetTension3],
			TargetTension4: [ct.TargetTension4],
			TreeInterceptCoefficient: [ct.TreeInterceptCoefficient, { updateOn: 'blur' }],
			TreeIlr4Coefficient: [ct.TreeIlr4Coefficient, { updateOn: 'blur' }],
			TreeIlr8Coefficient: [ct.TreeIlr8Coefficient, { updateOn: 'blur'}],
			TreeIlrProductCoefficient: [ct.TreeIlrProductCoefficient, { updateOn: 'blur'}],
			TreeLeafNCoefficient: [ct.TreeLeafNCoefficient, { updateOn: 'blur'}],
			VegetativeNDemands: this._fb.array([]),
			WaterStressEnd: [ct.WaterStressEnd],
			WaterStressStart: [ct.WaterStressStart],
			WetAreaDrip: [ct.WetAreaDrip, [Validators.required,
				Validators.min(0), Validators.max(100),
				PlantingSettingsValidator.mustBeAnInteger()]],

			// field(s) that won't be submitted
			IgnoresNFactors: [null],
			IsPerennial: [null],
			IsTree: [null],
			IsYearSpecific: [null],
			ShouldShowTreeCoefficients: [null],
			SpringLeafNutrientsRequired: [null],
			CalculatorId: [null, [Validators.required]],
		});

		this.determineCropProperties(this.f.CommodityTypeId.value);

		this.form = this._fb.group({
			...this.form.controls,
			CanopyE: [ct.CanopyE, FormValidation.requiredIfStressable(this.form)],
			CanopyF: [ct.CanopyF, FormValidation.requiredIfStressable(this.form)],
			CovDensityFactor: [ct.CovDensityFactor, FormValidation.requiredIfTree(this.f.IsTree)],

			CropMineralizationRate: [ct.CropMineralizationRate > 0 ?
				ct.CropMineralizationRate : null,
				[FormValidation.requiredIfNDependent(this.f.IsNDependent),
					FormValidation.greaterThanValidator(0)]],

			ExcessiveSummerLeafN: [ct.ExcessiveSummerLeafN, { validators:
				[FormValidation.requiredIfTree(this.f.IsTree)],  updateOn: 'blur'}],

			FertA: [ct.FertA, [FormValidation.requiredIfNDependent(this.f.IsNDependent)]],
			FertB: [ct.FertB, [FormValidation.requiredIfNDependent(this.f.IsNDependent)]],
			FertYo: [ct.FertYo, [FormValidation.requiredIfNDependent(this.f.IsNDependent)]],

			HarvestIntervalFraction: [ct.HarvestIntervalFraction > 0 ?
				ct.HarvestIntervalFraction : null, [FormValidation.requiredIfNDependent(this.f.IsNDependent),
				FormValidation.greaterThanValidator(0)]],

			MaxNRec: [ct.MaxNRec > 0 ? ct.MaxNRec : null,
				[FormValidation.greaterThanValidator(0),
				FormValidation.requiredIfNDependent(this.f.IsNDependent)]],

			NFertilizerFactorA: [ct.NFertilizerFactorA > 0 ? ct.NFertilizerFactorA : null,
				[FormValidation.requiredIfNFactors(this.f.IgnoresNFactors)]],

			NFertilizerFactorB: [ct.NFertilizerFactorB > 0 ? ct.NFertilizerFactorB : null,
				[FormValidation.requiredIfNFactors(this.f.IgnoresNFactors)]],

			NFertilizerFactorC: [ct.NFertilizerFactorC > 0 ? ct.NFertilizerFactorC : null,
				[FormValidation.requiredIfNFactors(this.f.IgnoresNFactors)]],

			NFertilizerFactorD: [ct.NFertilizerFactorD > 0 ? ct.NFertilizerFactorD : null,
				[FormValidation.requiredIfNFactors(this.f.IgnoresNFactors)]],

			NUptakeThreshold: [ct.NUptakeThreshold > 0 ? ct.NUptakeThreshold : null,
				[FormValidation.requiredIfNFactors(this.f.IgnoresNFactors)]],

			PrevCropK: [ct.PrevCropK > 0 ? ct.PrevCropK : null],

			RootDepthThreshold: [ct.RootDepthThreshold > 0 ? ct.RootDepthThreshold : null,
				[FormValidation.requiredIfNDependentAndNotTree(this.f.IsNDependent, this.f.IsTree)]],

			SoilNRatioA: [ct.SoilNRatioA > 0 ? ct.SoilNRatioA : null,
				[FormValidation.requiredIfNFactors(this.f.IgnoresNFactors)]],

			SoilNRatioB: [ct.SoilNRatioB > 0 ? ct.SoilNRatioB : null,
				[FormValidation.requiredIfNFactors(this.f.IgnoresNFactors)]],

			SoilNRatioC: [ct.SoilNRatioC > 0 ? ct.SoilNRatioC : null,
				[FormValidation.requiredIfNFactors(this.f.IgnoresNFactors)]],

			SoilNThreshold: [ct.SoilNThreshold > 0 ? ct.SoilNThreshold : null,
				[FormValidation.requiredIfNDependentAndNotTree(this.f.IsNDependent, this.f.IsTree)]],

			SoilNThresholdFraction: [ct.SoilNThresholdFraction > 0 ?
				ct.SoilNThresholdFraction : null,
				[FormValidation.requiredIfNDependentAndNotTree(this.f.IsNDependent, this.f.IsTree)]],

			SoilNThresholdFractionMid: [ct.SoilNThresholdFractionMid > 0 ?
				ct.SoilNThresholdFractionMid : null,
				[FormValidation.requiredIfNDependentAndNotTree(this.f.IsNDependent, this.f.IsTree)]],

			SoilNThresholdLate: [ct.SoilNThresholdLate > 0 ? ct.SoilNThresholdLate : null,
				[FormValidation.requiredIfNDependentAndNotTree(this.f.IsNDependent, this.f.IsTree)]],

			SoilNThresholdMid: [ct.SoilNThresholdMid > 0 ? ct.SoilNThresholdMid : null,
				[FormValidation.requiredIfNDependentAndNotTree(this.f.IsNDependent, this.f.IsTree)]],

			UptakeConstant: [ct.UptakeConstant ? ct.UptakeConstant : 0,
				[FormValidation.requiredIfNDependent(this.f.IsNDependent)]],

			UptakeFactor: [ct.UptakeFactor ? ct.UptakeFactor : 0,
				{ Validators: [FormValidation.requiredIfNDependent(this.f.IsNDependent)],
					updateOn: 'blur' }],

			WetAreaMicroSprinkler: [ct.WetAreaMicroSprinkler,
				[FormValidation.requiredIfTree(this.f.IsTree), Validators.min(0),
				Validators.max(100), PlantingSettingsValidator.mustBeAnInteger()]],

		});

		this.setupConditionalValidators();

		this.f.MaxAge.setValidators(this._requiredIfNotYearSpecific(this.form));

		if (ct.VegetativeNDemands && ct.VegetativeNDemands.length > 0) {
			for (let demand of ct.VegetativeNDemands) {
				(this.f.VegetativeNDemands as FormArray).
					push(this._getVegetativeDemandFormGroup(demand));
			}
		}

		if (ct.TreeNutrientThresholds && ct.TreeNutrientThresholds.length > 0) {
			for (let threshold of ct.TreeNutrientThresholds) {
				(this.form.get('NCalculator').get('TreeNutrientThresholds') as FormArray).
					push(this._getThresholdFormGroup(threshold));
			}
		}

		if (ct.SeasonalKCs && ct.SeasonalKCs.length > 0) {
			for (let kc of ct.SeasonalKCs) {
				(this.f.SeasonalKCs as FormArray).
					push(this._getSeasonalKCFormGroup(kc));
			}
		}

		if (ct.PerennialRootDepths && ct.PerennialRootDepths.length > 0) {
			for (let rootDepth of ct.PerennialRootDepths) {
				(this.f.PerennialRootDepths as FormArray).
					push(this._getPerennialRootDepthFormGroup(rootDepth));
			}
		}

		if (ct.PerennialCanopies && ct.PerennialCanopies.length > 0) {
			for (let item of ct.PerennialCanopies) {
				(this.f.PerennialCanopies as FormArray).
					push(this._getPerennialCanopiesFormGroup(item));
			}
		}

		if (ct.Id) {
			this.form.markAsDirty();
		}

		return this.form;
	}

	protected setupConditionalValidators(): void {
		if (this.f.IsYearSpecific.value) {
			this.f.MinRootDepth.enable();
			this.f.MaxRootDepth.enable();
		} else {
			this.f.MinRootDepth.disable();
			this.f.MaxRootDepth.disable();
		}

		if (this.f.CommodityTypeId.value === eCommodityTypes.ALFALFA) {
			this.f.BasalKC.enable();
		} else {
			this.f.BasalKC.disable();
		}

		if (this.f.IsTree.value) {
			this.f.FertilizerUseEfficiency.enable();
			this.f.InadequateSummerLeafN.enable();
			this.f.Kccov_int.enable();
			this.f.Kccov_quad.enable();
			this.f.Kccov_slope.enable();
			this.f.LeafSenesce.enable();
			this.f.NFertilizerAdjustment.enable();
			this.f.WetAreaDrip.enable();
		} else {
			this.f.FertilizerUseEfficiency.disable();
			this.f.InadequateSummerLeafN.disable();
			this.f.Kccov_int.disable();
			this.f.Kccov_quad.disable();
			this.f.Kccov_slope.disable();
			this.f.LeafSenesce.disable();
			this.f.NFertilizerAdjustment.disable();
			this.f.WetAreaDrip.disable();
		}

		/**
		this.f.StressStart.setValidators([FormValidation.greaterThanValidator(0),
			Validators.max(1), FormValidation.lessThanValidatorControl(this.f.StressEnd)]);

		this.f.StressEnd.setValidators([FormValidation.greaterThanValidator(0),
			FormValidation.greaterThanValidatorControl(this.f.StressStart),
			Validators.max(1)]);
			**/

		/**
		if (this.f.IsTree.value || this.f.CalculatorId.value === eCalculators.TOMATO) {
			this.f.StressStart.enable();
			this.f.StressEnd.enable();
		} else {
			this.f.StressStart.disable();
			this.f.StressEnd.disable();
		}

		**/

		if (this.f.CalculatorId.value === eCalculators.TOMATO) {
			this.f.EarlyFruit.enable();
		} else {
			this.f.EarlyFruit.disable();
		}

		if (this.f.CalculatorId.value === eCalculators.STRAWBERRY ||
			this.f.CalculatorId.value === eCalculators.TOMATO ) {

			this.f.StrawberryFertilizationInterval.enable();
		} else {
			this.f.StrawberryFertilizationInterval.disable();
		}

		if (this.f.IsNDependent.value && !this.f.IsTree.value) {
			this.f.MaxUptake.enable();
			this.f.CropMineralizationRate.enable();
			this.f.PrevCropK.enable();
		} else {
			this.f.MaxUptake.disable();
			this.f.CropMineralizationRate.disable();
			this.f.PrevCropK.disable();
		}
	}

	private _getPerennialCanopiesFormGroup(x: IPerennialCanopy): FormGroup {
		let result: FormGroup;

		result = this._fb.group({
			Id: [x.Id],
			CropTypeId: [x.CropTypeId],
			Age: [x.Age],
			CanopyA: [x.CanopyA],
			CanopyB: [x.CanopyB],
			CanopyE: [x.CanopyE],
			CanopyF: [x.CanopyF],
			CanopyGMax: [x.CanopyGMax],
			CanopyMaxFraction: [x.CanopyMaxFraction]
		});

		return result;
	}

	private _getPerennialRootDepthFormGroup(x: IPerennialRootDepth): FormGroup {
		let result: FormGroup;

		result = this._fb.group({
			Id: [x.Id],
			CropTypeId: [x.CropTypeId],
			YearMin: [x.YearMin],
			YearMax: [x.YearMax],
			Max: [x.Max],
			Min: [x.Min]
		});

		return result;
	}

	private _getSeasonalKCFormGroup(kc: ISeasonalKC): FormGroup {
		let result: FormGroup;

		result = this._fb.group({
			Id: [kc.Id],
			CropTypeId: [kc.CropTypeId],
			Month: [kc.Month],
			NiToMaxCanopy: [kc.NiToMaxCanopy],
			Density: [kc.Density],
			Cover: [kc.Cover]
		});

		if (this.f.CommodityTypeId.value === eCommodityTypes.ALFALFA) {
			result.get('NiToMaxCanopy').setValidators(Validators.required);
		}

		return result;
	}

	private _getThresholdFormGroup(x: ITreeNutrientThreshold): FormGroup {
		let result: FormGroup;

		result = this._fb.group({
			Id: [x.Id],
			CropTypeId: [x.CropTypeId],
			NutrientId: [x.NutrientId],
			SeasonAverage: [x.SeasonAverage, { updateOn: 'blur' }],
			Threshold: [x.Threshold, { updateOn: 'blur' }],
			Recorded: [x.Recorded, { updateOn: 'blur' }],
			TissueFactor: [x.TissueFactor],
			TissueCoefficientA: [x.TissueCoefficientA],
			TissueCoefficientB: [x.TissueCoefficientB],
			NutrientSymbol: [x.NutrientSymbol],
			NutrientUnit: [x.NutrientUnit],
			NutrientName: [x.NutrientName]
		});

		if (this._shouldSetTreeNutrientThresholdValidation(x)) {
			result.get('SeasonAverage').setValidators(Validators.required);
			result.get('Threshold').setValidators(Validators.required);
		} else {
			result.get('SeasonAverage').clearValidators();
			result.get('Threshold').clearValidators();
		}

		return result;
	}

	private _shouldSetTreeNutrientThresholdValidation(threshold: ITreeNutrientThreshold): boolean {
		if (!this.f.SpringLeafNutrientsRequired.value) {
			return false;
		}

		switch (this._commodityTypeCalculator) {
			case eCommodityTypeCalculators.pistachio:
				return false;
			case eCommodityTypeCalculators.walnut:
				if (threshold.NutrientSymbol === eNutrientSymbols.Boron) {
					return false;
				} else {
					return true;
				}
			case eCommodityTypeCalculators.almond:
				if (threshold.NutrientSymbol === eNutrientSymbols.Phosphorous || eNutrientSymbols.Sulfur) {
					return false;
				} else {
					return true;
				}
			default:
				return false;
		}
	}

	private _getVegetativeDemandFormGroup(x: IVegetativeNDemand): FormGroup {
		let result: FormGroup;

		result = this._fb.group({
			Id: [x.Id],
			CropTypeId: [x.CropTypeId],
			YearMin: [x.YearMin],
			YearMax: [x.YearMax],
			Low: [x.Low],
			High: [x.High]
		});

		return result;
	}

	private _requiredIfNotYearSpecific(fg: FormGroup): ValidatorFn {
		return (control: AbstractControl): {[key: string]: any} | null => {
			let valid: boolean;
			let isYearSpecific: boolean;
			const errorName = 'requiredIfNDependnt';

			isYearSpecific = fg.get('IsYearSpecific').value;

			if (!isYearSpecific) {
				valid = control.value !== null  ? true : false;
			} else {
				FormValidation.removeErrorFromContrl(control, errorName);
				valid = true;
			}

			return valid ? null : {errorName: {value: control.value}};
		};
	}

	public getPerennialRootDepthIndex(age: number): number {
		let result: number;

		if (!this.f.PerennialRootDepths || (this.f.PerennialRootDepths as
			FormArray).length === 0 || age === null || age === undefined) {

			return null;
		}

		result = (this.f.PerennialRootDepths as FormArray).controls.
			findIndex(x => age === x.get('YearMin').value ||
			(x.get('YearMax').value - x.get('YearMin').value > 1 && age
			>= x.get('YearMin').value));

		return result;
	}

	public getPerennialCanopyIndex(age: number): number {
		let result: number;

		if (!this.f.PerennialCanopies || (this.f.PerennialCanopies as
			FormArray).length === 0 || age === null || age === undefined) {

			return null;
		}

		if (age === 0) {
			result = (this.f.PerennialCanopies as FormArray).controls.
			findIndex(x => x.get('Age').value === age);
		} else {
			let ar: AbstractControl[];
			let id: number;

			ar = (this.f.PerennialCanopies as FormArray).controls
				.filter(x => x.get('Age').value <= age).sort((a, b) => {
				return b.get('Age').value - a.get('Age').value;
			});

			id = (ar[0].value as IPerennialCanopy).Id;

			result = (this.f.PerennialCanopies as FormArray).controls.
				findIndex(x => x.get('Id').value === id);
		}

		return result;
	}

	/**
	 * Find the index of match in vegetative N Demand array
	 * @param age crop age
	 * @returns array index
	 */
	public getVegetativeNDemandIndex(age: number): number {
		let result: number;

		if (age === null || age === undefined ||
			(this.f.VegetativeNDemands as FormArray).length === 0) { // age 0 is valid
			return null;
		}

		result = (this.f.VegetativeNDemands as FormArray).controls.
			findIndex(x => age + 1 >= x.get('YearMin').value
			&& age + 1 <= x.get('YearMax').value);

		return result;
	}
}
