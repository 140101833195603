import { IrrigationEvent } from '../../components/irrigation-event/irrigationEvent';
import { FertilizationEvent } from '../../components/fertilization-event/fertilizationEvent';
import { SoilSampleEvent } from '../../components/soil-sample-event/soilSampleEvent';
import { FlowMeter } from '../../components/flowmeter-graph/flowMeter';

import { IRainfallWeather } from '../../components/irrigation-event/interfaces';
import { IRawEvent, IEventPostResponseJSON, IEventPostResponse } from './interfaces';

import {
	EventContext, eIrrigationMethods, EventTypeName, EventTypeNames, irrigationMethodNames,
	units, waterUnit, eCommodityTypes, EventsType
} from '../../interfaces/constants';
import { EventUtility } from './eventUtility';
import { DateUtility } from '../../classes/dateUtility';
import { RanchService } from '../../components/ranch-settings/service';
import { CutEvent } from '../../components/cut-event/cutEvent';
import { IMaturity } from '../maturity/interfaces';
import { ValidateService } from '../../services/validate.service';
import * as jQuery from 'jquery';
import { TissueSampleEvent } from '../../components/tissue-sample-event/tissueSampleEvent';

export class EventGroup {
	public ActiveEvents?: string; // active events string used to display upcoming events in plantings view
	public Id: number;
	public CutEvent: CutEvent = null;
	public CuttingDaysSincePreviousCutting: number;
	public CuttingMaturityName: string; // controls table sorting
	public CuttingYield: number;
	public EventDate: Date;
	public EventDateString: string; // result of Date.getTime() converted to string,
	public eventType: EventsType; // virtual
	public Fertilization: FertilizationEvent[] = null;
	public FertilizationCropStageName: string;
	public FertilizationDisplayFertilizationUnit: number;
	public FertilizationDisplayLbsNPerAcre: number;
	public FertilizationFertilizerApplied: number;
	public FertilizationFertilizerName: string;
	public FertilizationNApplied: number;
	public FertilizationNAppliedFromWater: number;
	public FertilizationNUptake: number;
	public FertilizationRecommendation: number;
	public FertilizationsoilNitrate: number;
	public hiddenInListView: boolean;
	public Irrigation: IrrigationEvent = null;
	public IrrigationAppliedInches: number;
	public IrrigationAppliedHours: number;
	public IrrigationAvailableSoilMoisture: number;
	public IrrigationCanopyCover: number;
	public IrrigationContributingRainfall: number;
	public IrrigationCumulativeRainfall: number;
	public IrrigationCropCoefficient: number;
	public IrrigationDisplayAmount: number;
	public IrrigationInterval: number;
	public IrrigationIrrigationMethod: string;
	public IrrigationRainfall: number;
	public IrrigationRecommendationHours: number;
	public IrrigationRecommendedMaxIrrigationInterval: number;
	public IrrigationRecommendationInches: number;
	public IrrigationReferenceET: number;
	public IrrigationTotalCropET: number;
	public isFuture: boolean;
	public isOnOrBeforeWetDate: boolean;
	public isPast: boolean;
	public JSEventDate: Date;
	public originalEventDate: Date; // virtual - used to maintain planting array when event is moved from
		// one date to the next
	public RainfallWeather: IRainfallWeather;
	public SoilSamples: SoilSampleEvent[] = null;
	public SoilSamplesCropStageName: string;
	public SoilSamplesDepth: number;
	public SoilSamplesSampleType: string;
	public SoilSamplesSoilMineralNLbsAcre: number;
	public SoilSamplesSoilNitrateNPPM: number; // controls table sorting
	public TissueSamples: TissueSampleEvent[] = null;
	public TissueSamplesCropStageName: string;
	public TissueSamplesLocationName: string;
	public TissueSamplesNitrate: number;

	// some of the above fields are properties only for display
	// which is used for sorting
	// REFACTOR - these should not be at the top level of EventGroup.. they should
	// be tucked away in eventGroup.Irrigation, etc.

	/**
     * Convert multiple event JSON into eventGroups
     * @param events
     * @param persistentDatabase
     */
	public static convertAll(events: IRawEvent[], wetDate: Date): EventGroup[] {
		let result: EventGroup[];

		result = new Array();

		events = EventGroup.removeHiddenEvents(events);

		for (let event of events) {
			let item: EventGroup;

			item = new EventGroup();
			item.convert(event, wetDate);
			result.push(item);
		}

		return result;
	}

	/**
     * Convert from JSON into EventGroup - wrote to reduce redundant code in calling controllers
     * @param json
     * @param planting
     * @param ranchService
     */
	public static convertFromJSON(json: IEventPostResponseJSON, wetDate: Date, plantingId: number,
		ranchService: RanchService): EventGroup {

		let eventGroup: EventGroup;

		if (!json || !json.Events) {
			return null;
		}

		eventGroup = new EventGroup();
		eventGroup.convert(json.Events[0], wetDate);

		EventGroup.updateEvents(json.EventGroupsSucceeding, plantingId,
			wetDate, ranchService);

		eventGroup.Id = json.Id;
		eventGroup.ActiveEvents = json.ActiveEvents;

		return eventGroup;
	}

	/**
     * Create meta data for events for list view
     *
     * @param events
     * @param commodityTypeId
     * @param maturities
     */
	public static createMetadata(events: EventGroup[], commodityTypeId: number,
		maturities: IMaturity[]): EventGroup[] {

		let now: Date;
		let result: EventGroup[];

		now = new Date();

		if (!events || events.length === 0) {
			return;
		}

		now.setHours(0, 0, 0, 0);
		result = new Array();

		for (let event of events) {

			event.EventDateString = event.EventDate.getTime().toString();

			if (event.EventDate > now) {
				event.isFuture = true;
			} else {
				event.isFuture = false;
			}

			if (event.Irrigation) {
				event.IrrigationDisplayAmount = event.Irrigation.DisplayAmount;
				event.IrrigationIrrigationMethod = event.Irrigation.IrrigationMethod
				event.IrrigationInterval = event.Irrigation.Interval;

				if (event.Irrigation.IsRainfall) {
					event.IrrigationRecommendedMaxIrrigationInterval = -1;
				} else {
					event.IrrigationRecommendedMaxIrrigationInterval = event.Irrigation.RecommendedIrrigationInterval;
				}

				event.IrrigationRecommendationInches = event.Irrigation.RecommendationInches;
				event.IrrigationRecommendationHours = event.Irrigation.RecommendationHours;
				event.IrrigationAppliedInches = event.Irrigation.AppliedInches;
				event.IrrigationAppliedHours = event.Irrigation.AppliedHours;
				event.IrrigationCropCoefficient = -1;
				event.IrrigationCanopyCover = -1;
				event.IrrigationReferenceET = -1;
				event.IrrigationTotalCropET = -1;
				event.IrrigationAvailableSoilMoisture = -1;
				event.IrrigationContributingRainfall = event.Irrigation.ContributingRainfall;
				event.IrrigationCumulativeRainfall = event.Irrigation.ContributingRainfall + event.Irrigation.Rainfall;
			} else {
				event.IrrigationDisplayAmount = -1;
				event.IrrigationIrrigationMethod = '-1';
				event.IrrigationInterval = -1;
				event.IrrigationRecommendedMaxIrrigationInterval = -1;
				event.IrrigationRecommendationInches = -1;
				event.IrrigationRecommendationHours = -1;
				event.IrrigationAppliedInches = -1;
				event.IrrigationAppliedHours = -1;
				event.IrrigationRainfall = -1;
				event.IrrigationContributingRainfall = -1;
				event.IrrigationCumulativeRainfall = -1;
			}

			EventGroup.unpackFertilizationEvents(event, result, commodityTypeId);
			EventGroup.unpackTissueSampleEvents(event, result);

			// may rewrite to use loops in template instead of this...

			if (event.SoilSamples) {
				for (let index in event.SoilSamples) {
					// keep at least one soil sample in the eventGroup, so eventGroup isn't empty
					if (Number(index) === 0) {
						event.SoilSamplesSoilNitrateNPPM = event.SoilSamples[0].SoilNitrateNPPM;
						event.SoilSamplesSoilMineralNLbsAcre = event.SoilSamples[0].SoilMineralNLbsAcre;
						event.SoilSamplesSampleType = event.SoilSamples[0].SampleType;
						event.SoilSamplesDepth = event.SoilSamples[0].Depth;

						event.SoilSamplesCropStageName = event.SoilSamples[0].CropStage ?
							event.SoilSamples[0].CropStage.Name : null;

						event.SoilSamples[0].Nutrients = SoilSampleEvent.insertNitrateNIntoNutrients(event.SoilSamples[0]);
					} else {
						let newEvent: EventGroup;

						newEvent = EventGroup.initializeEventGroup(event);
						newEvent.hiddenInListView = true;

						newEvent.SoilSamples.push(jQuery.extend(true, {}, event.SoilSamples[index]));
						newEvent.SoilSamplesSoilNitrateNPPM = newEvent.SoilSamples[0].SoilNitrateNPPM;
						newEvent.SoilSamplesSoilMineralNLbsAcre = newEvent.SoilSamples[0].SoilMineralNLbsAcre;
						newEvent.SoilSamplesSampleType = newEvent.SoilSamples[0].SampleType;
						newEvent.SoilSamplesDepth = newEvent.SoilSamples[0].Depth;

						newEvent.SoilSamplesCropStageName = newEvent.SoilSamples[0].CropStage ?
							newEvent.SoilSamples[0].CropStage.Name : null;

						newEvent.SoilSamples[0].Nutrients = SoilSampleEvent.insertNitrateNIntoNutrients(event.SoilSamples[index]);
						// additionalSoilSamples.push(newEvent); // each soil sample
						result.push(newEvent);
					}

					// for (let newEvent of additionalSoilSamples) {
					//     processedEvents.push(newEvent); // each additionalSoilSamples is added to allEvents
					// }
				}
			} else {
				event.SoilSamplesSoilNitrateNPPM = -1;
				event.SoilSamplesSoilMineralNLbsAcre = -1;
				event.SoilSamplesSampleType = '-1';
				event.SoilSamplesDepth = -1;
				event.SoilSamplesCropStageName = '-1';
			}

			if (event.CutEvent) {
				event.CuttingYield = event.CutEvent.Yield;
				event.CuttingMaturityName = EventGroup.getMaturityNameById(maturities, event.CutEvent.MaturityId);

				event.CuttingDaysSincePreviousCutting = event.CutEvent.CutInterval;
			} else {
				event.CuttingYield = -1;
				event.CuttingMaturityName = '-1';
				event.CuttingDaysSincePreviousCutting = -1;
			}

			result.push(event);
		}

		return result;
	}

	private static getMaturityNameById(maturities: IMaturity[], maturityId: number): string {
		let maturity: IMaturity;

		if (!ValidateService.isValidNumber(maturityId)) {
			return '-1';
		}

		if (!maturities || !maturities.length) {
			return '-1';
		}

		maturity = maturities.filter(x => x.Id === maturityId)[0];

		if (!maturity) {
			return '-1';
		}

		return maturity.Name;
	}

	public static initializeEventGroup(originalEvent: EventGroup): EventGroup {
		let result: EventGroup;

		if (originalEvent) {
			result = jQuery.extend(true, new EventGroup(), originalEvent);
		} else {
			result = new EventGroup();
		}

		result.hiddenInListView = true; // filters out this event from list views -
			// otherwise will show as duplicate

		result.Irrigation = null;
		result.IrrigationDisplayAmount = -1;
		result.IrrigationIrrigationMethod = '-1';
		result.IrrigationInterval = -1;
		result.IrrigationRecommendedMaxIrrigationInterval = -1;
		result.IrrigationRecommendationInches = -1;
		result.IrrigationRecommendationHours = -1;
		result.IrrigationAppliedInches = -1;
		result.IrrigationAppliedHours = -1;
		result.IrrigationRainfall = -1;
		result.Fertilization = new Array();
		result.FertilizationDisplayFertilizationUnit = -1;
		result.FertilizationDisplayLbsNPerAcre = -1;
		result.FertilizationCropStageName = '-1';
		result.FertilizationsoilNitrate = -1;
		result.FertilizationNAppliedFromWater = -1;
		result.FertilizationNUptake = -1;
		result.FertilizationFertilizerName = '-1';
		result.FertilizationRecommendation = -1;
		result.FertilizationFertilizerApplied = -1;
		result.FertilizationNApplied = -1;
		result.SoilSamples = new Array();
		result.TissueSamples = new Array();

		return result;
	}

	/**
     * remove "bookmark" cut events and soil tension reading irrigation events
     *
     * WARNING: Also removes events where no "event" is detected, which is not related to this functionality
     * @param events
     */
	public static removeHiddenEvents(events: IRawEvent[]): IRawEvent[] {
		let result: IRawEvent[] = new Array();

		if (!events || !events.length) {
			return result;
		}

		for (let event of events) {
			if (event.Irrigation && event.Irrigation.IrrigationMethodId === eIrrigationMethods.SOIL_TENSION_READING) {
				event.Irrigation = null;
			}

			if (event.CutEvent && event.CutEvent.IsBookmark) {
				event.CutEvent = null;
			}

			if (EventUtility.hasNoEvents(event)) {
				continue;
			}

			result.push(event);
		}

		return result;
	}

	/**
     * Prep display fields for event tables/lists. Modifies eventGroup
     *
     * @param event
     * @param eventGroup event group will be modified
     */
	private static setFertlizationEventDisplayFields(event: FertilizationEvent, eventGroup: EventGroup, commodityTypeId: number) {
		if (!event) {
			eventGroup.FertilizationDisplayFertilizationUnit = -1;
			eventGroup.FertilizationDisplayLbsNPerAcre = -1;
			eventGroup.FertilizationCropStageName = '-1';
			eventGroup.FertilizationsoilNitrate = -1;
			eventGroup.FertilizationNAppliedFromWater = -1;
			eventGroup.FertilizationNUptake = -1;
			eventGroup.FertilizationFertilizerName = '-1';
			eventGroup.FertilizationRecommendation = -1;
			eventGroup.FertilizationFertilizerApplied = -1;
			eventGroup.FertilizationNApplied = -1;

			return;
		}

		eventGroup.FertilizationDisplayFertilizationUnit = event.DisplayFertilizationUnit;
		eventGroup.FertilizationDisplayLbsNPerAcre = event.DisplayLbsNPerAcre;
		eventGroup.FertilizationCropStageName = event.CropStage ? event.CropStage.Name : null;
		eventGroup.FertilizationsoilNitrate = event.SoilNitrate;
		eventGroup.FertilizationNAppliedFromWater = event.NAppliedFromWater;
		eventGroup.FertilizationNUptake = event.NUptake;
		eventGroup.FertilizationFertilizerName = event.FertilizerName;
		eventGroup.FertilizationRecommendation = event.Recommendation.cropManage;

		if (event.Recommendation && commodityTypeId === eCommodityTypes.ALFALFA) {
			eventGroup.FertilizationRecommendation = event.Recommendation.managerFertilizerAmount;
			// if alfalfa, just use the manager's fertilizer amount.
		}

		if (event.FertilizerApplied) {
			eventGroup.FertilizationFertilizerApplied = event.FertilizerApplied;
		} else {
			eventGroup.FertilizationFertilizerApplied = 0;
		}

		if (event.NApplied) {
			eventGroup.FertilizationNApplied = event.NApplied;
		} else {
			eventGroup.FertilizationNApplied = 0;
		}
	}

	private static setTissueSampleEventDisplayFields(event: TissueSampleEvent,
		eventGroup: EventGroup): void {

		if (!eventGroup) {
			return;
		}

		if (!event) {
			eventGroup.TissueSamplesLocationName = '-1'; // REFACTOR - should not have numbers as strings
			eventGroup.TissueSamplesCropStageName = '-1';
			eventGroup.TissueSamplesNitrate = -1;

			return;
		}

		eventGroup.TissueSamplesCropStageName = event.CropStage ? event.CropStage.Name : '-1';
		eventGroup.TissueSamplesLocationName = '-1';
	}

	public static sortEvents(events: EventGroup[]): EventGroup[] {
		let result: EventGroup[];

		if (!events) {
			return [];
		}

		result = events.sort(function (a, b) {
			return Number(a.EventDateString) - Number(b.EventDateString);
		});

		return result;
	}

	private static unpackFertilizationEvents(eventGroupOriginal: EventGroup, eventGroups: EventGroup[], commodityTypeId: number): void {

		let count = 0;

		if (!eventGroupOriginal.Fertilization || eventGroupOriginal.Fertilization.length === 0) {
			return;
		}

		for (let fertilization of eventGroupOriginal.Fertilization) {
			let eventGroupCopy: EventGroup;

			if (count === 0) {
				EventGroup.setFertlizationEventDisplayFields(fertilization, eventGroupOriginal, commodityTypeId);
				count++;
				continue;
			}

			eventGroupCopy = EventGroup.initializeEventGroup(eventGroupOriginal);
			EventGroup.setFertlizationEventDisplayFields(fertilization, eventGroupCopy, commodityTypeId);

			eventGroupCopy.Fertilization = [
				fertilization
			];

			eventGroups.push(eventGroupCopy);
		}
	}

	private static unpackTissueSampleEvents(eventGroupOriginal: EventGroup,
		eventGroups: EventGroup[]): void {

		let count = 0;

		if (!eventGroupOriginal.TissueSamples || eventGroupOriginal.TissueSamples.length === 0) {
			return;
		}

		for (let tissueSample of eventGroupOriginal.TissueSamples) {
			let eventGroupCopy: EventGroup;

			if (count === 0) {
				EventGroup.setTissueSampleEventDisplayFields(tissueSample, eventGroupOriginal);
				count++;
				continue;
			}

			eventGroupCopy = EventGroup.initializeEventGroup(eventGroupOriginal);
			EventGroup.setTissueSampleEventDisplayFields(tissueSample, eventGroupCopy);

			eventGroupCopy.TissueSamples = [
				tissueSample
			];

			eventGroups.push(eventGroupCopy);
		}
	}

	/**
     * Used to update multiple event groups - mainly used for alfalfa's succeeding event problem
     *
     * @param eventGroups
     * @param wetDate
     */
	public static updateEvents(eventGroupsRaw: IRawEvent[], plantingId: number, wetDate: Date,
		ranchService: RanchService): void {

		let eventGroups: EventGroup[];

		if (!eventGroupsRaw || eventGroupsRaw.length === 0) {
			return;
		}

		eventGroups = EventGroup.convertAll(eventGroupsRaw,
			wetDate);

		for (let eventGroup of eventGroups) {
			ranchService.plantings.updatePlantingEvents(eventGroup, plantingId);
		}
	}

	public convert(rawEvent: IRawEvent, wetDate: Date): void {

		let dateArr: number[];
		let date: Date;
		let now: Date;
		let soilSample: SoilSampleEvent;

		if (!rawEvent) {
			return;
		}

		dateArr = rawEvent.EventDate.split('/').map((n) => {
			return parseInt(n, 10);
		});

		date = new Date(dateArr[2], dateArr[0] - 1, dateArr[1]);
		now = new Date();

		now.setHours(0, 0, 0, 0);
		this.isFuture = date > now;
		this.isPast = date < now;

		this.EventDate = new Date(rawEvent.EventDate);
		this.RainfallWeather = rawEvent.RainfallWeather;

		this.isOnOrBeforeWetDate = DateUtility.isOnOrBefore(this.EventDate, wetDate);

		if (rawEvent.Irrigation) {
			this.Irrigation = new IrrigationEvent();

			this.Irrigation.convert(rawEvent.Irrigation, this.RainfallWeather, this.isFuture);
		}

		if (rawEvent.Fertilization && rawEvent.Fertilization.length > 0) {
			this.Fertilization = new Array();

			for (let fe of rawEvent.Fertilization) {
				let fertilizationEvent = new FertilizationEvent();

				fertilizationEvent.convert(fe, this.EventDate);

				this.Fertilization.push(fertilizationEvent);
			}
		}

		if (rawEvent.SoilSamples) {
			this.SoilSamples = new Array();

			for (let sample of rawEvent.SoilSamples) {
				soilSample = new SoilSampleEvent();

				soilSample.convert(sample);

				this.SoilSamples.push(soilSample);
			}
		}

		if (rawEvent.TissueSamples) {
			this.TissueSamples = new Array();

			for (let sample of rawEvent.TissueSamples) {
				let tissueSample: TissueSampleEvent;

				tissueSample = new TissueSampleEvent();

				tissueSample.convertTissueSampleModel(sample);

				this.TissueSamples.push(tissueSample);
			}
		}

		if (rawEvent.CutEvent) {
			this.CutEvent = new CutEvent();
			this.CutEvent.transmuteJSON(rawEvent.CutEvent);
		}
	}

	public getFloodAmount(context: EventContext, isRecommendation: boolean = null): number {
		let isCropManageRecommendation: boolean;

		if (!this.Irrigation) {
			return;
		}

		// applied values are only in hours
		if (isRecommendation === false) {
			return this.Irrigation.AppliedHours;
		}

		isCropManageRecommendation = this.isCropManageRecommendationIrrigation(context);

		return isCropManageRecommendation ? this.Irrigation.RecommendationInches : this.Irrigation.RecommendationHours;
	}

	public isCropManageRecommendation(eventTypeName: EventTypeName, context: EventContext): boolean {
		switch (eventTypeName) {
			case EventTypeNames.WATER:
				return this.isCropManageRecommendationIrrigation(context);
			case EventTypeNames.FERTILIZER:
				return this.isCropManageRecommendationFertilization(context);
			case EventTypeNames.SOILSAMPLES:
				return false;
			case EventTypeNames.CUTTING:
				return false;
			default:
				return;
		}
	}

	/**
     * NOTE: We assume that this.Fertilization at this point only has 1 fertilization event, which is true
     * after event table initially loads and processes the event stream.
     *
     * @param context
     */
	public isCropManageRecommendationFertilization(context: EventContext): boolean {
		if (!this.Fertilization || this.Fertilization.length === 0) {
			return;
		}

		if (context !== EventContext.TABLE && (this.Fertilization[0].FertilizerApplied != null || this.Fertilization[0].NApplied != null)) {
			return false;
		}

		if (this.Fertilization[0].Recommendation.manager != null) {
			return false;
		}

		return true;
	}

	public isCropManageRecommendationIrrigation(context: EventContext): boolean {
		if (!this.Irrigation) {
			return;
		}

		if (this.Irrigation.IrrigationMethodId === eIrrigationMethods.RAINFALL) {

			return true;
		}

		if (this.Irrigation.HasFlowmeterData && this.Irrigation.WaterApplied !== null &&
			FlowMeter.getFlowmeterWaterApplied(this.Irrigation) === this.Irrigation.WaterApplied) {
			return true;
		}

		if (context !== EventContext.TABLE && this.Irrigation.WaterApplied !== null) {
			return false;
		}

		if (this.Irrigation.ManagerAmountRecommendation != null) {
			return false;
		}

		return true;
	}

	public showCutEventWarning(): boolean {
		if (!this.CutEvent) {
			return;
		}

		if (this.isPast === false) {
			return false;
		}

		return this.CutEvent.Yield <= 0;
	}

	public showFloodInfoIcon(context: EventContext, waterUnits: waterUnit, appliedOnly = false): boolean {
		let isCropManageRecommendation: boolean;

		if (!this.Irrigation) {
			return;
		}

		if (appliedOnly) {
			return waterUnits === units.water.inches;
		}

		isCropManageRecommendation = this.isCropManageRecommendationIrrigation(context);

		return isCropManageRecommendation ? waterUnits === units.water.hours : waterUnits === units.water.inches;
	}
}
